import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './Account.module.css';
import Toast from '../Toast';

const Account: React.FC = () => {
  const [displayName, setDisplayName] = useState<string>('');
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [hasPassword, setHasPassword] = useState<boolean>(true);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('/api/auth/user', { withCredentials: true });
        setDisplayName(response.data.username);
        setHasPassword(!!response.data.password);
      } catch (error) {
        setToastMessage('Failed to load account data.');
        setToastType('error');
      }
    };
    fetchUserData();
  }, []);

  const handleUpdateDisplayName = async () => {
    try {
      await axios.post(
        '/api/auth/account/update-displayname',
        { displayName },
        { withCredentials: true }
      );
      setToastMessage('Display name updated successfully.');
      setToastType('success');
    } catch (error: any) {
      const errorMsg = error.response?.data?.message || 'Error updating display name. Please try again.';
      setToastMessage(errorMsg);
      setToastType('error');
    }
  };

  const handleUpdatePassword = async () => {
    if (newPassword !== confirmPassword) {
      setToastMessage('New password and confirmation do not match.');
      setToastType('error');
      return;
    }

    try {
      await axios.post(
        '/api/auth/account/update-password',
        {
          currentPassword: hasPassword ? currentPassword : null,
          newPassword,
        },
        { withCredentials: true }
      );
      setToastMessage('Password updated successfully.');
      setToastType('success');
    } catch (error: any) {
      const errorMsg = error.response?.data?.message || 'Error updating password. Please try again.';
      setToastMessage(errorMsg);
      setToastType('error');
    }
  };

  return (
    <div className={styles.accountContainer}>
      <h1>Account Settings</h1>

      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}

      <div className={styles.settingsSection}>
        <h2>Update Display Name</h2>
        <div className={styles.formGroup}>
          <label htmlFor="displayName">Display Name:</label>
          <input
            type="text"
            id="displayName"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            placeholder="Enter your new display name"
          />
        </div>
        <button className={styles.updateButton} onClick={handleUpdateDisplayName}>
          Update Display Name
        </button>
      </div>

      <div className={styles.settingsSection}>
        <h2>{hasPassword ? 'Update Password' : 'Set Password'}</h2>
        {hasPassword && (
          <div className={styles.formGroup}>
            <label htmlFor="currentPassword">Current Password:</label>
            <input
              type="password"
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Enter your current password"
            />
          </div>
        )}
        <div className={styles.formGroup}>
          <label htmlFor="newPassword">New Password:</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Enter your new password"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="confirmPassword">Confirm New Password:</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm your new password"
          />
        </div>
        <button className={styles.updateButton} onClick={handleUpdatePassword}>
          {hasPassword ? 'Update Password' : 'Set Password'}
        </button>
      </div>
    </div>
  );
};

export default Account;
