import React from 'react';
import styles from './TeamList.module.css';

interface TeamMember {
  id: number;
  name: string;
  level: number;
}

interface TeamListProps {
  teamOne: TeamMember[];
  teamTwo: TeamMember[];
  userId: number;
  isOwner: boolean;
  handleKickPlayer: (mercenaryId: number) => void;
  randomTeams: boolean;
  pve: boolean;
}

const TeamList: React.FC<TeamListProps> = ({
  teamOne,
  teamTwo,
  userId,
  isOwner,
  handleKickPlayer,
  randomTeams,
  pve,
}) => {
  const participants = [...teamOne, ...teamTwo];

  return (
    <div className={styles.teamsContainer}>
      {(pve || randomTeams) ? (
        <div className={styles.participants}>
          <h3>Participants</h3>
          <ul>
            {participants.map((member) => (
              <li key={member.id}>
                {member.name} (Level {member.level})
                {isOwner && member.id !== userId && (
                  <button onClick={() => handleKickPlayer(member.id)}>
                    Kick
                  </button>
                )}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <>
          <div className={styles.team}>
            <h3>Team 1</h3>
            <ul>
              {teamOne.map((member) => (
                <li key={member.id}>
                  {member.name} (Level {member.level})
                  {isOwner && member.id !== userId && (
                    <button onClick={() => handleKickPlayer(member.id)}>
                      Kick
                    </button>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.team}>
            <h3>Team 2</h3>
            <ul>
              {teamTwo.map((member) => (
                <li key={member.id}>
                  {member.name} (Level {member.level})
                  {isOwner && member.id !== userId && (
                    <button onClick={() => handleKickPlayer(member.id)}>
                      Kick
                    </button>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default TeamList;
