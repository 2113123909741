export const savePreferencesToDatabase = async (
    lobbyId: number,
    mercenaryId: number,
    preferences: { surrenderHP: number; readyHP: number; strategy: string }
  ) => {
    try {
      await fetch(`/api/team-battle/lobbies/${lobbyId}/participant/${mercenaryId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(preferences),
      });
    } catch (err) {
      console.error('Failed to save preferences to the database:', err);
    }
  };
  