import React from 'react';
import styles from './Modal.module.css';

interface ButtonConfig {
  text: string;
  action: () => void;
  className?: string;
  disabled?: boolean;
}

interface ModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  title: string;
  message: React.ReactNode;
  customButtons?: ButtonConfig[];
}

const Modal: React.FC<ModalProps> = ({ show, onClose, onConfirm, title, message, customButtons }) => {
  if (!show) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose} aria-label="Close">
          &times;
        </button>
        <h2 className={styles.modalTitle}>{title}</h2>
        <p className={styles.modalMessage}>{message}</p>
        <div className={styles.modalActions}>
          {customButtons ? (
            customButtons.map((button, index) => (
              <button
                key={index}
                className={button.className || styles.defaultButton}
                onClick={button.action}
              >
                {button.text}
              </button>
            ))
          ) : (
            <>
              <button className={styles.cancelButton} onClick={onClose}>
                Cancel
              </button>
              {onConfirm && (
                <button className={styles.confirmButton} onClick={onConfirm}>
                  Confirm
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
