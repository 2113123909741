import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Toast from '../Toast';
import styles from './BeastBattle.module.css';

interface Beast {
  id: number;
  name: string;
  description: string;
  level: number;
  loot: Array<string>;
  health: number;
  strength: number;
  endurance: number;
  initiative: number;
  dodge: number;
  luck: number;
  silver: number;
  experience: number;
}

interface LocationState {
  beast: Beast;
  mercenaryId: number;
}

const BeastBattle: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { beast, mercenaryId } = location.state as LocationState;

  const [surrenderAt, setSurrenderAt] = useState<number>(() => {
    const savedSurrenderValue = localStorage.getItem('surrenderAt');
    return savedSurrenderValue ? parseInt(savedSurrenderValue) : 50;
  });

  const [strategy, setStrategy] = useState<string>(() => {
    const savedStrategy = localStorage.getItem('battleStrategy');
    return savedStrategy || 'Normal';
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        '/api/battle/battle',
        {
          teamOne: [
            { mercenaryId, strategy, surrenderHP: surrenderAt / 100 },
          ],
          teamTwo: [
            { beastId: beast.id, strategy: 'Normal', surrenderHP: 0, type: 'beast' },
          ],
          deathMatch: false,
          playerMercenaryId: mercenaryId,
          title: `Beast Battle - ${beast.name}`,
          battleType: 'Beast Battle',
        },
        { withCredentials: true }
      );
      navigate(`/battle-result/${response.data.battleId}`);
    } catch (err: any) {
      console.error('Error initiating battle:', err);
      displayToast(err.response?.data?.message || 'Failed to initiate battle. Please try again.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  useEffect(() => {
    localStorage.setItem('surrenderAt', surrenderAt.toString());
  }, [surrenderAt]);

  useEffect(() => {
    localStorage.setItem('battleStrategy', strategy);
  }, [strategy]);

  return (
    <div className={styles.beastBattleContainer}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}

      <h2>Prepare for Battle</h2>
      <div className={styles.beastDetails}>
        <h3>Beast Details</h3>
        <p><strong>Name:</strong> {beast.name}</p>
        <p><strong>Description:</strong> {beast.description}</p>
        <p><strong>Level:</strong> {beast.level}</p>
        <p><strong>Loot:</strong> {beast.loot.join(', ')}</p>
      </div>

      <form onSubmit={handleSubmit} className={styles.battlePreparationForm}>
        <div className={styles.formGroup}>
          <label htmlFor="surrenderAt">Surrender at HP %:</label>
          <select
            id="surrenderAt"
            value={surrenderAt}
            onChange={(e) => setSurrenderAt(parseInt(e.target.value))}
            required
          >
            {[90, 80, 70, 60, 50, 40, 30, 20, 10, 0].map((value) => (
              <option key={value} value={value}>{value}%</option>
            ))}
          </select>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="strategy">Battle Strategy:</label>
          <select
            id="strategy"
            value={strategy}
            onChange={(e) => setStrategy(e.target.value)}
            required
          >
            <option value="Normal">Normal</option>
            <option value="Normal: Light Attacks">Normal: Light Attacks</option>
            <option value="Normal: Heavy Attacks">Normal: Heavy Attacks</option>
            <option value="Offensive">Offensive</option>
            <option value="Offensive: Light Attacks">Offensive: Light Attacks</option>
            <option value="Offensive: Heavy Attacks">Offensive: Heavy Attacks</option>
            <option value="Defensive">Defensive</option>
            <option value="Defensive: Light Attacks">Defensive: Light Attacks</option>
            <option value="Defensive: Heavy Attacks">Defensive: Heavy Attacks</option>
            <option value="Berserk">Berserk</option>
            <option value="Berserk: Light Attacks">Berserk: Light Attacks</option>
            <option value="Berserk: Heavy Attacks">Berserk: Heavy Attacks</option>
          </select>
        </div>

        <button type="submit" className={styles.battleButton} disabled={isLoading}>
          {isLoading ? 'Preparing...' : 'Battle'}
        </button>
      </form>
    </div>
  );
};

export default BeastBattle;
