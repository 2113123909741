import React, { useState } from 'react';
import styles from './Book.module.css';

const pages = [
  "Welcome to Njordheim, a realm of fierce warriors and mighty battles...",
  "In Njordheim, you can create and train your mercenary to conquer the arena...",
  "Join guilds, compete for glory, and become the champion of Njordheim...",
  "Battles are fought fiercely. Equip your mercenary wisely to survive...",
  "Earning silver and gaining reputation are key to advancing...",
  "Embark on quests, prove your worth, and rise as a champion!"
];

const Book: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const nextPage = () => {
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className={styles.book}>
      <div className={styles.page}>
        <p>{pages[currentPage]}</p>
      </div>
      <div className={styles.controls}>
        <button onClick={prevPage} disabled={currentPage === 0} className={styles.pageButton}>
          ◀ Previous
        </button>
        <button onClick={nextPage} disabled={currentPage === pages.length - 1} className={styles.pageButton}>
          Next ▶
        </button>
      </div>
    </div>
  );
};

export default Book;
