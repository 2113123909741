import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Tooltip from '../ItemTooltip';
import { Item } from '../../types/Item';
import styles from './AssignItemsToVendor.module.css'; // Using CSS Modules

interface Vendor {
  id: number;
  name: string;
  description: string;
}

const AssignItemsToVendor: React.FC = () => {
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<number | null>(null);
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [selectedItemType, setSelectedItemType] = useState<string | null>(null);
  const [stock, setStock] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [itemPreview, setItemPreview] = useState<Item | null>(null);

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await axios.get('/api/admin/vendors', { withCredentials: true });
        setVendors(response.data);
      } catch (error) {
        console.error('Error fetching vendors:', error);
      }
    };

    const fetchItems = async () => {
      try {
        const response = await axios.get('/api/admin/items', { withCredentials: true });
        setItems(response.data);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    fetchVendors();
    fetchItems();
  }, []);

  const handleItemChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const [itemId, itemType] = e.target.value.split(':');
    setSelectedItem(parseInt(itemId, 10));
    setSelectedItemType(itemType);
    const item = items.find(i => i.id === parseInt(itemId, 10) && i.itemType === itemType) || null;
    setItemPreview(item);
  };

  const handleSearchItemClick = (item: Item) => {
    setSelectedItem(item.id);
    setSelectedItemType(item.itemType);
    setItemPreview(item);
  };

  const filteredItems = items.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedVendor === null || selectedItem === null || !selectedItemType) return;

    try {
      await axios.post('/api/admin/assign-item', {
        vendorId: selectedVendor,
        itemId: selectedItem,
        itemType: selectedItemType,
        stock,
      }, { withCredentials: true });
      alert('Item assigned successfully!');
    } catch (error) {
      console.error('Error assigning item:', error);
      alert('Failed to assign item.');
    }
  };

  return (
    <div className={styles.assignItemsContainer}>
      <h1>Assign Items to Vendors</h1>
      <form onSubmit={handleSubmit} className={styles.assignItemsForm}>
        <div className={styles.formGroup}>
          <label>Select Vendor:</label>
          <select
            value={selectedVendor || ''}
            onChange={(e) => setSelectedVendor(parseInt(e.target.value, 10))}
            required
          >
            <option value="">Select a vendor</option>
            {vendors.map(vendor => (
              <option key={vendor.id} value={vendor.id}>{vendor.name}</option>
            ))}
          </select>
        </div>

        <div className={styles.formGroup}>
          <label>Search Items:</label>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search for items..."
          />
        </div>

        <div className={styles.formGroup}>
          <label>Select Item:</label>
          <select
            value={selectedItem ? `${selectedItem}:${selectedItemType}` : ''}
            onChange={handleItemChange}
            required
          >
            <option value="">Select an item</option>
            {filteredItems.map(item => (
              <option key={item.id} value={`${item.id}:${item.itemType}`}>
                {item.name} (Price: {item.value})
              </option>
            ))}
          </select>
        </div>

        <div className={styles.formGroup}>
          <label>Stock:</label>
          <input
            type="number"
            value={stock}
            onChange={(e) => setStock(parseInt(e.target.value, 10))}
            min="1"
            required
          />
        </div>

        <button type="submit" className={styles.assignButton}>Assign Item</button>
      </form>

      {/* Display item list with tooltips */}
      <ul className={styles.itemList}>
        {filteredItems.map(item => (
          <li
            key={item.id}
            className={styles.itemListEntry}
            onClick={() => handleSearchItemClick(item)}
          >
            <Tooltip itemId={item.id}>
              <span>{item.name} (Price: {item.value})</span>
            </Tooltip>
          </li>
        ))}
      </ul>

      {/* Preview selected item */}
      {itemPreview && (
        <div className={styles.itemPreview}>
          <h3>Item Preview</h3>
          <p><strong>Name:</strong> {itemPreview.name}</p>
          <p><strong>Description:</strong> {itemPreview.description}</p>
          <p><strong>Type:</strong> {itemPreview.itemType}</p>
          <p><strong>Value:</strong> {itemPreview.value}</p>
          <img src={itemPreview.img || '/img/no_img.png'} alt={itemPreview.name} />
        </div>
      )}
    </div>
  );
};

export default AssignItemsToVendor;
