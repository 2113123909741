import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './contexts/AuthContext';
import { useMercenary } from './contexts/MercenaryContext';
import { useNavigate } from 'react-router-dom';

const AuthInitializer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { setUser } = useAuth();
  const { setMercenary, mercenaryId, setMercenaryId } = useMercenary();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userResponse = await axios.get('https://njordheim.com/api/auth/user', { withCredentials: true });
        if (userResponse.data) {
          setUser(userResponse.data);
          if (userResponse.data.isDead && userResponse.data.deathBattleReport) {
            navigate(`/deathscreen/${userResponse.data.deathBattleReport}`);
          }

          // Check for mercenary based on mercenaryId or fallback to first mercenary if mercenaryId is missing
          let mercenaryResponse;
          if (mercenaryId && mercenaryId !== undefined) {
            try {
              mercenaryResponse = await axios.get(`https://njordheim.com/api/mercenary/${mercenaryId}`, { withCredentials: true });
            } catch (error) {
              console.error('Failed to fetch mercenary by ID, attempting to fetch first mercenary:', error);
            }
          }

          if (!mercenaryResponse || mercenaryResponse.status === 404) {
            mercenaryResponse = await axios.get('https://njordheim.com/api/mercenary/first', { withCredentials: true });
            setMercenaryId(mercenaryResponse.data.id);
          }

          if (mercenaryResponse && mercenaryResponse.data) {
            setMercenary(mercenaryResponse.data);
          } else {
            navigate('/create-mercenary');
          }
        }
      } catch (error) {
        console.error('Failed to fetch user:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [setUser, setMercenary, setMercenaryId, mercenaryId, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return <>{children}</>;
};

export default AuthInitializer;
