// MercenaryBar.tsx
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useSocket } from '../../contexts/SocketContext';
import { useMercenary } from '../../contexts/MercenaryContext';
import styles from './MercenaryBar.module.css';
import battleNotificationSound from '../../sounds/battle_notification.mp3';

interface MercenaryStats {
  id?: number;
  name?: string;
  race?: string;
  sex?: string;
  avatar?: number;
  level?: number;
  health?: number;
  maxHealth?: number;
  experience?: number;
  nextExperience?: number;
  previousExperience?: number;
  rounds?: number;
  maxRounds?: number;
  form?: number;
  silver?: number;
  reputation?: number;
  allowContracts?: boolean;
  primarySkillPoints?: number;
  secondarySkillPoints?: number;
}

const MercenaryBar: React.FC = () => {
  const navigate = useNavigate();
  const { socket } = useSocket();
  const { mercenary, setMercenary } = useMercenary();
  const [mercenaryStats, setMercenaryStats] = useState<MercenaryStats | null>(mercenary);
  const [allowContracts, setAllowContracts] = useState<boolean>(false);

  const [battleNotification, setBattleNotification] = useState<{ battleId: number } | null>(null);
  const notificationAudioRef = useRef(new Audio(battleNotificationSound));
  const [levelUpNotification, setLevelUpNotification] = useState(false);
  const [regenTimerProgress, setRegenTimerProgress] = useState<number>(0);

  const [pendingFriendRequests, setPendingFriendRequests] = useState<number>(0);
  const [unreadMails, setUnreadMails] = useState<number>(0);

  useEffect(() => {
    if (socket) {
      socket.on('update_mercenary_stats', (data: { mercenaryId: number; updatedStats: Partial<MercenaryStats> }) => {
          const { mercenaryId, updatedStats } = data;
          const activeMercenaryId = parseInt(localStorage.getItem('mercenaryId') || '', 10);
          if (mercenaryId === activeMercenaryId) {
            setMercenaryStats((prevState: MercenaryStats | null) => ({
                ...prevState,
                ...updatedStats,
            }));
          }
      });

      socket.on('battle_notification', (battleId: number) => {
        setBattleNotification({ battleId });
        notificationAudioRef.current.play().catch((error) => {
          console.error('Failed to play sound:', error);
        });

        setTimeout(() => {
          setBattleNotification(null);
        }, 30000);
      });

      socket.on('level_up_notification', () => {
        setLevelUpNotification(true);
      });

      socket.on('mercenary_regen', (data: { mercenaryId: number; regen: { hp: number; rounds: number } }) => {
        const { mercenaryId, regen } = data;
        const activeMercenaryId = parseInt(localStorage.getItem('mercenaryId') || '', 10);
        if (mercenaryId === activeMercenaryId) {
            setMercenaryStats((prevState) => {
                if (!prevState) return prevState;
                return {
                    ...prevState,
                    health: regen.hp,
                    rounds: regen.rounds,
                };
            });
        }
      });

      socket.on('friendRequestHandled', () => {
        setPendingFriendRequests((prev) => Math.max(prev - 1, 0));
      });

      socket.on('newMail', () => {
        setUnreadMails(prev => prev + 1);
      });

      socket.on('mailRead', () => {
        setUnreadMails(prev => Math.max(prev - 1, 0));
      });

      return () => {
        socket.off('update_mercenary_stats');
        socket.off('battle_notification');
        socket.off('level_up_notification');
        socket.off('mercenary_regen');
        socket.off('newFriendRequest');
        socket.off('friendRequestHandled');
        socket.off('newMail');
        socket.off('mailRead');
      };
    }
  }, [socket]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const friendResponse = await axios.get('/api/friend/pending-count', { withCredentials: true });
        setPendingFriendRequests(friendResponse.data.count);

        const mailResponse = await axios.get('/api/mail/unread-count', { withCredentials: true });
        setUnreadMails(mailResponse.data.count);
      } catch (error) {
        console.error("Error fetching notification counts:", error);
      }
    };

    fetchNotifications();
  }, []);

  useEffect(() => {
    if (mercenary) {
      setMercenaryStats(mercenary);

      if (mercenary.allowContracts !== undefined) {
        setAllowContracts(mercenary.allowContracts);
      }

      if (mercenary.primarySkillPoints > 0 || mercenary.secondarySkillPoints > 0) {
        setLevelUpNotification(true);
      } else {
        setLevelUpNotification(false);
      }
    }
  }, [mercenary]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRegenTimerProgress(Date.now() % 180000);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const toggleAllowContracts = async () => {
    try {
      const updatedValue = !allowContracts;
      await axios.patch(`/api/contract/${mercenaryStats?.id}/allow-contracts`, {
        allowContracts: updatedValue,
      });
  
      setAllowContracts(updatedValue);
      setMercenaryStats((prev) => (prev ? { ...prev, allowContracts: updatedValue } : prev));
    } catch (error) {
      console.error('Failed to update allowContracts:', error);
    }
  };

  if (!mercenaryStats) {
    return <p>No mercenary found.</p>;
  }

  const healthPercentage = (mercenaryStats.health ?? 0) / (mercenaryStats.maxHealth ?? 1) * 100;
  const currentLevelExp = (mercenaryStats.experience ?? 0) - (mercenaryStats.previousExperience ?? 0);
  const levelExpNeeded = (mercenaryStats.nextExperience ?? 0) - (mercenaryStats.previousExperience ?? 0);
  const hasLeveledUp = currentLevelExp >= levelExpNeeded;
  const experiencePercentage = hasLeveledUp ? 100 : (currentLevelExp / levelExpNeeded) * 100;
  const experienceDisplay = hasLeveledUp ? "Leveled up!" : `${currentLevelExp} / ${levelExpNeeded} Exp`;
  const minutes = Math.floor((180000 - regenTimerProgress) / 60000);
  const seconds = Math.floor(((180000 - regenTimerProgress) % 60000) / 1000);

  const handleViewBattle = () => {
    if (battleNotification) {
      navigate(`/battle-result/${battleNotification.battleId}`);
    }
    setBattleNotification(null);
  };

  const handleDismissNotification = () => {
    setBattleNotification(null);
  };

  const handleLevelUp = () => {
    navigate('/level-up');
    setLevelUpNotification(false);
  };

  return (
    <div className={styles.mercenaryBar}>
      <div className={styles.contentContainer}>
        <div className={styles.mercenaryAvatar}>
          <img
            src={`/img/avatars/${mercenaryStats.race?.toLowerCase() ?? 'default'}.${mercenaryStats.sex?.toLowerCase() ?? 'default'}.${mercenaryStats.avatar ?? 1}.png`}
            alt={mercenaryStats.name ?? 'Unknown Mercenary'}
          />
          <h2>{mercenaryStats.name ?? 'Unknown Mercenary'}</h2>
          <p>Level: {mercenaryStats.level ?? 1}</p>

          {battleNotification && (
            <div className={styles.battleNotification}>
              <p>I just participated in a battle, want to see it?</p>
              <button onClick={handleViewBattle}>View Battle</button>
              <button onClick={handleDismissNotification}>Close</button>
            </div>
          )}
        </div>

        <div className={styles.mercenaryStats}>
          <div className={styles.stat}>
            <div className={`${styles.progressBar} ${styles.hpBar}`}>
              <div className={styles.fill} style={{ width: `${healthPercentage}%` }} />
              <span>{Math.floor(mercenaryStats.health ?? 0)} / {Math.floor(mercenaryStats.maxHealth ?? 1)} HP</span>
            </div>
          </div>
          <div className={styles.stat}>
            <div className={`${styles.progressBar} ${styles.epBar}`}>
              <div className={styles.fill} style={{ width: `${experiencePercentage}%` }} />
              <span>{experienceDisplay}</span>
            </div>
          </div>
          <div className={styles.stat}>
            <div className={`${styles.progressBar} ${styles.regenBar}`}>
              <div className={styles.fill} style={{ width: `${(regenTimerProgress / 180000) * 100}%` }} />
              <span>{minutes}:{seconds < 10 ? `0${seconds}` : seconds} Regen</span>
            </div>
          </div>
          <div className={styles.mercenaryForm}>
            <div className={styles.labelValuePair}>
              <label>Form:</label>
              <span>{mercenaryStats.form}%</span>
            </div>
            <div className={styles.labelValuePair}>
              <label>Rounds:</label>
              <span>{Math.floor(mercenaryStats.rounds ?? 0)} / {mercenaryStats.maxRounds ?? 0}</span>
            </div>
            <div className={styles.labelValuePair}>
              <label>Silver:</label>
              <span>{mercenaryStats.silver}</span>
            </div>
            <div className={styles.labelValuePair}>
              <label>Reputation:</label>
              <span>{mercenaryStats.reputation}</span>
            </div>
            <div className={styles.labelValuePair}>
              <label>Allow Contracts:</label>
              <div
                className={`${styles.contractIndicator} ${allowContracts ? styles.enabled : styles.disabled}`}
                onClick={toggleAllowContracts}
              >
                {allowContracts ? 'Yes' : 'No'}
              </div>
            </div>
          </div>
        </div>

        {levelUpNotification && (
          <div className={styles.levelUpNotification}>
            <button onClick={handleLevelUp} className={styles.levelUpButton}>Level Up!</button>
          </div>
        )}

        {/* Spacer to push links down */}
        <div className={styles.spacer} />

        <div className={styles.mercenaryLinks}>
          <h3>Mercenary Links</h3>
          <ul>
            <li><Link to="/mercenary">Profile</Link></li>
            <li><Link to="/battle-history">Battle History</Link></li>
            <li><Link to="/inventory">Inventory</Link></li>
            {/* <li><Link to="/bio">Bio</Link></li>
            <li><Link to="/statistics">Statistics</Link></li>
            <li><Link to="/attributes">Attributes</Link></li>
            <li><Link to="/profession">Profession</Link></li>
            <li><Link to="/achievements">Achievements</Link></li>
            <li><Link to="/activity">Activity</Link></li> */}
          </ul>
        </div>

        <div className={styles.accountLinks}>
          <h3>Account Links</h3>
          <ul>
            <li><Link to="/mercenaries">My Mercenaries</Link></li>
            {/* <li><Link to="/notes">Notes</Link></li> */}
            <li>
            <Link to="/friends">Friends List</Link>
              {pendingFriendRequests > 0 && (
                <span className={styles.notificationBadge}>{pendingFriendRequests}</span>
              )}
            <li>
              <Link to="/mail">Mail</Link>
              {unreadMails > 0 && (
                <span className={styles.notificationBadge}>{unreadMails}</span>
              )}
            </li>
            </li>
            <li><Link to="/account">Account</Link></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MercenaryBar;
