import React from 'react';

const Bio: React.FC = () => {
  return (
    <div>
      <h1>Bio</h1>
      <p>This is the Bio component.</p>
    </div>
  );
};

export default Bio;
