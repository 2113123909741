import React from 'react';
import axios from 'axios';
import { useMercenary } from '../../contexts/MercenaryContext';
import { useNavigate, Link } from 'react-router-dom';
import { useSocket } from '../../contexts/SocketContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShop, faStore, faDragon, faHandRock, faUserGroup, faTrophy, faMedal, faMap, faScaleUnbalancedFlip, faBeer } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as AnvilIcon } from '../../assets/icons/anvil.svg';
import { ReactComponent as ScrollIcon } from '../../assets/icons/scroll.svg';
import styles from './LeftNavBar.module.css';

const LeftNavBar: React.FC = () => {
  const navigate = useNavigate();
  const { socket } = useSocket();
  const { mercenary, setMercenary } = useMercenary();

  const handleLogout = async () => {
    try {
      await axios.get('https://njordheim.com/api/auth/logout', { withCredentials: true });

      if (socket) {
        socket.emit('logout');
        socket.disconnect();
      }

      setMercenary(null);
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <div className={styles.leftMenu}>
      <nav className={styles.navLinks}>
        {/* Battle Category */}
        <h3 className={styles.categoryTitle}>Battle</h3>
        <Link to="/beasts" className={styles.navLink}>
          <FontAwesomeIcon icon={faDragon} className={styles.icon} />
          Beasts
        </Link>
        <Link to="/duel" className={styles.navLink}>
          <FontAwesomeIcon icon={faHandRock} className={styles.icon} />
          Duel
        </Link>
        <Link to="/contract-battle" className={styles.navLink}>
          <ScrollIcon className={`${styles.icon} ${styles.svgIcon}`} />
          Contract Battles
        </Link>
        <Link to="/team-battle" className={styles.navLink}>
          <FontAwesomeIcon icon={faUserGroup} className={styles.icon} />
          Team Battles
        </Link>
        <Link to="/tournament" className={styles.navLink}>
          <FontAwesomeIcon icon={faMedal} className={styles.icon} />
          Tournaments
        </Link>

        {/* City Category */}
        <h3 className={styles.categoryTitle}>City</h3>
        <Link to="/market" className={styles.navLink}>
          <FontAwesomeIcon icon={faStore} className={styles.icon} />
          Market
        </Link>
        <Link to="/auction" className={styles.navLink}>
          <FontAwesomeIcon icon={faScaleUnbalancedFlip} className={styles.icon} />
          Auction House
        </Link>
        <Link to="/tavern" className={styles.navLink}>
          <FontAwesomeIcon icon={faBeer} className={styles.icon} />
          Stonebolt's Tavern
        </Link>
        <Link to="/work" className={styles.navLink}>
          <FontAwesomeIcon icon={faShop} className={styles.icon} />
          Work District
        </Link>
        <Link to="/craft" className={styles.navLink}>
          <AnvilIcon className={`${styles.icon} ${styles.svgIcon}`} />
          Crafting District
        </Link>
        <Link to="/vault" className={styles.navLink}>
          <FontAwesomeIcon icon={faStore} className={styles.icon} />
          Glacier Vault
        </Link>
        <Link to="/ranking" className={styles.navLink}>
          <FontAwesomeIcon icon={faTrophy} className={styles.icon} />
          The Wall of Legends
        </Link>


        {mercenary?.id === 69 && (
          <Link to="/adventures" className={styles.navLink}>
            <FontAwesomeIcon icon={faMap} className={styles.icon} /> 
            Adventures
          </Link>
        )}
      </nav>
      <div className={styles.logoutContainer}>
        <button onClick={handleLogout} className={styles.logoutButton}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default LeftNavBar;
