import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import {
  Chart,
  ArcElement,
  PointElement,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';

// Register elements
Chart.register(ArcElement, PointElement, LineElement, BarElement, CategoryScale, LinearScale);

const UserMetrics: React.FC = () => {
  const [userStats, setUserStats] = useState({
    activeUsers: 0,
    newUsers: 0,
    returningUsers: 0,
    totalUsers: 0,
  });

  useEffect(() => {
    // Fetch user statistics
    const fetchUserStats = async () => {
      try {
        const response = await axios.get('/api/admin/user-stats');
        setUserStats(response.data);
      } catch (error) {
        console.error('Error fetching user statistics', error);
      }
    };

    fetchUserStats();
  }, []);

  const data = {
    labels: ['Active Users', 'New Users', 'Returning Users', 'Total Users'],
    datasets: [
      {
        label: 'Users',
        backgroundColor: ['#00bfff', '#ffdd57', '#ff6f61', '#66b266'],
        data: [userStats.activeUsers, userStats.newUsers, userStats.returningUsers, userStats.totalUsers],
      },
    ],
  };

  return (
    <div className="metric-card">
      <h3>User Activity</h3>
      <Bar data={data} />
    </div>
  );
};

export default UserMetrics;
