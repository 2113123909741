// src/contexts/SocketContext.tsx

import React, { createContext, useContext, useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import { useAuth } from './AuthContext';

// Define the context type
interface SocketContextType {
  socket: Socket | null;
  setSocket: React.Dispatch<React.SetStateAction<Socket | null>>;
}

const SocketContext = createContext<SocketContextType | undefined>(undefined);

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    if (user) {
      const newSocket = io('https://njordheim.com', {
        withCredentials: true,
        transports: ['websocket'],
      });

      newSocket.on('connect', () => {
        setSocket(newSocket);
        newSocket.emit('join_room', user.id);
      });

      // Listen for friend requests
      newSocket.on('newFriendRequest', (data) => {
        console.log('New friend request received:', data);
        // Optionally, handle notifications (e.g., update state or show alert)
      });

      // Listen for accepted friend requests
      newSocket.on('friendRequestAccepted', (data) => {
        console.log('Friend request accepted:', data);
        // Optionally, handle friend request acceptance
      });

      // Listen for new mail notifications
      newSocket.on('newMail', (mail) => {
        console.log('New mail received:', mail);
        // Optionally, handle mail notifications (e.g., update state or show alert)
      });

      // Listen for friend online status
      newSocket.on('friendOnline', (friendId) => {
        // console.log(`Friend ${friendId} is online`);
        // Optionally, update friend online status in state
      });

      // Listen for friend offline status
      newSocket.on('friendOffline', (friendId) => {
        // console.log(`Friend ${friendId} is offline`);
        // Optionally, update friend offline status in state
      });

      newSocket.on('disconnect', (reason) => {
        console.warn('Disconnected from socket. Reason:', reason);
      });

      newSocket.on('error', (error) => {
        console.error('Socket error:', error);
      });

      return () => {
        newSocket.close();
        setSocket(null);
      };
    }
  }, [user]);

  return (
    <SocketContext.Provider value={{ socket, setSocket }}>
      {children}
    </SocketContext.Provider>
  );
};
