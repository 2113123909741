import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import Toast from '../Toast';
import Tooltip from '../ItemTooltip';
import styles from './Profile.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

interface Stats {
  winsTournament: number;
  lossTournament: number;
  winsDuel: number;
  lossDuel: number;
  winsTeamBattle: number;
  lossTeamBattle: number;
  winsClanLeague: number;
  lossClanLeague: number;
  winsPvE: number;
  lossPvE: number;
  winsContract: number;
  lossContract: number;
  tournamentFirstPlace: number;
  tournamentSecondPlace: number;
  tournamentThirdPlace: number;
  highestDamageDealt: number;
  mostDamageDone: number;
  mostDamageTaken: number;
  mostAttacksMade: number;
  mostDodgedAttacks: number;
  mostParries: number;
  mostBlockedAttacks: number;
  mostMissedAttacks: number;
  mostCriticalHits: number;
}

interface Mercenary {
  userId: number;
  name: string;
  race: string;
  age: number;
  sex: string;
  level: number;
  reputation: number;
  avatar: string;
  title: string;
  kills: number;
  health: number;
  maxHealth: number;
  stats: Stats | null;
  equipment: {
    slot: string;
    Item: { id: number; img: string; name: string; description: string } | null;
  }[];
}

const defaultStats: Stats = {
  winsTournament: 0,
  lossTournament: 0,
  winsDuel: 0,
  lossDuel: 0,
  winsTeamBattle: 0,
  lossTeamBattle: 0,
  winsClanLeague: 0,
  lossClanLeague: 0,
  winsPvE: 0,
  lossPvE: 0,
  winsContract: 0,
  lossContract: 0,
  tournamentFirstPlace: 0,
  tournamentSecondPlace: 0,
  tournamentThirdPlace: 0,
  highestDamageDealt: 0,
  mostDamageDone: 0,
  mostDamageTaken: 0,
  mostAttacksMade: 0,
  mostDodgedAttacks: 0,
  mostParries: 0,
  mostBlockedAttacks: 0,
  mostMissedAttacks: 0,
  mostCriticalHits: 0,
};

const Profile: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();
  const mercenaryId = parseInt(id || '0', 10);
  const [mercenary, setMercenary] = useState<Mercenary | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isFriend, setIsFriend] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  useEffect(() => {
    const fetchMercenary = async () => {
      try {
        const response = await axios.get(`/api/mercenary/profile/${mercenaryId}`);
        const data = response.data;
        const equipment = data.Equipment || [];
        setMercenary({ ...data, equipment });
      } catch (error) {
        console.error('Error fetching mercenary profile:', error);
        displayToast('Failed to load mercenary profile.', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchMercenary();
  }, [mercenaryId]);

  if (loading) return <div>Loading profile...</div>;

  if (!mercenary) return <p className={styles.errorMessage}>Mercenary not found.</p>;

  const stats = mercenary.stats || defaultStats;

  const calculateStats = (wins: number, losses: number) => {
    const total = wins + losses;
    const winPercentage = total > 0 ? ((wins / total) * 100).toFixed(2) : '0';
    return { total, winPercentage };
  };

  const handleSendFriendRequest = async () => {
    try {
      const response = await axios.post(`/api/friend/request`, {
        userId: user?.id,
        friendId: mercenary?.userId,
      });
      if (response.data.success) {
        setIsFriend(true);
        displayToast('Friend request sent successfully!', 'success');
      }
    } catch (error) {
      console.error('Error sending friend request:', error);
      displayToast('Failed to send friend request. Please try again.', 'error');
    }
  };

  const equippedCategories = [
    { id: 'Head', label: 'Head', className: styles.head, isSmallItem: false },
    { id: 'Torso', label: 'Torso', className: styles.torso, isSmallItem: false },
    { id: 'Legs', label: 'Legs', className: styles.legs, isSmallItem: false },
    { id: 'Feet', label: 'Feet', className: styles.feet, isSmallItem: false },
    { id: 'mainHand', label: 'Main Hand', className: styles.mainHand, isSmallItem: false },
    { id: 'offHand', label: 'Off Hand', className: styles.offHand, isSmallItem: false },
    { id: 'Hands', label: 'Hands', className: styles.hands, isSmallItem: false },
    { id: 'Shoulders', label: 'Shoulders', className: styles.shoulders, isSmallItem: false },
    { id: 'Amulet', label: 'Amulet', className: `${styles.amulet} ${styles.smallItemSlot}`, isSmallItem: true },
    { id: 'Armring', label: 'Armring', className: `${styles.armring} ${styles.smallItemSlot}`, isSmallItem: true },
    { id: 'Cloak', label: 'Cloak', className: `${styles.cloak} ${styles.smallItemSlot}`, isSmallItem: true },
    { id: 'Neck', label: 'Neck', className: `${styles.neck} ${styles.smallItemSlot}`, isSmallItem: true },
    { id: 'Ring', label: 'Ring', className: `${styles.ring} ${styles.smallItemSlot}`, isSmallItem: true },
  ];

  const normalizeSlot = (slot: string) => {
    if (slot === 'mainHand') return 'mainHand';
    if (slot === 'offHand') return 'offHand';
    if (slot === 'twoHand') return 'mainHand';
    if (slot === 'ring') return 'ring';
    if (slot === 'neck') return 'neck';
    if (slot === 'armring') return 'armring';
    if (slot === 'amulet') return 'amulet';
    if (slot === 'cloak') return 'cloak';
    return slot;
  };

  return (
    <div className={styles.profileContainer}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}

      {/* Back arrow */}
      <Link to="/ranking" className={styles.backLink}>
        ← Back
      </Link>

      {mercenary && (
        <>
          <div className={styles.titleContainer}>
            <h1 className={styles.profileTitle}>
              {mercenary.title} - {mercenary.name}
            </h1>
            <FontAwesomeIcon
              icon={faUserPlus}
              className={styles.friendRequestIcon}
              onClick={handleSendFriendRequest}
              title="Send Friend Request"
            />
          </div>

          {/* General Info and Equipped Items */}
          <div className={styles.equippedItemsAndGeneralInfoSection}>
            <div className={styles.generalInfo}>
              <h2 className={styles.profileLabel}>General Information</h2>
              <img
                src={`/img/avatars/${mercenary.race?.toLowerCase() ?? 'default'}.${mercenary.sex?.toLowerCase() ?? 'default'}.${mercenary.avatar ?? 1}.png`}
                alt={mercenary.name}
                className={styles.avatar}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.onerror = null;
                  target.src = '/img/avatars/no_img.png';
                }}
              />
              <p><strong>Race:</strong> {mercenary.race}</p>
              <p><strong>Sex:</strong> {mercenary.sex}</p>
              <p><strong>Age:</strong> {mercenary.age}</p>
              <p><strong>Title:</strong> {mercenary.title}</p>
              <p><strong>Level:</strong> {mercenary.level}</p>
              <p><strong>Reputation:</strong> {mercenary.reputation}</p>
              <p><strong>Kills:</strong> {mercenary.kills}</p>
            </div>

            <div className={styles.equippedItems}>
              <h2 className={styles.profileLabel}>Equipped Items</h2>
              <div className={styles.equippedGrid}>
                {equippedCategories
                  .filter((category) => !category.isSmallItem)
                  .map(({ id, label, className }) => {
                    const item = mercenary.equipment?.find((eq) => normalizeSlot(eq.slot) === id);

                    return (
                      <div key={id} className={`${styles.equippedSlot} ${className}`}>
                        <h3>{label}</h3>
                        {item?.Item ? (
                          <Tooltip itemId={item.Item.id}>
                            <img
                              src={`/img/items/${item.Item.img || 'no_img'}.png`}
                              alt={item.Item.name}
                              className={styles.itemIcon}
                              onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.onerror = null;
                                target.src = '/img/items/no_img.png';
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <span className={styles.noItem}>Empty</span>
                        )}
                      </div>
                    );
                  })}
              </div>

              <div className={styles.equippedSmallItemsGrid}>
                {equippedCategories
                  .filter((category) => category.isSmallItem)
                  .map(({ id, label, className }) => {
                    const item = mercenary.equipment?.find((eq) => normalizeSlot(eq.slot) === id);

                    return (
                      <div key={id} className={`${styles.equippedSlot} ${className}`}>
                        <h3>{label}</h3>
                        {item?.Item ? (
                          <Tooltip itemId={item.Item.id}>
                            <img
                              src={`/img/items/${item.Item.img || 'no_img'}.png`}
                              alt={item.Item.name}
                              className={styles.itemIcon}
                              onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.onerror = null;
                                target.src = '/img/items/no_img.png';
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <span className={styles.noItem}>Empty</span>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          {/* Detailed Combat Stats */}
          <div className={styles.profileSection}>
            <h2 className={styles.profileLabel}>Detailed Combat Stats</h2>
            <table className={styles.statsTable}>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Total Battles</th>
                  <th>Wins</th>
                  <th>Losses</th>
                  <th>Win %</th>
                </tr>
              </thead>
              <tbody>
                {[
                  { type: 'PvE', wins: stats.winsPvE, losses: stats.lossPvE },
                  { type: 'Duel', wins: stats.winsDuel, losses: stats.lossDuel },
                  { type: 'Contract Battle', wins: stats.winsContract, losses: stats.lossContract },
                  { type: 'Team Battle', wins: stats.winsTeamBattle, losses: stats.lossTeamBattle },
                  { type: 'Tournament', wins: stats.winsTournament, losses: stats.lossTournament },
                  { type: 'Clan Battle', wins: stats.winsClanLeague, losses: stats.lossClanLeague },
                ].map((stat) => {
                  const { total, winPercentage } = calculateStats(stat.wins, stat.losses);
                  return (
                    <tr key={stat.type}>
                      <td>{stat.type}</td>
                      <td>{total}</td>
                      <td>{stat.wins}</td>
                      <td>{stat.losses}</td>
                      <td>{winPercentage}%</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* Tournament and Combat Metrics */}
          <div className={styles.profileSection}>
            <h2 className={styles.profileLabel}>Tournament and Combat Metrics</h2>
            <table className={styles.statsTable}>
              <thead>
                <tr>
                  <th>Metric</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr><td>1st Place Finishes</td><td>{stats.tournamentFirstPlace}</td></tr>
                <tr><td>2nd Place Finishes</td><td>{stats.tournamentSecondPlace}</td></tr>
                <tr><td>3rd Place Finishes</td><td>{stats.tournamentThirdPlace}</td></tr>
                <tr><td>Highest Damage Dealt</td><td>{stats.highestDamageDealt}</td></tr>
                <tr><td>Most Damage Done</td><td>{stats.mostDamageDone}</td></tr>
                <tr><td>Most Damage Taken</td><td>{stats.mostDamageTaken}</td></tr>
                <tr><td>Most Attacks Made</td><td>{stats.mostAttacksMade}</td></tr>
                <tr><td>Most Dodged Attacks</td><td>{stats.mostDodgedAttacks}</td></tr>
                <tr><td>Most Parries</td><td>{stats.mostParries}</td></tr>
                <tr><td>Most Blocked Attacks</td><td>{stats.mostBlockedAttacks}</td></tr>
                <tr><td>Most Missed Attacks</td><td>{stats.mostMissedAttacks}</td></tr>
                <tr><td>Most Critical Hits</td><td>{stats.mostCriticalHits}</td></tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default Profile;
