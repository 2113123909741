import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const MarkdownViewer: React.FC = () => {
  const { filename } = useParams<{ filename: string }>();
  const [content, setContent] = useState<string>('');

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await axios.get(`/api/admin/markdown/${filename}`, { withCredentials: true });
        setContent(response.data);
      } catch (error) {
        console.error('Error fetching Markdown file:', error);
      }
    };

    fetchMarkdown();
  }, [filename]);

  return (
    <div>
      <h1>{filename}</h1>
      <pre>{content}</pre>
    </div>
  );
};

export default MarkdownViewer;
