import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styles from './BattleResult.module.css';

interface ParticipantSummary {
  combatantId: number;
  team: string;
  type: string;
  level?: number;
  damage: number;
  highestDamageDealt: number;
  damageTaken: number;
  attacksMade: number;
  dodgedAttacks: number;
  parries: number;
  blockedAttacks: number;
  missedAttacks: number;
  criticalHits: number;
}

interface BattleResultData {
  reportData: {
    participantSummaries: { [key: string]: ParticipantSummary };
    teamSummaries: {
      teamOne: any;
      teamTwo: any;
    };
    combatLog: string[];
    seasonName: string;
    winner: string;
  };
  title: string;
  createdAt: string;
}

const BattleResult: React.FC = () => {
  const { battleId } = useParams<Record<string, string>>();
  const playerMercenaryId = parseInt(localStorage.getItem('mercenaryId') || '0');
  const [battleResult, setBattleResult] = useState<BattleResultData | null>(null);
  const [playerTeam, setPlayerTeam] = useState<string>('');

  const fetchBattleResult = async () => {
    try {
      const response = await axios.get(`https://njordheim.com/api/battle/result/${battleId}`, { withCredentials: true });
      const resultData = response.data;
      setBattleResult(resultData);

      const playerSummary = (Object.values(resultData.reportData.participantSummaries) as ParticipantSummary[]).find(
        (participant) => participant.combatantId === playerMercenaryId
      );
      if (playerSummary) {
        setPlayerTeam((playerSummary as ParticipantSummary).team);
      }
    } catch (error) {
      console.error('Error fetching battle result:', error);
    }
  };

  useEffect(() => {
    if (!battleResult) {
      fetchBattleResult();
    }
  }, [battleId, battleResult]);

  const getParticipantClass = (id: number) => {
    if (!battleResult) return styles.red;
  
    const participant = Object.values(battleResult.reportData.participantSummaries).find(
      (p: any) => p.combatantId === id
    );

    if (!participant) return styles.red;
    if (id === playerMercenaryId && participant.type === 'mercenary') return styles.green;
    if (participant.team === playerTeam) return styles.blue;
    return styles.red;
  };
  

  const renderTeamPreview = (teamType: string, participantSummaries: any) => {
    if (!participantSummaries) return null;

    const teamMembers = Object.values(participantSummaries).filter(
      (participant: any) => participant.team === teamType
    );

    return (
      <div className={styles.teamPreview}>
        <h4>{teamType === 'teamOne' ? 'Team One' : 'Team Two'}</h4>
        <ul>
          {teamMembers.map((participant: any, index: number) => (
            <li
              key={index}
              className={`${styles.participant} ${getParticipantClass(participant.combatantId)}`}
            >
              {participant.name} (Level {participant.level || 'N/A'})
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const parseLogWithColors = (log: string) => {
    return log.split(/(\{\{.*?\}\})/).map((segment, i) => {
      const match = segment.match(/\{\{(.*?)\}\}/);
      if (match) {
        const [team, id, name] = match[1].split(':');
        const className = getParticipantClass(parseInt(id));
        return (
          <span key={i} className={`${styles.participant} ${className}`}>
            {name}
          </span>
        );
      }
      return <span key={i}>{segment}</span>;
    });
  };

  const renderRewards = () => {
    const rewardsLog = battleResult?.reportData?.combatLog?.filter((log: string) =>
      log.includes('receives') || log.includes('has reached level')
    );
    return (
      <div className={styles.rewardsSection}>
        <h4>Battle Rewards</h4>
        {rewardsLog && rewardsLog.map((reward: string, index: number) => (
          <p key={index}>{reward}</p>
        ))}
      </div>
    );
  };

  const renderSummaryTable = (teamType: string, teamSummary: any, participantSummaries: any) => {
    if (!participantSummaries || !teamSummary) {
      return null;
    }

    return (
      <table className={styles.summaryTable}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Total Damage</th>
            <th>Largest Hit</th>
            <th>Damage Taken</th>
            <th>Attacks Made</th>
            <th>Dodges</th>
            <th>Parries</th>
            <th>Blocks</th>
            <th>Missed</th>
            <th>Critical</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(participantSummaries).map((key) => {
            const participant = participantSummaries[key];
            if (participant.team === teamType) {
              return (
                <tr key={key}>
                  <td>{participant.name}</td>
                  <td>{participant.damage}</td>
                  <td>{participant.highestDamageDealt}</td>
                  <td>{participant.damageTaken}</td>
                  <td>{participant.attacksMade}</td>
                  <td>{participant.dodgedAttacks}</td>
                  <td>{participant.parries}</td>
                  <td>{participant.blockedAttacks}</td>
                  <td>{participant.missedAttacks}</td>
                  <td>{participant.criticalHits}</td>
                </tr>
              );
            }
            return null;
          })}
        </tbody>
        <tfoot>
          <tr>
            <td>Team Total</td>
            <td>{teamSummary.totalDamage}</td>
            <td>{teamSummary.highestDamageDealt}</td>
            <td>{teamSummary.damageTaken}</td>
            <td>{teamSummary.attacksMade}</td>
            <td>{teamSummary.dodgedAttacks}</td>
            <td>{teamSummary.parries}</td>
            <td>{teamSummary.blockedAttacks}</td>
            <td>{teamSummary.missedAttacks}</td>
            <td>{teamSummary.criticalHits}</td>
          </tr>
        </tfoot>
      </table>
    );
  };

  return (
    <div className={styles.battleContainer}>
      {battleResult && battleResult.reportData ? (
        <>
          {/* Info Panel */}
          <div className={styles.infoPanel}>
            <h2>{battleResult.title}</h2>
            <p>
              <strong>{battleResult.reportData.seasonName} -</strong> {new Date(battleResult.createdAt).toLocaleDateString()}
            </p>
            <div className={styles.teamPreviews}>
              {renderTeamPreview('teamOne', battleResult.reportData.participantSummaries)}
              {renderTeamPreview('teamTwo', battleResult.reportData.participantSummaries)}
            </div>
          </div>

          {/* Combat Log */}
          <div className={styles.combatLog}>
            {battleResult.reportData.combatLog
              .filter(
                (log: string) =>
                  !log.includes('receives') && !log.includes('has reached level')
              )
              .map((log: string, index: number) => {
                if (log.startsWith('--- Round')) {
                  return (
                    <div key={index} className={styles.roundHeader}>
                      {log.replace(/---/g, '').trim()}
                    </div>
                  );
                }
                return <p key={index}>{parseLogWithColors(log)}</p>;
              })}
          </div>

          {/* Summary Tables */}
          <h3 className={styles.summaryHeading}>
            {battleResult.reportData.winner === 'teamOne' ? 'Team One Wins!' : 'Team Two Wins!'}
          </h3>
          <div className={styles.summaryTables}>
            {renderSummaryTable(
              'teamOne',
              battleResult.reportData.teamSummaries.teamOne,
              battleResult.reportData.participantSummaries
            )}
            {renderSummaryTable(
              'teamTwo',
              battleResult.reportData.teamSummaries.teamTwo,
              battleResult.reportData.participantSummaries
            )}
          </div>

          {/* Rewards Section */}
          {renderRewards()}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default BattleResult;