import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useMercenary } from '../../../contexts/MercenaryContext';
import Toast from '../Toast';
import './ActiveOrders.css';

interface Order {
    id: number;
    itemName: string;
    price: number;
    quantity: number;
    timeLeft: string;
    type: 'buy' | 'sell';
}

const ActiveOrders: React.FC = () => {
    const { mercenary } = useMercenary();
    const [buyOrders, setBuyOrders] = useState<Order[]>([]);
    const [sellOrders, setSellOrders] = useState<Order[]>([]);
    const [toastMessage, setToastMessage] = useState<string | null>(null);
    const [toastType, setToastType] = useState<'success' | 'error'>('success');

    useEffect(() => {
        if (mercenary?.id) {
            axios.get(`/api/auction/orders/active/${mercenary.id}`)
                .then(response => {
                    const { buyOrders, sellOrders } = response.data;
                    setBuyOrders(buyOrders.map((order: any) => ({
                        id: order.id,
                        itemName: order.Item.name,
                        price: order.price_offer,
                        quantity: order.quantity,
                        timeLeft: calculateTimeLeft(order.expires_at),
                        type: 'buy'
                    })));
                    setSellOrders(sellOrders.map((order: any) => ({
                        id: order.id,
                        itemName: order.Item.name,
                        price: order.price,
                        quantity: order.quantity,
                        timeLeft: calculateTimeLeft(order.expires_at),
                        type: 'sell'
                    })));
                })
                .catch(error => displayToast('Error fetching active orders', 'error'));
        }
    }, [mercenary]);

    const calculateTimeLeft = (expiresAt: string) => {
        const now = new Date();
        const expiration = new Date(expiresAt);
        const difference = expiration.getTime() - now.getTime();
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        return `${hours}h:${minutes}m`;
    };

    const cancelOrder = (orderId: number, orderType: 'buy' | 'sell') => {
        axios.post('/api/auction/orders/cancel', { orderId, orderType, mercenaryId: mercenary?.id })
            .then(response => {
                displayToast(response.data.message, 'success');
                if (orderType === 'buy') {
                    setBuyOrders(buyOrders.filter(order => order.id !== orderId));
                } else {
                    setSellOrders(sellOrders.filter(order => order.id !== orderId));
                }
            })
            .catch(error => {
                displayToast(error.response?.data.error || 'Failed to cancel order', 'error');
            });
    };

    const displayToast = (message: string, type: 'success' | 'error') => {
        setToastMessage(message);
        setToastType(type);
        setTimeout(() => setToastMessage(null), 3000);
    };

    return (
        <div className="active-orders">
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    type={toastType}
                    duration={3000}
                    onClose={() => setToastMessage(null)}
                />
            )}

            <div className="orders-section">
                <h3>Active Buy Orders</h3>
                {buyOrders.length > 0 ? (
                    <ul>
                        {buyOrders.map(order => (
                            <li key={order.id}>
                                <span className="order-item-name">{order.itemName}</span>
                                <span className="order-item-qty">Qty: {order.quantity}</span>
                                <span className="order-item-price">Price: {order.price}</span>
                                <span className="order-item-time">Time Left: {order.timeLeft}</span>
                                <button onClick={() => cancelOrder(order.id, 'buy')} className="cancel-button">X</button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No active buy orders.</p>
                )}
            </div>

            <div className="orders-section">
                <h3>Active Sell Orders</h3>
                {sellOrders.length > 0 ? (
                    <ul>
                        {sellOrders.map(order => (
                            <li key={order.id}>
                                <span className="order-item-name">{order.itemName}</span>
                                <span className="order-item-qty">Qty: {order.quantity}</span>
                                <span className="order-item-price">Price: {order.price}</span>
                                <span className="order-item-time">Time Left: {order.timeLeft}</span>
                                <button onClick={() => cancelOrder(order.id, 'sell')} className="cancel-button">X</button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No active sell orders.</p>
                )}
            </div>
        </div>
    );
};

export default ActiveOrders;
