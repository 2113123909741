// BeastEditor.tsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './BeastEditor.module.css';
import { Beast } from '../../types/Beast';
import { BeastWeapon } from '../../types/BeastWeapon';
import { BeastArmor } from '../../types/BeastArmor';

interface BeastEditorProps {
  mode: 'create' | 'edit';
}

const BeastEditor: React.FC<BeastEditorProps> = ({ mode }) => {
  const [selectedTab, setSelectedTab] = useState('beast');
  const [beastData, setBeastData] = useState<Beast | null>(null);
  const [allBeasts, setAllBeasts] = useState<Beast[]>([]);
  const [weapons, setWeapons] = useState<BeastWeapon[]>([]);
  const [armor, setArmor] = useState<BeastArmor[]>([]);
  const [newWeapon, setNewWeapon] = useState<BeastWeapon>({} as BeastWeapon);
  const [newArmor, setNewArmor] = useState<BeastArmor>({} as BeastArmor);
  const [loot, setLoot] = useState<string[]>([]);
  const [itemSearch, setItemSearch] = useState<string>('');
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [isCreating, setIsCreating] = useState<boolean>(mode === 'create');

  useEffect(() => {
    setIsCreating(mode === 'create');
  }, [mode]);
  
  // useEffect(() => {
  //   if (mode === 'edit') {
  //     setIsCreating(false);
  //   }
  // }, [mode]);

  const weaponSlotOptions = ["oneHand", "twoHand", "offHand"];
  const armorSlotOptions = ["head", "shoulders", "torso", "hands", "legs", "feet"];
  const capitalizeFirstLetter = (str: string | undefined) => (str ? str.charAt(0).toUpperCase() + str.slice(1) : '');

  useEffect(() => {
    axios.get('/api/admin/beasts').then((response) => setAllBeasts(response.data));
  }, []);

  useEffect(() => {
  }, [weapons]);

  useEffect(() => {
    setWeapons((prevWeapons) =>
      prevWeapons.map((weapon) => {
        if (weapon.slot === 'offHand' && weapon.itemCategory !== 'Shield') {
          return { ...weapon, slot: 'oneHand' };
        }
        return weapon;
      })
    );
  }, []);

  useEffect(() => {
    setArmor((prevArmor) =>
      prevArmor.map((armorItem) => ({
        ...armorItem,
        armorType: capitalizeFirstLetter(armorItem.slot),
      }))
    );
  }, [armor]);

  const handleAddWeapon = () => {
    if (weapons.length === 0) {
      setWeapons([...weapons, { ...newWeapon, slot: "mainHand", itemCategory: "Weapon", canEquipInOffHand: false }]);
    } 
    else if (weapons.length === 1 && weapons[0].slot === "mainHand") {
      setWeapons([...weapons, { ...newWeapon, slot: "offHand", itemCategory: "Weapon", canEquipInOffHand: false }]);
    } 
    else if (weapons.length === 1 && weapons[0].itemCategory === "Shield") {
      setWeapons([...weapons, { ...newWeapon, slot: "mainHand", itemCategory: "Weapon", canEquipInOffHand: false }]);
    } else {
      setWeapons([...weapons, { ...newWeapon, slot: "mainHand", itemCategory: "Weapon", canEquipInOffHand: false }]);
    }
  };

  const handleSelectBeast = (id: number) => {
    axios.get(`/api/admin/beasts/${id}`).then((response) => {
      setBeastData(response.data);
      setWeapons(response.data.weapons || []);
      setArmor(response.data.armor || []);
      setLoot(response.data.loot || []);
      setIsCreating(false);
    });
  };

  const handleSave = async () => {
    try {
      if (!beastData?.id) {
        console.error("Beast ID is missing; cannot save.");
        alert("Failed to save beast due to missing ID.");
        return;
      }

      const updatedBeastData = {
        ...beastData,
        weapons,
        armor,
        loot
      };
  
      if (isCreating) {
        const response = await axios.post('/api/admin/beasts/create', updatedBeastData);
        setBeastData({ ...beastData, id: response.data.id });
        alert("Beast created successfully with weapons and armor!");
      } else {
        await axios.put(`/api/admin/beasts/${beastData.id}`, updatedBeastData);
        alert("Beast updated successfully with weapons and armor!");
      }
    } catch (error) {
      console.error("Failed to save beast, weapons, or armor:", error);
      alert("An error occurred while saving the beast.");
    }
  };

  const handleSearch = async (query: string) => {
    setItemSearch(query);
    if (query) {
      const response = await axios.get(`/api/admin/items/search?term=${query}`);
      setSearchResults(response.data.map((item: { name: string }) => item.name));
    } else {
      setSearchResults([]);
    }
  };

  const addLootItem = (itemName: string) => {
    if (!loot.includes(itemName)) {
      setLoot([...loot, itemName]);
      setItemSearch('');
      setSearchResults([]);
    }
  };

  const removeLootItem = (itemName: string) => {
    setLoot(loot.filter(item => item !== itemName));
  };

  return (
    <div className={styles.beastEditor}>
      <h2>{isCreating ? 'Create New Beast' : `Edit Beast: ${beastData?.name}`}</h2>

      {!isCreating && (
        <div className={styles.dropdownSearch}>
          <label>Select Beast:</label>
          <select onChange={(e) => handleSelectBeast(parseInt(e.target.value))} defaultValue="">
            <option value="" disabled>Select a beast</option>
            {allBeasts.map((beast) => (
              <option key={beast.id} value={beast.id}>{beast.name}</option>
            ))}
          </select>
        </div>
      )}

      <div className={styles.tabButtons}>
        <button className={selectedTab === 'beast' ? styles.active : ''} onClick={() => setSelectedTab('beast')}>Beast</button>
        <button className={selectedTab === 'weapons' ? styles.active : ''} onClick={() => setSelectedTab('weapons')}>Weapons</button>
        <button className={selectedTab === 'armor' ? styles.active : ''} onClick={() => setSelectedTab('armor')}>Armor</button>
        <button className={selectedTab === 'loot' ? styles.active : ''} onClick={() => setSelectedTab('loot')}>Loot</button>
      </div>

      <div className={styles.tabContent}>
        {selectedTab === 'beast' && (
          <div className={styles.formSection}>
            <label>Name:</label>
            <input
              type="text"
              value={beastData?.name || ''}
              onChange={(e) => setBeastData({ ...beastData, name: e.target.value } as Beast)}
            />
            <label>Description:</label>
            <textarea
              value={beastData?.description || ''}
              onChange={(e) => setBeastData({ ...beastData, description: e.target.value } as Beast)}
            />
            <label>Avatar:</label>
            <input
              type="text"
              value={beastData?.avatar || ''}
              onChange={(e) => setBeastData({ ...beastData, avatar: e.target.value } as Beast)}
            />
            <label>Beast Level:</label>
            <input
              type="number"
              value={beastData?.level || ''}
              onChange={(e) => setBeastData({ ...beastData, level: parseInt(e.target.value) } as Beast)}
            />
            <label>Min Level:</label>
            <input
              type="number"
              value={beastData?.minRequiredLevel || ''}
              onChange={(e) => setBeastData({ ...beastData, minRequiredLevel: parseInt(e.target.value) } as Beast)}
            />
            <label>Max Level:</label>
            <input
              type="number"
              value={beastData?.maxRequiredLevel || ''}
              onChange={(e) => setBeastData({ ...beastData, maxRequiredLevel: parseInt(e.target.value) } as Beast)}
            />
            <label>Health:</label>
            <input
              type="number"
              value={beastData?.health || ''}
              onChange={(e) => setBeastData({ ...beastData, health: parseInt(e.target.value) } as Beast)}
            />
            <label>Strength:</label>
            <input
              type="number"
              value={beastData?.strength || ''}
              onChange={(e) => setBeastData({ ...beastData, strength: parseInt(e.target.value) } as Beast)}
            />
            <label>Endurance:</label>
            <input
              type="number"
              value={beastData?.endurance || ''}
              onChange={(e) => setBeastData({ ...beastData, endurance: parseInt(e.target.value) } as Beast)}
            />
            <label>Initiative:</label>
            <input
              type="number"
              value={beastData?.initiative || ''}
              onChange={(e) => setBeastData({ ...beastData, initiative: parseInt(e.target.value) } as Beast)}
            />
            <label>Dodge:</label>
            <input
              type="number"
              value={beastData?.dodge || ''}
              onChange={(e) => setBeastData({ ...beastData, dodge: parseInt(e.target.value) } as Beast)}
            />
            <label>Weapon Skill:</label>
            <input
              type="number"
              value={beastData?.weaponSkill || ''}
              onChange={(e) => setBeastData({ ...beastData, weaponSkill: parseInt(e.target.value) } as Beast)}
            />
            <label>Learning:</label>
            <input
              type="number"
              value={beastData?.learning || ''}
              onChange={(e) => setBeastData({ ...beastData, learning: parseInt(e.target.value) } as Beast)}
            />
            <label>Luck:</label>
            <input
              type="number"
              value={beastData?.luck || ''}
              onChange={(e) => setBeastData({ ...beastData, luck: parseInt(e.target.value) } as Beast)}
            />
            <label>Discipline:</label>
            <input
              type="number"
              value={beastData?.discipline || ''}
              onChange={(e) => setBeastData({ ...beastData, discipline: parseInt(e.target.value) } as Beast)}
            />
            <label>Leadership:</label>
            <input
              type="number"
              value={beastData?.leadership || ''}
              onChange={(e) => setBeastData({ ...beastData, leadership: parseInt(e.target.value) } as Beast)}
            />
            <label>Provocation:</label>
            <input
              type="number"
              value={beastData?.provocation || ''}
              onChange={(e) => setBeastData({ ...beastData, provocation: parseInt(e.target.value) } as Beast)}
            />
            <label>Silver:</label>
            <input
              type="number"
              value={beastData?.silver || ''}
              onChange={(e) => setBeastData({ ...beastData, silver: parseInt(e.target.value) } as Beast)}
            />
            <label>Experience:</label>
            <input
              type="number"
              value={beastData?.experience || ''}
              onChange={(e) => setBeastData({ ...beastData, experience: parseInt(e.target.value) } as Beast)}
            />
            <label>Is Boss:</label>
            <input
              type="checkbox"
              checked={beastData?.isBoss || false}
              onChange={(e) => setBeastData({ ...beastData, isBoss: e.target.checked } as Beast)}
            />
            <label>Is Active:</label>
            <input
              type="checkbox"
              checked={beastData?.isActive || false}
              onChange={(e) => setBeastData({ ...beastData, isActive: e.target.checked } as Beast)}
            />
          </div>
        )}

        {selectedTab === 'weapons' && (
          <div className={styles.formSection}>
            <h3>Manage Weapons</h3>
            <div className={styles.itemContainer}>
              {weapons.map((weapon, index) => (
                <div key={index} className={styles.item}>
                  <label>Name:</label>
                  <input
                    type="text"
                    value={weapon.name}
                    onChange={(e) => {
                      const updatedWeapons = [...weapons];
                      updatedWeapons[index].name = e.target.value;
                      setWeapons(updatedWeapons);
                    }}
                  />
                  <label>Weight:</label>
                  <input
                    type="number"
                    value={weapon.weight}
                    onChange={(e) => {
                      const updatedWeapons = [...weapons];
                      updatedWeapons[index].weight = parseInt(e.target.value);
                      setWeapons(updatedWeapons);
                    }}
                  />
                  <label>Slot:</label>
                  <select
                    value={weapon.slot}
                    onChange={(e) => {
                      const updatedWeapons = [...weapons];
                      const selectedSlot = e.target.value;

                      if (selectedSlot === 'offHand' && weapon.itemCategory !== 'Shield') {
                        updatedWeapons[index].slot = 'offHand';
                      } else if (selectedSlot !== 'offHand') {
                        updatedWeapons[index].slot = selectedSlot;
                      }

                      setWeapons(updatedWeapons);
                    }}
                  >
                    {weaponSlotOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                    <label>Item Category:</label>
                    <select
                      value={weapon.itemCategory}
                      onChange={(e) => {
                        const updatedWeapons = [...weapons];
                        updatedWeapons[index].itemCategory = e.target.value;

                        if (e.target.value === 'Shield' && updatedWeapons[index].slot !== 'offHand') {
                          updatedWeapons[index].slot = 'offHand';
                        }
                        setWeapons(updatedWeapons);
                      }}
                    >
                      <option value="Weapon">Weapon</option>
                      <option value="Shield">Shield</option>
                    </select>

                  {weapon.slot === "offHand" && weapon.itemCategory === "Shield" ? (
                    <>
                      <label>Block Value:</label>
                      <input
                        type="number"
                        value={weapon.blockValue || 0}
                        onChange={(e) => {
                          const updatedWeapons = [...weapons];
                          updatedWeapons[index].blockValue = parseInt(e.target.value);
                          setWeapons(updatedWeapons);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <label>Min Damage:</label>
                      <input
                        type="number"
                        value={weapon.minDamage}
                        onChange={(e) => {
                          const updatedWeapons = [...weapons];
                          updatedWeapons[index].minDamage = parseInt(e.target.value);
                          setWeapons(updatedWeapons);
                        }}
                      />
                      <label>Max Damage:</label>
                      <input
                        type="number"
                        value={weapon.maxDamage}
                        onChange={(e) => {
                          const updatedWeapons = [...weapons];
                          updatedWeapons[index].maxDamage = parseInt(e.target.value);
                          setWeapons(updatedWeapons);
                        }}
                      />
                      <label>Damage Cap:</label>
                      <input
                        type="number"
                        value={weapon.dmgCap}
                        onChange={(e) => {
                          const updatedWeapons = [...weapons];
                          updatedWeapons[index].dmgCap = parseInt(e.target.value);
                          setWeapons(updatedWeapons);
                        }}
                      />
                      <label>Crit Chance:</label>
                      <input
                        type="number"
                        value={weapon.critChance}
                        onChange={(e) => {
                          const updatedWeapons = [...weapons];
                          updatedWeapons[index].critChance = parseInt(e.target.value);
                          setWeapons(updatedWeapons);
                        }}
                      />
                      <label>Crit Damage:</label>
                      <input
                        type="number"
                        value={weapon.critDamage}
                        onChange={(e) => {
                          const updatedWeapons = [...weapons];
                          updatedWeapons[index].critDamage = parseInt(e.target.value);
                          setWeapons(updatedWeapons);
                        }}
                      />
                    </>
                  )}

                  <label>Can equip in offHand?:</label>
                  <input
                    type="checkbox"
                    checked={weapon?.canEquipInOffHand || false}
                    onChange={(e) =>
                      setWeapons(
                        weapons.map((w, i) =>
                          i === index ? { ...w, canEquipInOffHand: e.target.checked } : w
                        )
                      )
                    }
                  />
                  <label>Weapon Type:</label>
                  <input
                    type="text"
                    value={weapon.weaponType}
                    onChange={(e) => {
                      const updatedWeapons = [...weapons];
                      updatedWeapons[index].weaponType = e.target.value;
                      setWeapons(updatedWeapons);
                    }}
                  />
                  <label>Actions Per Round:</label>
                  <input
                    type="number"
                    value={weapon.actionsPerRound}
                    onChange={(e) => {
                      const updatedWeapons = [...weapons];
                      updatedWeapons[index].actionsPerRound = parseInt(e.target.value);
                      setWeapons(updatedWeapons);
                    }}
                  />
                  <label>Break Value:</label>
                  <input
                    type="number"
                    value={weapon.breakValue}
                    onChange={(e) => {
                      const updatedWeapons = [...weapons];
                      updatedWeapons[index].breakValue = parseInt(e.target.value);
                      setWeapons(updatedWeapons);
                    }}
                  />
                  <label>Required Level:</label>
                  <input
                    type="number"
                    value={weapon.requiredLevel}
                    onChange={(e) => {
                      const updatedWeapons = [...weapons];
                      updatedWeapons[index].requiredLevel = parseInt(e.target.value);
                      setWeapons(updatedWeapons);
                    }}
                  />
                  <label>Skill Recommendation:</label>
                  <input
                    type="number"
                    value={weapon.skillRecommendation}
                    onChange={(e) => {
                      const updatedWeapons = [...weapons];
                      updatedWeapons[index].skillRecommendation = parseInt(e.target.value);
                      setWeapons(updatedWeapons);
                    }}
                  />

                  <button className={styles.removeBtn} onClick={() => setWeapons(weapons.filter((_, i) => i !== index))}>Remove</button>
                </div>
              ))}
            </div>
            <button className={styles.addBtn} onClick={handleAddWeapon}>Add Weapon</button>
          </div>
        )}

        {selectedTab === 'armor' && (
          <div className={styles.formSection}>
            <h3>Manage Armor</h3>
            <div className={styles.itemContainer}>
              {armor.map((armorItem, index) => (
                <div key={index} className={styles.item}>
                  <label>Name:</label>
                  <input
                    type="text"
                    value={armorItem.name}
                    onChange={(e) => {
                      const updatedArmor = [...armor];
                      updatedArmor[index].name = e.target.value;
                      setArmor(updatedArmor);
                    }}
                  />
                  <label>Weight:</label>
                  <input
                    type="number"
                    value={armorItem.weight}
                    onChange={(e) => {
                      const updatedArmor = [...armor];
                      updatedArmor[index].weight = parseInt(e.target.value);
                      setArmor(updatedArmor);
                    }}
                  />
                  <label>Absorbtion:</label>
                  <input
                    type="number"
                    value={armorItem.absorption}
                    onChange={(e) => {
                      const updatedArmor = [...armor];
                      updatedArmor[index].absorption = parseInt(e.target.value);
                      setArmor(updatedArmor);
                    }}
                  />
                  <label>Slot:</label>
                  <select
                    value={armorItem.slot}
                    onChange={(e) => {
                      const updatedArmor = [...armor];
                      const selectedSlot = e.target.value;

                      updatedArmor[index].slot = selectedSlot;
                      updatedArmor[index].armorType = capitalizeFirstLetter(selectedSlot);
                      setArmor(updatedArmor);
                    }}
                  >
                    {armorSlotOptions.map((option) => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </select>
                  <label>Armor Type:</label>
                  <input
                    type="text"
                    value={capitalizeFirstLetter(armorItem.armorType || '')}
                    disabled
                  />
                  <button className={styles.removeBtn} onClick={() => setArmor(armor.filter((_, i) => i !== index))}>Remove</button>
                </div>
              ))}
            </div>
            <button className={styles.addBtn} onClick={() => setArmor([...armor, newArmor])}>Add Armor</button>
          </div>
        )}
      </div>

      <div className={styles.tabContent}>
        {selectedTab === 'loot' && (
          <div className={styles.formSection}>
            <h3>Manage Loot</h3>
            <input
              type="text"
              placeholder="Search for items..."
              value={itemSearch}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <div className={styles.searchResults}>
              {searchResults.map((item, index) => (
                <div key={index} className={styles.searchItem} onClick={() => addLootItem(item)}>
                  {item}
                </div>
              ))}
            </div>
            <div className={styles.lootList}>
              <h4>Selected Loot</h4>
              {loot.map((item, index) => (
                <div key={index} className={styles.lootItem}>
                  {item}
                  <button className={`${styles.removeBtn} ${styles.xBtn}`} onClick={() => removeLootItem(item)}>X</button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <button className={styles.saveBtn} onClick={handleSave}>Save {isCreating ? 'Beast' : 'Changes'}</button>
    </div>
  );
};

export default BeastEditor;
