import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Tooltip from '../ItemTooltip';
import Toast from '../Toast';
import styles from './CraftingDistrict.module.css';
import CraftingProgress from './CraftingProgress';
import { useMercenary } from '../../../contexts/MercenaryContext';

interface Profession {
  id: number;
  name: string;
  maxLevel: number;
  currentLevel: number;
  blueprints?: { [level: number]: Blueprint[] };
}

interface Blueprint {
  id: number;
  name: string;
  description: string;
  itemId: number;
  img?: string;
  components: string | { itemId: number; name: string; quantity: number }[];
  requiredLevel: number;
  roundsToCraft: number;
}

interface Mercenary {
  id: number;
  professionSkillPoints: number;
}

const CraftingDistrict: React.FC = () => {
  const [professions, setProfessions] = useState<Profession[]>([]);
  const [selectedProfession, setSelectedProfession] = useState<Profession | null>(null);
  const [selectedBlueprint, setSelectedBlueprint] = useState<Blueprint | null>(null);
  const [mercenary, setMercenary] = useState<Mercenary | null>(null);
  const { mercenaryId } = useMercenary();
  const [blueprintQuantities, setBlueprintQuantities] = useState<{ [blueprintId: number]: number }>({});
  const [view, setView] = useState<'crafting' | 'progress'>('crafting');
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  useEffect(() => {
    fetchMercenaryAndProfessions();
  }, []);

  const fetchMercenaryAndProfessions = async () => {
    try {
      const { data: mercenaryData } = await axios.get(`/api/mercenary/${mercenaryId}`, { withCredentials: true });
      setMercenary(mercenaryData);

      const { data: professionData } = await axios.get(`/api/craft/professions/${mercenaryData.id}`, { withCredentials: true });

      const transformedProfessions = professionData.map((profession: Profession) => ({
        ...profession,
        blueprints: Array.isArray(profession.blueprints) ? profession.blueprints.reduce((acc: { [key: number]: Blueprint[] }, blueprint: Blueprint) => {
          if (!acc[blueprint.requiredLevel]) {
            acc[blueprint.requiredLevel] = [];
          }
          acc[blueprint.requiredLevel].push(blueprint);
          return acc;
        }, {} as { [key: number]: Blueprint[] }) : {},
      }));

      setProfessions(sortProfessions(transformedProfessions));
    } catch (error) {
      displayToast('Error fetching data', 'error');
    }
  };

  const sortProfessions = (professions: Profession[]): Profession[] => {
    const order = ['Weapon Smithing', 'Armor Smithing', 'Jeweler', 'Alchemy', 'Rune Carving'];
    return professions.sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));
  };

  const handleProfessionSelect = (profession: Profession) => {
    setSelectedProfession(profession);
    setSelectedBlueprint(null);
  };

  const renderComponents = (
    components: string | { itemId: number; name: string; quantity: number; img?: string }[],
    quantity: number
  ): JSX.Element => {
    const parsedComponents: { itemId: number; name: string; quantity: number; img?: string }[] =
      typeof components === 'string' ? JSON.parse(components) : components;
  
    return (
      <ul className={styles.componentList}>
        {parsedComponents.map((component) => (
          <li key={component.itemId} className={styles.componentItem}>
            <Tooltip itemId={component.itemId}>
              <div>
                {component.quantity * quantity}x {'  '}
                <img
                  src={component.img || `/img/items/${component.name || 'default_icon'}.png`}
                  alt={`${component.name} icon`}
                  className={styles.componentIcon}
                />{' '}
                {component.name}
              </div>
            </Tooltip>
          </li>
        ))}
      </ul>
    );
  };

  const handleQuantityChange = (blueprintId: number, newQuantity: number) => {
    setBlueprintQuantities((prevQuantities) => ({
      ...prevQuantities,
      [blueprintId]: Math.max(1, newQuantity),
    }));
  };

  const handleStartCraft = async (blueprint: Blueprint, quantity: number) => {
    try {
      await axios.post('/api/craft/start-craft', { mercenaryId: mercenary?.id, blueprintId: blueprint.id, quantity }, { withCredentials: true });
      displayToast('Crafting project started successfully!', 'success');
      setView('progress');
    } catch {
      displayToast('Failed to start crafting project.', 'error');
    }
  };

  const handleLevelUp = async () => {
    if (!selectedProfession || !mercenary || mercenary.professionSkillPoints === 0) return;

    try {
      await axios.post('/api/craft/professions/level-up', { mercenaryId: mercenary.id, professionId: selectedProfession.id }, { withCredentials: true });
      displayToast('Profession leveled up successfully!', 'success');
      fetchMercenaryAndProfessions();
    } catch {
      displayToast('Failed to level up profession.', 'error');
    }
  };

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  return (
    <div className={styles.craftingDistrictContainer}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}
      <button onClick={() => setView(view === 'crafting' ? 'progress' : 'crafting')} className={styles.backButton}>
        {view === 'crafting' ? 'View Ongoing Projects' : 'Back to Crafting'}
      </button>
      {view === 'crafting' ? (
        <div>
          <h1 className={styles.craftingTitle}>Crafting District</h1>
          <p className={styles.craftingDistrictDescription}>
            Welcome to Njordheim’s Crafting District, where tools meet talent and skilled hands bring raw materials to life.<br />
            Whether you're shaping metal, brewing potions, or carving runes, each craft is a step towards mastery.
            <br /><br />
            Choose your path, Mercenary, and let your hands shape your fate.<br />
            Available profession points: {mercenary?.professionSkillPoints}
          </p>
  
          {selectedProfession ? (
            <ProfessionDetail
              profession={selectedProfession}
              selectedBlueprint={selectedBlueprint}
              blueprintQuantities={blueprintQuantities}
              onQuantityChange={handleQuantityChange}
              onBlueprintSelect={setSelectedBlueprint}
              onBack={() => setSelectedProfession(null)}
              onLevelUp={handleLevelUp}
              onStartCraft={handleStartCraft}
              renderComponents={renderComponents}
            />
          ) : (
            <ProfessionList professions={professions} onSelect={handleProfessionSelect} />
          )}
        </div>
      ) : (
        <CraftingProgress mercenary={mercenary!} />
      )}
    </div>
  );
};

const ProfessionDetail: React.FC<{
  profession: Profession;
  selectedBlueprint: Blueprint | null;
  blueprintQuantities: { [blueprintId: number]: number };
  onQuantityChange: (blueprintId: number, quantity: number) => void;
  onBlueprintSelect: (blueprint: Blueprint) => void;
  onBack: () => void;
  onLevelUp: () => void;
  onStartCraft: (blueprint: Blueprint, quantity: number) => void;
  renderComponents: (components: string | { itemId: number; name: string; quantity: number; img?: string }[], quantity: number) => JSX.Element;
}> = ({
  profession,
  selectedBlueprint,
  blueprintQuantities,
  onQuantityChange,
  onBlueprintSelect,
  onBack,
  onLevelUp,
  onStartCraft,
  renderComponents,
}) => (
  <div className={styles.selectedProfession}>
    <h2 className={styles.professionName}>{profession.name}</h2>
    <p className={styles.professionLevel}>Current Level: {profession.currentLevel} / {profession.maxLevel}</p>
    <button onClick={onBack} className={styles.backButton}>Back to Professions</button>
    <button onClick={onLevelUp} className={styles.levelUpButton}>Level Up Profession</button>

    {profession.blueprints && Object.entries(profession.blueprints).map(([level, levelBlueprints]) => {
      const sortedBlueprints = [...levelBlueprints].sort((a, b) => {
        const nameComparison = a.name.localeCompare(b.name);
        if (nameComparison !== 0) return nameComparison;
        return a.roundsToCraft - b.roundsToCraft;
      });

      return (
        <div key={level} className={styles.blueprintLevelGroup}>
          <h3 className={styles.blueprintLevelHeader}>Level {level} Blueprints</h3>
          <table className={styles.blueprintTable}>
            <thead>
              <tr><th>Name</th><th>Components</th><th>Rounds</th><th>Quantity</th><th>Action</th></tr>
            </thead>
            <tbody>
              {sortedBlueprints.map((blueprint) => {
                const quantity = blueprintQuantities[blueprint.id] || 1;
                const totalRounds = blueprint.roundsToCraft * quantity;
                return (
                  <tr key={blueprint.id} className={styles.blueprintRow}>
                    <td>
                      <Tooltip itemId={blueprint.itemId}>
                        <div className={styles.itemCell}>
                          <img src={blueprint.img || `/img/icons/${profession.name.toLowerCase().replace(/\s+/g, '')}.png`} alt={`${profession.name} icon`} className={styles.itemIcon} />
                          <span>{blueprint.name}</span>
                        </div>
                      </Tooltip>
                    </td>
                    <td>{renderComponents(blueprint.components as { itemId: number; name: string; quantity: number }[], quantity)}</td>
                    <td>{totalRounds}</td>
                    <td>
                      <input
                        type="number"
                        value={quantity}
                        onChange={(e) => onQuantityChange(blueprint.id, parseInt(e.target.value, 10))}
                        min="1"
                        className={styles.quantityInput}
                      />
                    </td>
                    <td>
                      <button onClick={() => onStartCraft(blueprint, quantity)} className={styles.craftButton}>
                        Start Craft
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    })}
  </div>
);

const ProfessionList: React.FC<{ professions: Profession[]; onSelect: (p: Profession) => void }> = ({ professions, onSelect }) => (
  <div className={styles.professionList}>
    {professions.map((profession) => (
      <div key={profession.id} onClick={() => onSelect(profession)} className={styles.professionCard}>
        <h3 className={styles.professionName}>{profession.name}</h3>
        <p className={styles.professionLevel}>Level: {profession.currentLevel}/{profession.maxLevel}</p>
      </div>
    ))}
  </div>
);

export default CraftingDistrict;
