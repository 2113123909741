// src/components/Pages/ModeratorPage.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface ReportedContent {
  id: number;
  type: string;
  content: string;
  reportedBy: string;
}

const ModeratorPage: React.FC = () => {
  const [reportedContent, setReportedContent] = useState<ReportedContent[]>([]);

  useEffect(() => {
    const fetchReportedContent = async () => {
      try {
        const response = await axios.get<ReportedContent[]>('https://njordheim.com/api/moderator/reports', { withCredentials: true });
        setReportedContent(response.data);
      } catch (error) {
        console.error('Error fetching reported content:', error);
      }
    };

    fetchReportedContent();
  }, []);

  return (
    <div className="moderator-container">
      <h1>Moderator Page</h1>
      <div className="reports-list">
        {reportedContent.map((report) => (
          <div key={report.id} className="report-item">
            <h3>Reported Content</h3>
            <p>Type: {report.type}</p>
            <p>Content: {report.content}</p>
            <p>Reported By: {report.reportedBy}</p>
            {/* Add buttons for actions such as "Approve", "Delete", etc. */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModeratorPage;
