import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Toast from '../Toast';
import { useMercenary } from '../../../contexts/MercenaryContext';
import styles from './Beasts.module.css';

interface Mercenary {
  id: number;
  name: string;
  level: number;
}

interface Beast {
  id: number;
  name: string;
  level: number;
  loot: string[];
  silver: number;
  experience: number;
  isBoss: boolean;
  minRequiredLevel: number;
  maxRequiredLevel: number;
}

const Beasts: React.FC = () => {
  const [beasts, setBeasts] = useState<Beast[]>([]);
  const [mercenary, setMercenary] = useState<Mercenary | null>(null);
  const [filterByLevel, setFilterByLevel] = useState<boolean>(() => {
    const savedFilter = localStorage.getItem('filterByLevel');
    return savedFilter ? JSON.parse(savedFilter) : false;
  });
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const navigate = useNavigate();
  const { mercenaryId } = useMercenary();

  useEffect(() => {
    const fetchMercenary = async () => {
      if (mercenaryId) {
        try {
          const response = await axios.get(`/api/mercenary/${mercenaryId}`, { withCredentials: true });
          setMercenary(response.data);
        } catch (error) {
          displayToast('Error fetching mercenary details', 'error');
        }
      }
    };

    fetchMercenary();
  }, [mercenaryId]);

  useEffect(() => {
    const fetchBeasts = async () => {
      try {
        const response = await axios.get('/api/beast/', { withCredentials: true });
        setBeasts(response.data);
      } catch (error) {
        displayToast('Error fetching beasts', 'error');
      }
    };

    fetchBeasts();
  }, []);

  useEffect(() => {
    localStorage.setItem('filterByLevel', JSON.stringify(filterByLevel));
  }, [filterByLevel]);

  const handlePrepareBattle = (beast: Beast) => {
    if (mercenary) {
      navigate('/beast-battle', { state: { beast, mercenaryId: mercenary.id } });
    } else {
      displayToast('No mercenary selected for the battle.', 'error');
    }
  };

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  const filteredBeasts = filterByLevel
    ? beasts.filter(
        (beast) =>
          mercenary !== null &&
          mercenary.level >= beast.minRequiredLevel &&
          mercenary.level <= beast.maxRequiredLevel &&
          !beast.isBoss
      )
    : beasts;

  return (
    <div className={styles.beastsContainer}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}

      <h2 className={styles.title}>Beasts</h2>
      <p className={styles.description}>Choose a beast for training battles in the arena of Njordheim.</p>

      <div className={styles.filterContainer}>
        <label className={styles.filterLabel}>
          <input
            type="checkbox"
            checked={filterByLevel}
            onChange={(e) => setFilterByLevel(e.target.checked)}
          />
          Show only beasts matching my level
        </label>
      </div>

      {filteredBeasts.length > 0 ? (
        <table className={styles.beastsTable}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Rewards</th>
              <th>Loot</th>
              <th>Level</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredBeasts.map((beast) => (
              <tr key={beast.id}>
                <td>{beast.name}</td>
                <td>{beast.silver} silver & {beast.experience} EXP</td>
                <td>{beast.loot.join(', ')}</td>
                <td>{beast.minRequiredLevel} - {beast.maxRequiredLevel}</td>
                <td>
                  <button onClick={() => handlePrepareBattle(beast)} className={styles.prepareButton}>
                    Prepare Battle
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className={styles.noBeastsMessage}>No beasts available for the selected filter.</p>
      )}
    </div>
  );
};

export default Beasts;
