import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import styles from './ManageUser.module.css';

interface Role {
  id: number;
  name: string;
}

interface User {
  id: number;
  username: string;
  email: string;
  roleId: number;
  isDead: boolean;
  deathBattleReport: number | null;
  lastActiveAt: string | null;
  createdAt: string;
  roles: Role[];
}

const ManageUser: React.FC = () => {
  const [userId, setUserId] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const [newDisplayName, setNewDisplayName] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<number | null>(null);
  const [roles, setRoles] = useState<Role[]>([]);
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const fetchRoles = async () => {
    try {
      const response = await axios.get('/api/admin/roles', { withCredentials: true });
      setRoles(response.data);
    } catch (error) {
      setError('Failed to fetch roles');
    }
  };

  const fetchUserData = async (id: number) => {
    try {
      const response = await axios.get(`/api/admin/user/${id}`, { withCredentials: true });
      setUser(response.data);
      setNewDisplayName(response.data.username);
      setSelectedRole(response.data.roleId);
    } catch (error) {
      setError('Failed to fetch user data');
    }
  };

  const debouncedSearch = useCallback(
    (() => {
      let timer: NodeJS.Timeout;

      return (term: string) => {
        clearTimeout(timer);
        timer = setTimeout(async () => {
          if (term) {
            try {
              const response = await axios.get(`/api/admin/user/search?term=${term}`, { withCredentials: true });
              setSearchResults(response.data);
              setError(null);
            } catch (error) {
              setError('Failed to search users');
            }
          }
        }, 500);
      };
    })(),
    []
  );

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handleFetchUser = (id: number) => {
    setUserId(id);
    fetchUserData(id);
    setSearchResults([]);
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleRemovePassword = async () => {
    try {
      await axios.post(`/api/admin/user/${userId}/remove-password`, {}, { withCredentials: true });
      setMessage('Password removed successfully.');
    } catch (error) {
      setError('Failed to remove password');
    }
  };

  const handleUpdateDisplayName = async () => {
    try {
      await axios.post(`/api/admin/user/${userId}/update-displayname`, { displayName: newDisplayName }, { withCredentials: true });
      setMessage('Display name updated successfully.');
    } catch (error) {
      setError('Failed to update display name');
    }
  };

  const handleUpdateRole = async () => {
    try {
      await axios.post(`/api/admin/user/${userId}/update-role`, { roleId: selectedRole }, { withCredentials: true });
      setMessage('User role updated successfully.');
    } catch (error) {
      setError('Failed to update user role');
    }
  };

  const handleClearDeathStatus = async () => {
    try {
      await axios.post(`/api/admin/user/${userId}/clear-death-status`, {}, { withCredentials: true });
      setMessage('Death status cleared successfully.');
      fetchUserData(userId!);
    } catch (error) {
      setError('Failed to clear death status');
    }
  };

  return (
    <div className={styles.manageUserContainer}>
      <h1>Manage User</h1>
      {message && <p className={styles.successMessage}>{message}</p>}
      {error && <p className={styles.errorMessage}>{error}</p>}

      <div className={styles.formGroup}>
        <label htmlFor="searchTerm">Search by Username or Email:</label>
        <input
          type="text"
          id="searchTerm"
          value={searchTerm}
          onChange={handleSearchTermChange}
          placeholder="Enter username or email"
        />
      </div>

      {searchResults.length > 0 && (
        <div className={styles.searchResults}>
          <h2>Select a User:</h2>
          <ul>
            {searchResults.map((user) => (
              <li key={user.id}>
                {user.username} ({user.email}) - ID: {user.id}
                <button onClick={() => handleFetchUser(user.id)} className={styles.selectUserButton}>
                  Select
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {user && (
        <div className={styles.userManagementSection}>
          <div className={styles.userInfo}>
            <h2>Username: {user.username}</h2>
            <h3>Current Role: {user.roles?.find((role) => role.id === user.roleId)?.name}</h3>
            <p>Status: {user.isDead ? 'Dead' : 'Alive'}</p>
            <p>Death Battle Report: {user.deathBattleReport || 'None'}</p>
            <p>Last Active: {user.lastActiveAt ? new Date(user.lastActiveAt).toLocaleString() : 'N/A'}</p>
            <p>Registered: {new Date(user.createdAt).toLocaleString()}</p>
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="newDisplayName">Update Display Name:</label>
            <input
              type="text"
              id="newDisplayName"
              value={newDisplayName}
              onChange={(e) => setNewDisplayName(e.target.value)}
            />
            <button className={styles.updateButton} onClick={handleUpdateDisplayName}>
              Update Display Name
            </button>
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="role">Update User Role:</label>
            <select
              id="role"
              value={selectedRole || ''}
              onChange={(e) => setSelectedRole(parseInt(e.target.value))}
            >
              <option value="">Select a Role</option>
              {roles.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </select>
            <button className={styles.updateButton} onClick={handleUpdateRole}>
              Update Role
            </button>
          </div>

          <div className={styles.formGroup}>
            <button className={styles.removeButton} onClick={handleRemovePassword}>
              Remove Password
            </button>
          </div>

          <div className={styles.formGroup}>
            <button className={styles.clearDeathButton} onClick={handleClearDeathStatus}>
              Clear Death Status
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageUser;
