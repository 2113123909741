import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useAuth } from '../../../contexts/AuthContext';
import { useMercenary } from '../../../contexts/MercenaryContext';
import DiceGame from './DiceGame';
import Toast from '../Toast';
import styles from './Tavern.module.css';

const Tavern: React.FC = () => {
  const [activeTab, setActiveTab] = useState('foodDrinks');
  const { user } = useAuth();
  const { mercenary } = useMercenary();
  
  interface PatchNote {
    id: number;
    title: string;
    date: string;
    content: string;
    version: string;
    author: string;
  }

  const [patchNotes, setPatchNotes] = useState<PatchNote[]>([]);

  interface FoodItem {
    id: number;
    name: string;
    description: string;
    healAmount: number;
    cost: number;
  }

  const [foodItems, setFoodItems] = useState<FoodItem[]>([]);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  useEffect(() => {
    const fetchFoodItems = async () => {
      try {
        const response = await axios.get('/api/tavern/food');
        setFoodItems(response.data);
      } catch (error) {
        console.error('Failed to fetch food items', error);
        displayToast('Failed to load food items. Please try again later.', 'error');
      }
    };

    fetchFoodItems();
  }, []);

  const handleBuy = async (foodId: number) => {
    try {
      const response = await axios.post('/api/tavern/purchase-food', {
        mercenaryId: mercenary.id,
        foodId,
      });
      displayToast('Food purchased successfully!', 'success');
    } catch (error) {
      const errorMessage = axios.isAxiosError(error) ? error.response?.data?.message || 'Purchase failed' : 'Purchase failed';
      displayToast(errorMessage, 'error');
    }
  };

  useEffect(() => {
    if (activeTab === 'chronicles') {
      const fetchPatchNotes = async () => {
        try {
          const response = await axios.get('/api/tavern/patchnotes');
          setPatchNotes(response.data);
        } catch (error) {
          console.error('Failed to fetch patch notes', error);
          displayToast('Failed to load patch notes.', 'error');
        }
      };
      fetchPatchNotes();
    }
  }, [activeTab]);

  const renderContent = () => {
    switch (activeTab) {
      case 'foodDrinks':
        return (
          <div className={styles.menuTable}>
            <h3 className={styles.menuTitle}>Tavern Menu</h3>
            <table>
              <thead>
                <tr>
                  <th>Food Item</th>
                  <th>Heals For</th>
                  <th>Price</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {foodItems.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <strong>{item.name}</strong>
                      <br />
                      <em>{item.description}</em>
                    </td>
                    <td>{item.healAmount} HP</td>
                    <td>{item.cost}</td>
                    <td>
                      <button className={styles.buyButton} onClick={() => handleBuy(item.id)}>Buy</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      case 'gamble':
        return <DiceGame mercenaryId={mercenary.id} initialSilver={mercenary.silver} />;
      case 'The Veiled Stranger':
        return (
          <div className={styles.mysteriousContent}>
            <h3>The Veiled Stranger</h3>
            <p>
              As you settle in the dim corner of the tavern, a shadowed figure catches your eye. Cloaked in a dark, worn
              hood, the figure sits alone, occasionally glancing around the room as if waiting for the right moment.
            </p>
            <p>
              "Njordheim is growing," a low voice murmurs, barely audible above the tavern's hum. "Change is in the air,
              and those brave enough will soon find paths... unexpected ones. Secrets hidden in the mountain, mysteries
              in the forest across the river. Perhaps, when the time is right, I’ll have a tale or two for you."
            </p>
            <p>
              The figure pauses, eyes glinting from the shadows. "But patience, friend. The winds of change are not so
              easily hurried. In time, those who seek adventure will find it. Until then, stay sharp, and listen well to
              the whispers in the dark corners of Njordheim."
            </p>
            <p className={styles.teasingNote}>Stay tuned... The Veiled Stranger might have more to reveal in the future.</p>
          </div>
        );
      case 'chronicles':
        return (
          <div className={styles.chroniclesContent}>
            <h3 className={styles.patchHeader}>Chronicles of Njordheim</h3>
            {patchNotes.map((note) => (
              <div key={note.id} className={styles.patchNote}>
                <h4>{note.title}</h4>
                <ReactMarkdown className={styles.patchContent}>{note.content}</ReactMarkdown>
                <p className={styles.patchVersion}>Version {note.version} - {new Date(note.date).toLocaleDateString()}</p>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.tavernContainer}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}
      <div className={styles.tavernHeader}>
        <div className={styles.rpText}>
          <h2>Welcome, traveler!</h2>
          <p>
            In the heart of Njordheim, the tavern stands as a haven for weary adventurers. Whether you're here to relax,
            feast, or seek fortune, there's a seat waiting just for you. Enjoy the warmth of the hearth and the tales
            shared among comrades.
          </p>
        </div>
        <div className={styles.tavernImage}>
          <img src='/img/tavern.png' alt="Tavern" />
        </div>
      </div>
      <div className={styles.tavernTabs}>
        <button className={activeTab === 'foodDrinks' ? styles.active : ''} onClick={() => setActiveTab('foodDrinks')}>Food & Drinks</button>
        <button className={activeTab === 'gamble' ? styles.active : ''} onClick={() => setActiveTab('gamble')}>Gamble</button>
        <button className={activeTab === 'The Veiled Stranger' ? styles.active : ''} onClick={() => setActiveTab('The Veiled Stranger')}>The Veiled Stranger</button>
        <button className={activeTab === 'chronicles' ? styles.active : ''} onClick={() => setActiveTab('chronicles')}>Chronicles</button>
      </div>
        {renderContent()}
    </div>
  );
};

export default Tavern;
