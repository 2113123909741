import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Toast from '../Toast';
import styles from './ViewTournaments.module.css';

interface Tournament {
  id: number;
  name: string;
  description: string;
  type: string;
  status: string;
  min_level: number;
  max_level: number;
  num_teams: number;
  race_requirement: string;
  team_size: number;
  participantCount: number;
  availableSpots: number;
  start_time: string;
}

const ViewTournaments: React.FC = () => {
  const [tournaments, setTournaments] = useState<Tournament[]>([]);
  const [activeTab, setActiveTab] = useState('upcoming');
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const response = await axios.get('/api/tournament', { withCredentials: true });
        setTournaments(response.data);
      } catch (error) {
        console.error('Error fetching tournaments:', error);
        displayToast('Failed to load tournaments. Please try again later.', 'error');
      }
    };

    fetchTournaments();
  }, []);

  const upcomingAndOngoing = tournaments.filter((tournament) =>
    ['scheduled', 'locked', 'ongoing'].includes(tournament.status)
  );

  const finishedTournaments = tournaments
    .filter((tournament) => tournament.status === 'completed')
    .sort((a, b) => new Date(b.start_time).getTime() - new Date(a.start_time).getTime());

  return (
    <div className={styles.tournamentListContainer}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}
      <h2 className={styles.tournamentHeader}>Tournaments</h2>
      <div className={styles.tabs}>
        <button
          className={`${styles.tab} ${activeTab === 'upcoming' ? styles.active : ''}`}
          onClick={() => setActiveTab('upcoming')}
        >
          Upcoming & Ongoing
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'finished' ? styles.active : ''}`}
          onClick={() => setActiveTab('finished')}
        >
          Finished
        </button>
      </div>
      <ul className={styles.tournamentList}>
        {activeTab === 'upcoming' &&
          upcomingAndOngoing.map((tournament) => (
            <li key={tournament.id} className={styles.tournamentItem}>
              <h3 className={styles.tournamentTitle}>{tournament.name}</h3>
              <p className={styles.tournamentDescription}>{tournament.description}</p>
              <p className={styles.tournamentStatus}>Status: {tournament.status}</p>
              <p className={styles.tournamentType}>Type: {tournament.type === 'death' ? 'Death Match' : 'Normal Battle'}</p>
              <p className={styles.tournamentTeamSize}>Team Size: {tournament.team_size}</p>
              <p className={styles.tournamentContestants}>
                Contestants: {tournament.participantCount} / {tournament.num_teams * tournament.team_size}
              </p>
              <p className={styles.tournamentAvailableSpots}>Available Spots: {tournament.availableSpots}</p>
              <p className={styles.tournamentStartTime}>Starts at: {new Date(tournament.start_time).toLocaleString()}</p>
              <p className={styles.tournamentLevels}>Levels: {tournament.min_level} - {tournament.max_level}</p>
              <p className={styles.tournamentRace}>Allowed Race: {tournament.race_requirement}</p>
              <Link to={`/tournament/${tournament.id}`} className={styles.tournamentLink}>
                View Details
              </Link>
            </li>
          ))}
        {activeTab === 'finished' &&
          finishedTournaments.map((tournament) => (
            <li key={tournament.id} className={styles.tournamentItem}>
              <h3 className={styles.tournamentTitle}>{tournament.name}</h3>
              <p className={styles.tournamentDescription}>{tournament.description}</p>
              <p className={styles.tournamentType}>Type: {tournament.type === 'death' ? 'Death Match' : 'Normal Battle'}</p>
              <p className={styles.tournamentStatus}>Status: Completed</p>
              <Link to={`/tournament/${tournament.id}`} className={styles.tournamentLink}>
                View Details
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default ViewTournaments;
