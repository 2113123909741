// components/AdventureCompletion.tsx
import React from 'react';
import { useLocation } from 'react-router-dom';
import './AdventureCompletion.css';

interface Reward {
  reward_value: {
    name: string;
    rarity: string;
  };
}

const AdventureCompletion: React.FC = () => {
  const location = useLocation();
  const state = location.state as { message: string; rewards: Reward[] };
  const { message, rewards } = state || { message: '', rewards: [] };

  return (
    <div className="adventure-completion">
      <h2>Adventure Complete!</h2>
      <p>{message}</p>
      <h3>Rewards:</h3>
      <ul>
        {rewards.map((reward, index) => (
          <li key={index}>
            {reward.reward_value.name} - {reward.reward_value.rarity}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdventureCompletion;
