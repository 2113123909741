import React, { useEffect, useState } from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import axios from 'axios';
import {
  Chart,
  ArcElement,
  PointElement,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';

// Register elements
Chart.register(ArcElement, PointElement, LineElement, BarElement, CategoryScale, LinearScale);

interface EconomyStats {
  totalSilverOwned: number;
  totalSilverSpentOnOrders: number;
  totalOrdersFulfilled: number;
  totalOrdersExpired: number;
  totalOrdersCanceled: number;
  topSellingItems: { item_id: number, quantity: string, Item: { name: string } }[];
  topBoughtItems: { item_id: number, quantity: string, Item: { name: string } }[];
}

const EconomyMetrics: React.FC = () => {
  const [economyStats, setEconomyStats] = useState<EconomyStats>({
    totalSilverOwned: 0,
    totalSilverSpentOnOrders: 0,
    totalOrdersFulfilled: 0,
    totalOrdersExpired: 0,
    totalOrdersCanceled: 0,
    topSellingItems: [],
    topBoughtItems: [],
  });

  useEffect(() => {
    const fetchEconomyStats = async () => {
      try {
        const response = await axios.get('/api/admin/economy-stats');
        setEconomyStats(response.data);
      } catch (error) {
        console.error('Error fetching economy statistics', error);
      }
    };

    fetchEconomyStats();
  }, []);

  // Pie chart data for silver distribution
  const silverData = {
    labels: ['Total Silver Owned', 'Total Silver Spent on Orders'],
    datasets: [
      {
        data: [economyStats.totalSilverOwned, economyStats.totalSilverSpentOnOrders],
        backgroundColor: ['#ffd700', '#c0c0c0'],
      },
    ],
  };

  // Bar chart data for order statistics
  const ordersData = {
    labels: ['Fulfilled Orders', 'Expired Orders', 'Canceled Orders'],
    datasets: [
      {
        label: 'Order Stats',
        data: [
          economyStats.totalOrdersFulfilled,
          economyStats.totalOrdersExpired,
          economyStats.totalOrdersCanceled,
        ],
        backgroundColor: ['#00bfff', '#ff6347', '#ffa500'],
      },
    ],
  };

  return (
    <div className="economy-metrics-container">
      <h3>In-Game Economy Overview</h3>
      <div className="metrics-grid">
        <div className="metric-card">
          <h4>Silver Distribution</h4>
          <Pie data={silverData} />
        </div>
        <div className="metric-card">
          <h4>Order Statistics</h4>
          <Bar data={ordersData} />
        </div>
      </div>

      <div className="metrics-lists">
        <div className="metric-card">
          <h4>Top SellOrder Items</h4>
          <ul>
            {economyStats.topSellingItems.map((item) => (
              <li key={item.item_id}>
                {item.Item.name}: {item.quantity} sold
              </li>
            ))}
          </ul>
        </div>

        <div className="metric-card">
          <h4>Top BuyOrder Items</h4>
          <ul>
            {economyStats.topBoughtItems.map((item) => (
              <li key={item.item_id}>
                {item.Item.name}: {item.quantity} bought
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EconomyMetrics;
