import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import UserMetrics from './metrics/UserMetrics';
import EconomyMetrics from './metrics/EconomyMetrics';
import BattleMetrics from './metrics/BattleMetrics';
import BeastMetrics from './metrics/BeastMetrics';
import RaceMetrics from './metrics/RaceMetrics';
import StrategyMetrics from './metrics/StrategyMetrics';
import AdminBroadcastMail from './AdminBroadcastMail';
import styles from './AdminPage.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faCircleExclamation, faUserPen, faUsers, faClipboardList, faPlus, faDiceD20, faFlask, faNewspaper, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const AdminPage: React.FC = () => {
  const [mdFiles, setMdFiles] = useState<string[]>([]);
  const [showBroadcastMail, setShowBroadcastMail] = useState(false);

  useEffect(() => {
    const fetchMdFiles = async () => {
      try {
        const response = await axios.get('/api/admin/md-files', { withCredentials: true });
        setMdFiles(response.data);
      } catch (error) {
        console.error('Failed to fetch Markdown files:', error);
      }
    };

    fetchMdFiles();
  }, []);

  const toggleBroadcastMail = () => {
    setShowBroadcastMail((prev) => !prev);
  };

  return (
    <div className={styles.adminContainer}>
      <aside className={styles.adminSidebar}>
        <h1>Admin Actions</h1>
        <ul className={styles.adminMenuList}>
          <li>
            <Link to="/post-patch-note">
              <FontAwesomeIcon icon={faNewspaper} /> Post Patch Note
            </Link>
          </li>
          <li>
            <Link to="/world-editor">
              <FontAwesomeIcon icon={faGlobe} /> World Editor
            </Link>
          </li>
          <li>
            <Link to="/assign-items">
              <FontAwesomeIcon icon={faClipboardList} /> Assign Items to Vendors
            </Link>
          </li>
          <li>
            <Link to="/create-tournament">
              <FontAwesomeIcon icon={faPlus} /> Create Tournament
            </Link>
          </li>
          <li>
            <Link to="/manage-user">
              <FontAwesomeIcon icon={faUserPen} /> Manage User
            </Link>
          </li>
          <li>
            <Link to="/audit">
              <FontAwesomeIcon icon={faCircleExclamation} /> Audit Logs
            </Link>
          </li>
          <li>
            <button onClick={toggleBroadcastMail} className={styles.adminMenuButton}>
              <FontAwesomeIcon icon={faEnvelope} /> Broadcast Mail
            </button>
          </li>
        </ul>

        <h1>Markdown Files</h1>
        <div className={styles.adminMdDropdownContainer}>
          <button className={styles.adminMdBtn}>Select Markdown File</button>
          <div className={styles.adminMdContent}>
            <ul className={styles.adminFileList}>
              {mdFiles.map((filename) => (
                <li key={filename}>
                  <Link to={`/docs/${filename}`} className={styles.adminLink}>
                    {filename}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className={styles.adminUsageSection}>
          <h3>Disk Space Used</h3>
          <div className={styles.adminProgressBar}>
            <div className={styles.adminProgress} style={{ width: '60%' }}></div>
          </div>
          <h3>Bandwidth Used</h3>
          <div className={styles.adminProgressBar}>
            <div className={styles.adminProgress} style={{ width: '40%' }}></div>
          </div>
        </div>
      </aside>

      <main className={styles.adminMainContent}>
        {showBroadcastMail ? (
          <div className={styles.metricCard}>
            <AdminBroadcastMail />
          </div>
        ) : (
          <div className={styles.metricsGrid}>
            <div className={styles.metricCard}>
              <UserMetrics />
            </div>
            <div className={styles.metricCard}>
              <BattleMetrics />
            </div>
            <div className={styles.metricCard}>
              <EconomyMetrics />
            </div>
            <div className={styles.metricCard}>
              <BeastMetrics />
            </div>
            <div className={styles.metricCard}>
              <RaceMetrics />
            </div>
            <div className={styles.metricCard}>
              <StrategyMetrics />
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default AdminPage;
