import React, { useEffect, useState } from 'react';
import styles from './Preferences.module.css';
import { savePreferencesToDatabase } from './savePreferencesToDatabase';

interface PreferencesProps {
  lobbyId: number;
  mercenaryId: number;
  preferences: {
    surrenderHP: number;
    readyHP: number;
    strategy: string;
  };
  onUpdate: (updatedPreferences: { surrenderHP: number; readyHP: number; strategy: string }) => void;
}

const Preferences: React.FC<PreferencesProps> = ({ lobbyId, mercenaryId, onUpdate }) => {
  const [preferences, setPreferences] = useState({
    surrenderHP: 0.5,
    readyHP: 0.5,
    strategy: 'Normal',
  });

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const LOCAL_STORAGE_KEY = `preferences_${mercenaryId}`;
  const EXPIRATION_DAYS = 60;
  const MS_IN_A_DAY = 24 * 60 * 60 * 1000;

  const loadFromLocalStorage = () => {
    const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedData) {
      const { preferences, timestamp } = JSON.parse(storedData);
      const isExpired = Date.now() - timestamp > EXPIRATION_DAYS * MS_IN_A_DAY;

      if (isExpired) {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
        return null;
      }

      return preferences;
    }
    return null;
  };

  const saveToLocalStorage = (prefs: typeof preferences) => {
    const data = {
      preferences: prefs,
      timestamp: Date.now(),
    };
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
  };

  useEffect(() => {
    const fetchPreferences = async () => {
      const localPreferences = loadFromLocalStorage();
      if (localPreferences) {
        setPreferences(localPreferences);
        setIsLoading(false);
      } else {
        try {
          setIsLoading(true);
          const response = await fetch(`/api/team-battle/lobbies/${lobbyId}/participant/${mercenaryId}`);
          if (!response.ok) {
            throw new Error('Failed to load preferences');
          }
          const data = await response.json();
          const fetchedPreferences = {
            surrenderHP: data.surrenderHP,
            readyHP: data.readyHP,
            strategy: data.strategy,
          };
          setPreferences(fetchedPreferences);
          saveToLocalStorage(fetchedPreferences);
          setError(null);
        } catch (err) {
          console.error(err);
          setError('Failed to load preferences.');
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchPreferences();
  }, [lobbyId, mercenaryId]);

  const handleUpdate = async (field: string, value: number | string) => {
    const updatedPreferences = { ...preferences, [field]: value };
    setPreferences(updatedPreferences);
    saveToLocalStorage(updatedPreferences);
    onUpdate(updatedPreferences);
  
    await savePreferencesToDatabase(lobbyId, mercenaryId, updatedPreferences);
  };

  const percentageOptions = Array.from({ length: 10 }, (_, i) => i * 10).concat(100);

  if (isLoading) {
    return <div>Loading preferences...</div>;
  }

  if (error) {
    return <div className={styles.error}>Error: {error}</div>;
  }

  return (
    <div className={styles.preferences}>
      <h3>Preferences</h3>
      <div className={styles.preferencesContainer}>
        <div className={styles.row}>
          <div className={styles.formGroup}>
            <label htmlFor="surrenderHP">Surrender HP (%):</label>
            <select
              id="surrenderHP"
              value={preferences.surrenderHP * 100}
              onChange={(e) =>
                handleUpdate('surrenderHP', parseFloat(e.target.value) / 100)
              }
            >
              {percentageOptions.map((value) => (
                <option key={value} value={value}>
                  {value}%
                </option>
              ))}
            </select>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="readyHP">Ready HP (%):</label>
            <select
              id="readyHP"
              value={preferences.readyHP * 100}
              onChange={(e) =>
                handleUpdate('readyHP', parseFloat(e.target.value) / 100)
              }
            >
              {percentageOptions.map((value) => (
                <option key={value} value={value}>
                  {value}%
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.formGroup} style={{ flex: 1 }}>
            <label htmlFor="strategy">Strategy:</label>
            <select
              id="strategy"
              value={preferences.strategy}
              onChange={(e) => handleUpdate('strategy', e.target.value)}
            >
              <option value="Normal">Normal</option>
              <option value="Normal: Light Attacks">Normal: Light Attacks</option>
              <option value="Normal: Heavy Attacks">Normal: Heavy Attacks</option>
              <option value="Offensive">Offensive</option>
              <option value="Offensive: Light Attacks">Offensive: Light Attacks</option>
              <option value="Offensive: Heavy Attacks">Offensive: Heavy Attacks</option>
              <option value="Defensive">Defensive</option>
              <option value="Defensive: Light Attacks">Defensive: Light Attacks</option>
              <option value="Defensive: Heavy Attacks">Defensive: Heavy Attacks</option>
              <option value="Berserk">Berserk</option>
              <option value="Berserk: Light Attacks">Berserk: Light Attacks</option>
              <option value="Berserk: Heavy Attacks">Berserk: Heavy Attacks</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preferences;
