import React from 'react';

const Achievements: React.FC = () => {
  return (
    <div>
      <h1>Achievements</h1>
      <p>This is the Achievements component.</p>
    </div>
  );
};

export default Achievements;
