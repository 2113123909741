import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useMercenary } from '../../../contexts/MercenaryContext';
import styles from './TeamBattles.module.css';

interface Lobby {
  id: number;
  name: string;
  minLevel: number;
  maxLevel: number;
  maxPlayers: number;
  randomTeams: boolean;
  currentParticipants: number;
  pve: boolean;
  matchType: string;
}

const TeamBattles: React.FC = () => {
  const [lobbies, setLobbies] = useState<Lobby[]>([]);
  const [filteredLobbies, setFilteredLobbies] = useState<Lobby[]>([]);
  const [userLobbyId, setUserLobbyId] = useState<number | null>(null);
  const [showWithinLevel, setShowWithinLevel] = useState<boolean>(false);
  const [hideFullLobbies, setHideFullLobbies] = useState<boolean>(false);
  const [hidePvE, setHidePvE] = useState<boolean>(false);
  const [hidePvP, setHidePvP] = useState<boolean>(false);
  const navigate = useNavigate();
  const { mercenary } = useMercenary();

  const fetchLobbies = async () => {
    try {
      const lobbiesResponse = await axios.get('/api/team-battle/lobbies');
      setLobbies(lobbiesResponse.data);

      const participationResponse = await axios.get('/api/team-battle/participation', { withCredentials: true });
      setUserLobbyId(participationResponse.data.inLobby ? participationResponse.data.lobbyId : null);
    } catch (error) {
      console.error('Error fetching lobbies:', error);
    }
  };

  useEffect(() => {
    fetchLobbies();
  }, []);

  useEffect(() => {
    filterLobbies();
  }, [lobbies, userLobbyId, showWithinLevel, hideFullLobbies, hidePvE, hidePvP]);

  const filterLobbies = () => {
    let filtered = lobbies;

    if (showWithinLevel && mercenary) {
      filtered = filtered.filter(
        (lobby) => mercenary.level >= lobby.minLevel && mercenary.level <= lobby.maxLevel
      );
    }

    if (hideFullLobbies) {
      filtered = filtered.filter((lobby) => lobby.currentParticipants < lobby.maxPlayers);
    }

    if (hidePvE) {
      filtered = filtered.filter((lobby) => !lobby.pve);
    }

    if (hidePvP) {
      filtered = filtered.filter((lobby) => lobby.pve);
    }

    const sortedLobbies = filtered.sort((a, b) => {
      if (a.id === userLobbyId) return -1;
      if (b.id === userLobbyId) return 1;
      return 0;
    });

    setFilteredLobbies(sortedLobbies);
  };

  const handleCreateLobby = async () => {
    navigate('/team-battle/create');
    await fetchLobbies();
  };

  const handleLeaveLobby = async () => {
    try {
      await axios.post('/api/team-battle/leave', {}, { withCredentials: true });
      setUserLobbyId(null);
      await fetchLobbies();
    } catch (error) {
      console.error('Error leaving the lobby:', error);
    }
  };

  const handleViewLobby = (lobbyId: number) => {
    navigate(`/team-battle/lobby/${lobbyId}`);
  };

  return (
    <div className={styles.teamBattlesContainer}>
      <h1 className={styles.title}>Team Battles</h1>
      {!userLobbyId ? (
        <button className={styles.createLobbyButton} onClick={handleCreateLobby}>
          Create Lobby
        </button>
      ) : (
        <button className={styles.leaveLobbyButton} onClick={handleLeaveLobby}>
          Leave Current Lobby
        </button>
      )}

      <div className={styles.filterOptions}>
        <button
          className={`${styles.filterButton} ${showWithinLevel ? styles.activeFilter : ''}`}
          onClick={() => setShowWithinLevel(!showWithinLevel)}
        >
          Show Lobbies Within Level
        </button>
        <button
          className={`${styles.filterButton} ${hideFullLobbies ? styles.activeFilter : ''}`}
          onClick={() => setHideFullLobbies(!hideFullLobbies)}
        >
          Hide Full Lobbies
        </button>
        <button
          className={`${styles.filterButton} ${hidePvE ? styles.activeFilter : ''}`}
          onClick={() => setHidePvE(!hidePvE)}
        >
          Hide PvE Battles
        </button>
        <button
          className={`${styles.filterButton} ${hidePvP ? styles.activeFilter : ''}`}
          onClick={() => setHidePvP(!hidePvP)}
        >
          Hide PvP Battles
        </button>
      </div>

      <div className={styles.lobbiesList}>
        {filteredLobbies.length === 0 ? (
          <p className={styles.noLobbies}>No lobbies available. Create one!</p>
        ) : (
          filteredLobbies.map((lobby) => (
            <div
              key={lobby.id}
              className={`${styles.lobbyCard} ${lobby.id === userLobbyId ? styles.highlight : ''}`}
            >
              <div onClick={() => handleViewLobby(lobby.id)}>
                <h2 className={styles.lobbyName}>{lobby.name}</h2>
                <p className={styles.lobbyLevel}>Levels: {lobby.minLevel} - {lobby.maxLevel}</p>
                <p className={styles.lobbyParticipants}>
                  Participants: {lobby.currentParticipants} / {lobby.maxPlayers}
                </p>
                <p className={styles.lobbyPve}>
                  PvE: {lobby.pve ? 'Yes' : 'No'}
                </p>
                <p className={`${styles.lobbyTeams} ${lobby.randomTeams ? styles.random : ''}`}>
                  Teams: {lobby.randomTeams ? 'Random' : 'Manual'}
                </p>
                <p className={`${styles.lobbyDeathmatch} ${lobby.matchType === 'death' ? styles.deathmatch : ''}`}>
                  Deathmatch: {lobby.matchType === 'death' ? 'Yes' : 'No'}
                </p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default TeamBattles;
