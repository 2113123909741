// components/AdminBroadcastMail.js
import React, { useState } from 'react';
import axios from 'axios';
import styles from './AdminBroadcastMail.module.css';

const AdminBroadcastMail = () => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const handleSendBroadcast = async () => {
    if (!subject.trim() || !message.trim()) {
      setStatus('Subject and message are required.');
      return;
    }

    try {
      const response = await axios.post('/api/admin/broadcast', {
        subject,
        message,
      }, { withCredentials: true });
      
      setStatus(response.data.message);
      setSubject('');
      setMessage('');
    } catch (error) {
      console.error('Error sending broadcast:', error);
      setStatus('Failed to send broadcast message.');
    }
  };

  return (
    <div className={styles.broadcastContainer}>
      <h2>Send Broadcast Mail</h2>
      <div className={styles.formGroup}>
        <label>Subject:</label>
        <input
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          placeholder="Enter the subject"
          className={styles.input}
        />
      </div>
      <div className={styles.formGroup}>
        <label>Message:</label>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter the message"
          className={styles.textarea}
        />
      </div>
      <button onClick={handleSendBroadcast} className={styles.sendButton}>
        Send Broadcast
      </button>
      {status && <p className={styles.statusMessage}>{status}</p>}
    </div>
  );
};

export default AdminBroadcastMail;
