import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Toast from '../Toast';
import styles from './CreateLobby.module.css';
import { useMercenary } from '../../../contexts/MercenaryContext';

const CreateLobby: React.FC = () => {
  const [name, setName] = useState<string>('');
  const [minLevel, setMinLevel] = useState<number>(1);
  const [maxLevel, setMaxLevel] = useState<number>(100);
  const [maxPlayers, setMaxPlayers] = useState<number>(4);
  const [randomTeams, setRandomTeams] = useState<boolean>(false);
  const [matchType, setMatchType] = useState<string>('normal');
  const [pve, setPve] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { mercenary } = useMercenary();
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  useEffect(() => {
    if (mercenary) {
      setMinLevel(Math.max(1, mercenary.level - 2));
      setMaxLevel(mercenary.level + 4);
    }
  }, [mercenary]);

  const handleCreateLobby = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const lobbyName = name.trim() === '' ? `${mercenary.name}'s Lobby` : name;

    try {
      // Load preferences from local storage or set defaults
      const localPreferences = localStorage.getItem(`preferences_${mercenary.id}`);
      const parsedPreferences = localPreferences ? JSON.parse(localPreferences) : {};
      const savedPreferences = {
        surrenderHP: parseFloat(parsedPreferences.preferences?.surrenderHP || '0.5'),
        readyHP: parseFloat(parsedPreferences.preferences?.readyHP || '0.9'),
        strategy: parsedPreferences.preferences?.strategy || 'Normal',
      };

      // Create the lobby
      const response = await axios.post(
        '/api/team-battle/create',
        {
          name: lobbyName,
          minLevel,
          maxLevel,
          maxPlayers,
          randomTeams,
          matchType,
          pve,
          mercId: mercenary.id,
        },
        { withCredentials: true }
      );

      const lobbyId = response.data.lobby.id;

      // Save preferences for the lobby creator
      await axios.put(
        `/api/team-battle/lobbies/${lobbyId}/participant/${mercenary.id}`,
        savedPreferences,
        { withCredentials: true }
      );

      navigate(`/team-battle/lobby/${lobbyId}`);
      setToastType('success');
      setToastMessage('Lobby created successfully!');
    } catch (err) {
      console.error('Error creating lobby:', err);
      setToastType('error');
      setToastMessage('Failed to create lobby. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const generateLevelOptions = (min: number, max: number) => {
    const options = [];
    for (let i = min; i <= max; i++) {
      options.push(<option key={i} value={i}>{i}</option>);
    }
    return options;
  };

  return (
    <div className={styles.createLobbyContainer}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}
      <h2 className={styles.title}>Create a New Lobby</h2>
      <form onSubmit={handleCreateLobby} className={styles.createLobbyForm}>
        <div className={styles.formGroup}>
          <label htmlFor="name">Lobby Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={`${mercenary.name}'s Lobby`}
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="minLevel">Min Level:</label>
          <select
            id="minLevel"
            value={minLevel}
            onChange={(e) => setMinLevel(parseInt(e.target.value))}
            required
          >
            {generateLevelOptions(Math.max(1, mercenary.level - 2), mercenary.level)}
          </select>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="maxLevel">Max Level:</label>
          <select
            id="maxLevel"
            value={maxLevel}
            onChange={(e) => setMaxLevel(parseInt(e.target.value))}
            required
          >
            {generateLevelOptions(mercenary.level, mercenary.level + 4)}
          </select>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="maxPlayers">Team Size:</label>
          <select
            id="maxPlayers"
            value={maxPlayers}
            onChange={(e) => setMaxPlayers(parseInt(e.target.value))}
            required
          >
            <option value={4}>2 vs. 2</option>
            <option value={6}>3 vs. 3</option>
            <option value={8}>4 vs. 4</option>
          </select>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="matchType">Match Type:</label>
          <select
            id="matchType"
            value={matchType}
            onChange={(e) => setMatchType(e.target.value)}
            required
          >
            <option value="normal">Normal</option>
            <option value="death">Death</option>
          </select>
        </div>

        <div className={styles.checkboxContainer}>
          <label htmlFor="pve">
            <input
              type="checkbox"
              id="pve"
              checked={pve}
              onChange={(e) => {
                setPve(e.target.checked);
                if (e.target.checked) {
                  setRandomTeams(false);
                }
              }}
            />
            PvE
          </label>
        </div>

        <div className={styles.checkboxContainer}>
          <label htmlFor="randomTeams">
            <input
              type="checkbox"
              id="randomTeams"
              checked={randomTeams}
              onChange={(e) => setRandomTeams(e.target.checked)}
              disabled={pve}
            />
            Random Teams
          </label>
        </div>

        <button type="submit" className={styles.createLobbyButton} disabled={isLoading}>
          {isLoading ? 'Creating...' : 'Create Lobby'}
        </button>
      </form>
    </div>
  );
};

export default CreateLobby;
