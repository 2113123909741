import React, { useState } from 'react';
import { useMercenary } from '../../../contexts/MercenaryContext';
import axios from 'axios';
import styles from './ActionButtons.module.css';
import { io } from 'socket.io-client';
import Modal from '../../Shared/Modal';
import Toast from '../Toast';
import { savePreferencesToDatabase } from './savePreferencesToDatabase';

const socket = io();

interface ActionButtonsProps {
  isOwner: boolean;
  userInCurrentLobby: boolean;
  isInAnyLobby: boolean;
  navigate: (path: string) => void;
  lobbyId: string;
  matchType: string;
  onBattleStart: () => void;
  teamsFull: boolean;
  randomTeams: boolean;
  setSelectedTeam: (team: 'teamOne' | 'teamTwo') => void;
  handleKickPlayer: (mercenaryId: number) => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  isOwner,
  userInCurrentLobby,
  isInAnyLobby,
  navigate,
  lobbyId,
  matchType,
  onBattleStart,
  teamsFull,
  randomTeams,
  setSelectedTeam,
  handleKickPlayer,
}) => {
  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [showDeathModal, setShowDeathModal] = useState(false);
  const [teamStatus, setTeamStatus] = useState({ teamOne: 0, teamTwo: 0, maxPlayers: 0 });
  const { mercenary } = useMercenary();

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  const fetchTeamStatus = async () => {
    try {
      const { data: lobby } = await axios.get(`/api/team-battle/lobbies/${lobbyId}`, { withCredentials: true });
      setTeamStatus({
        teamOne: lobby.teamOne.length,
        teamTwo: lobby.teamTwo.length,
        maxPlayers: lobby.maxPlayers,
      });
    } catch (err) {
      console.error('Failed to fetch team status:', err);
      displayToast('Failed to fetch team data.', 'error');
    }
  };

  const handleJoinLobby = async (team: 'teamOne' | 'teamTwo' | null = null) => {
    setLoading(true);
    try {
      if (!mercenary?.id) {
        setToastType('error');
        setToastMessage('Invalid mercenary. Please select a valid mercenary.');
        setLoading(false);
        return;
      }
  
      const { data: lobby } = await axios.get(`/api/team-battle/lobbies/${lobbyId}`, { withCredentials: true });
      const maxTeamSize = lobby.pve ? lobby.maxPlayers : Math.floor(lobby.maxPlayers / 2);
      const teamOneSize = lobby.teamOne.length;
      const teamTwoSize = lobby.teamTwo.length;
  
      if (lobby.pve) {
        if (teamOneSize >= maxTeamSize) {
          setToastType('error');
          setToastMessage('The team is full.');
          setLoading(false);
          return;
        }
      } else if (team === 'teamOne' && teamOneSize >= maxTeamSize) {
        setToastType('error');
        setToastMessage('Team One is full.');
        setLoading(false);
        return;
      } else if (team === 'teamTwo' && teamTwoSize >= maxTeamSize) {
        setToastType('error');
        setToastMessage('Team Two is full.');
        setLoading(false);
        return;
      }
  
      const selectedTeam = lobby.pve ? 'teamOne' : team;
      const localPreferences = localStorage.getItem(`preferences_${mercenary.id}`);
      const parsedPreferences = localPreferences ? JSON.parse(localPreferences) : {};
      const savedPreferences = {
        surrenderHP: parseFloat(parsedPreferences.preferences?.surrenderHP || '0.5'),
        readyHP: parseFloat(parsedPreferences.preferences?.readyHP || '0.5'),
        strategy: parsedPreferences.preferences?.strategy || 'Normal',
      };
  
      const payload = { mercenaryId: mercenary.id, team: selectedTeam, ...savedPreferences };
      await axios.post(`/api/team-battle/lobbies/${lobbyId}/join`, payload, { withCredentials: true });
      await savePreferencesToDatabase(Number(lobbyId), mercenary.id, savedPreferences);
  
      setToastType('success');
      setToastMessage(
        `Joined ${lobby.pve ? 'the team' : selectedTeam === 'teamOne' ? 'Team One' : 'Team Two'} successfully.`
      );
      setShowTeamModal(false);
      navigate(`/team-battle/lobby/${lobbyId}`);
    } catch (err) {
      console.error('Error joining lobby:', err);
      setToastType('error');
      setToastMessage('Failed to join the lobby.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteLobby = async () => {
    setLoading(true);
    try {
      await axios.delete(`/api/team-battle/lobbies/${lobbyId}`, { withCredentials: true });
      displayToast('Lobby deleted successfully.', 'success');
      navigate('/team-battle');
    } catch (err) {
      console.error('Error deleting lobby:', err);
      displayToast('Failed to delete the lobby.', 'error');
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
    }
  };

  const handleLeaveLobby = async () => {
    setLoading(true);
    try {
      await axios.post(`/api/team-battle/lobbies/${lobbyId}/leave`, {}, { withCredentials: true });
      displayToast('Left the lobby successfully.', 'success');
      navigate('/team-battle');
    } catch (err) {
      console.error('Error leaving lobby:', err);
      displayToast('Failed to leave the lobby.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleKick = () => {
    if (!isOwner) {
      displayToast('Only the lobby owner can kick players.', 'error');
      return;
    }

    const targetId = parseInt(prompt('Enter the Mercenary ID to kick:', '') || '0', 10);

    if (isNaN(targetId) || targetId <= 0) {
      displayToast('Invalid Mercenary ID.', 'error');
      return;
    }

    if (mercenary?.id === targetId) {
      displayToast('You cannot kick yourself from the lobby.', 'error');
      return;
    }

    handleKickPlayer(targetId);
  };

  return (
    <div className={styles.actionButtons}>
      {toastMessage && <Toast message={toastMessage} type={toastType} onClose={() => setToastMessage(null)} />}

      {!userInCurrentLobby && (
        <button
          onClick={() => {
            if (randomTeams) {
              handleJoinLobby();
            } else {
              setShowTeamModal(true);
            }
          }}
          disabled={loading}
        >
          {loading ? 'Joining...' : 'Join Lobby'}
        </button>
      )}

      {userInCurrentLobby && (
        isOwner ? (
          <button onClick={() => setShowDeleteModal(true)} disabled={loading}>
            {loading ? 'Deleting...' : 'Delete Lobby'}
          </button>
        ) : (
          <button onClick={handleLeaveLobby} disabled={loading}>
            {loading ? 'Leaving...' : 'Leave Lobby'}
          </button>
        )
      )}

      {isOwner && (
        <>
          <button onClick={onBattleStart} disabled={loading || !teamsFull}>
            {loading ? 'Starting Battle...' : 'Start Battle'}
          </button>
        </>
      )}

      <button onClick={() => navigate('/team-battle')} disabled={loading}>
        Back to Lobbies
      </button>

      <Modal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title="Confirm Delete Lobby"
        message="Are you sure you want to delete this lobby?"
        customButtons={[
          {
            text: 'Cancel',
            action: () => setShowDeleteModal(false),
          },
          {
            text: 'Delete',
            action: handleDeleteLobby,
          },
        ]}
      />

      <Modal
        show={showTeamModal}
        onClose={() => setShowTeamModal(false)}
        title="Select a Team"
        message="Choose a team to join."
        customButtons={[
          {
            text: 'Team One',
            action: () => handleJoinLobby('teamOne'),
            disabled: teamStatus.teamOne >= Math.floor(teamStatus.maxPlayers / 2),
          },
          {
            text: 'Team Two',
            action: () => handleJoinLobby('teamTwo'),
            disabled: teamStatus.teamTwo >= Math.floor(teamStatus.maxPlayers / 2),
          },
        ]}
      />

      <Modal
        show={showDeathModal}
        onClose={() => setShowDeathModal(false)}
        onConfirm={fetchTeamStatus}
        title="Confirm Death Battle"
        message="You are about to join a Death Battle. Do you want to proceed?"
      />
    </div>
  );
};

export default ActionButtons;
