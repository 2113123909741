import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import {
  Chart,
  ArcElement,
  PointElement,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';

// Register elements
Chart.register(ArcElement, PointElement, LineElement, BarElement, CategoryScale, LinearScale);

const BattleMetrics: React.FC = () => {
  const [battleStats, setBattleStats] = useState({
    totalBattles: 0,
    avgDuration: 0,
    winRatio: 0,
  });

  useEffect(() => {
    const fetchBattleStats = async () => {
      try {
        const response = await axios.get('/api/admin/battle-stats');
        setBattleStats(response.data);
      } catch (error) {
        console.error('Error fetching battle statistics', error);
      }
    };

    fetchBattleStats();
  }, []);

  const data = {
    labels: ['Total Battles', 'Average Duration (mins)', 'Win Ratio'],
    datasets: [
      {
        label: 'Battles',
        backgroundColor: '#ffdd57',
        data: [battleStats.totalBattles, battleStats.avgDuration, battleStats.winRatio],
      },
    ],
  };

  return (
    <div className="metric-card">
      <h3>Combat Statistics</h3>
      <Line data={data} />
    </div>
  );
};

export default BattleMetrics;
