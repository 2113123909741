import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';

interface StrategyStat {
  strategy: string;
  battlesPvE: number;
  winsPvE: number;
  battlesDuel: number;
  winsDuel: number;
  battlesTeam: number;
  winsTeam: number;
  battlesTournament: number;
  winsTournament: number;
  season: { name: string };
}

const StrategyMetrics: React.FC = () => {
  const [strategyStats, setStrategyStats] = useState<StrategyStat[]>([]);

  useEffect(() => {
    const fetchStrategyStats = async () => {
      try {
        const response = await axios.get('/api/admin/strategy-insights');
        setStrategyStats(response.data);
      } catch (error) {
        console.error('Error fetching strategy stats:', error);
      }
    };
    fetchStrategyStats();
  }, []);

  const data = {
    labels: strategyStats.map((stat) => `${stat.strategy}`),
    datasets: [
      {
        label: 'Strategy Effectiveness',
        data: strategyStats.map((stat) => stat.winsDuel),
        backgroundColor: ['#00bfff', '#ff4500', '#ffd700', '#32cd32', '#ffa500'],
      },
    ],
  };

  return (
    <div className="metric-card">
      <h3>Strategy Usage</h3>
      <div className="chart-container">
        <Pie data={data} />
      </div>
    </div>
  );
};

export default StrategyMetrics;
