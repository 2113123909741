import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './TournamentBracket.module.css';

interface Mercenary {
  name: string;
}

interface Match {
  id: number;
  team1Participants: Mercenary[];
  team2Participants: Mercenary[];
  winner_team?: number;
  round_number: number;
  battle_report?: number;
  match_type?: 'normal' | 'final' | 'third_place';
}

interface BracketProps {
  matches: Match[];
}

const TournamentBracket: React.FC<BracketProps> = ({ matches }) => {
  const [expandedRounds, setExpandedRounds] = useState<{ [key: string]: boolean }>({});

  const toggleRound = (roundKey: string) => {
    setExpandedRounds((prev) => ({
      ...prev,
      [roundKey]: !prev[roundKey],
    }));
  };

  // Group matches by rounds
  const rounds = matches.reduce((acc, match) => {
    const roundKey =
      match.match_type === 'final'
        ? 'Final'
        : match.match_type === 'third_place'
        ? 'Final'
        : `Round ${match.round_number}`;

    if (!acc[roundKey]) acc[roundKey] = [];
    acc[roundKey].push(match);
    return acc;
  }, {} as { [key: string]: Match[] });

  // Sort the rounds to achieve desired order
  const sortedRoundKeys = Object.keys(rounds).sort((a, b) => {
    if (a.startsWith('Round') && b.startsWith('Round')) {
      return parseInt(a.split(' ')[1]) - parseInt(b.split(' ')[1]);
    }
    if (a.startsWith('Round')) return -1;
    if (b.startsWith('Round')) return 1;
    if (a === 'Final') return 1;
    return 0;
  });

  return (
    <div className={styles.tournamentBracket}>
      {sortedRoundKeys.map((roundKey) => (
        <div key={roundKey} className={styles.round}>
          <h3 onClick={() => toggleRound(roundKey)} className={styles.roundHeader}>
            {roundKey} {expandedRounds[roundKey] ? '-' : '+'}
          </h3>
          {expandedRounds[roundKey] && (
            <div className={styles.matches}>
              {roundKey === 'Final' && (
                <>
                  {rounds[roundKey]
                    .filter((match) => match.match_type === 'final')
                    .map((match) => (
                      <div key={match.id} className={styles.match}>
                        <div className={styles.finalLabel}>Final Match</div>
                        <div className={`${styles.team} ${match.winner_team === 1 ? styles.winnerTeam : ''}`}>
                          {match.team1Participants.map((participant) => (
                            <div key={participant.name} className={styles.participantName}>
                              {participant.name}
                            </div>
                          ))}
                        </div>
                        <div className={styles.vs}>VS</div>
                        <div className={`${styles.team} ${match.winner_team === 2 ? styles.winnerTeam : ''}`}>
                          {match.team2Participants.map((participant) => (
                            <div key={participant.name} className={styles.participantName}>
                              {participant.name}
                            </div>
                          ))}
                        </div>
                        {match.winner_team && (
                          <div className={styles.winner}>Winner: Team {match.winner_team}</div>
                        )}
                        {match.battle_report && (
                          <Link to={`/battle-result/${match.battle_report}`} className={styles.viewBattleReportLink}>
                            View Battle Report
                          </Link>
                        )}
                      </div>
                    ))}
                  {rounds[roundKey]
                    .filter((match) => match.match_type === 'third_place')
                    .map((match) => (
                      <div key={match.id} className={styles.match}>
                        <div className={styles.thirdPlaceLabel}>Third Place Match</div>
                        <div className={`${styles.team} ${match.winner_team === 1 ? styles.winnerTeam : ''}`}>
                          {match.team1Participants.map((participant) => (
                            <div key={participant.name} className={styles.participantName}>
                              {participant.name}
                            </div>
                          ))}
                        </div>
                        <div className={styles.vs}>VS</div>
                        <div className={`${styles.team} ${match.winner_team === 2 ? styles.winnerTeam : ''}`}>
                          {match.team2Participants.map((participant) => (
                            <div key={participant.name} className={styles.participantName}>
                              {participant.name}
                            </div>
                          ))}
                        </div>
                        {match.winner_team && (
                          <div className={styles.winner}>Winner: Team {match.winner_team}</div>
                        )}
                        {match.battle_report && (
                          <Link to={`/battle-result/${match.battle_report}`} className={styles.viewBattleReportLink}>
                            View Battle Report
                          </Link>
                        )}
                      </div>
                    ))}
                </>
              )}
              {roundKey !== 'Final' &&
                rounds[roundKey].map((match) => (
                  <div key={match.id} className={styles.match}>
                    <div className={`${styles.team} ${match.winner_team === 1 ? styles.winnerTeam : ''}`}>
                      {match.team1Participants.map((participant) => (
                        <div key={participant.name} className={styles.participantName}>
                          {participant.name}
                        </div>
                      ))}
                    </div>
                    <div className={styles.vs}>VS</div>
                    <div className={`${styles.team} ${match.winner_team === 2 ? styles.winnerTeam : ''}`}>
                      {match.team2Participants.map((participant) => (
                        <div key={participant.name} className={styles.participantName}>
                          {participant.name}
                        </div>
                      ))}
                    </div>
                    {match.winner_team && (
                      <div className={styles.winner}>Winner: Team {match.winner_team}</div>
                    )}
                    {match.battle_report && (
                      <Link to={`/battle-result/${match.battle_report}`} className={styles.viewBattleReportLink}>
                        View Battle Report
                      </Link>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TournamentBracket;
