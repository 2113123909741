import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useMercenary } from '../../../contexts/MercenaryContext';
import { Link } from 'react-router-dom';
import Toast from '../Toast';
import styles from './BattleHistory.module.css';

interface BattleReport {
  id: number;
  title: string;
  createdAt: string;
  winningMercenaryIds: string | null;
  losingMercenaryIds: string | null;
}

const BattleHistory: React.FC = () => {
  const { mercenary } = useMercenary();
  const [battles, setBattles] = useState<BattleReport[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  useEffect(() => {
    const fetchBattleHistory = async () => {
      setLoading(true);
      try {
        if (mercenary) {
          const response = await axios.get(`/api/battle/history/${mercenary.id}?page=${page}&limit=20`);
          setBattles(response.data);
        }
      } catch (error) {
        displayToast('Error fetching battle history.', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchBattleHistory();
  }, [mercenary, page]);

  const handleNextPage = () => {
    if (battles.length === 20) setPage(page + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage(page - 1);
  };

  const renderWinLoss = (battle: BattleReport) => {
    if (battle.winningMercenaryIds && battle.winningMercenaryIds.includes(`${mercenary?.id}`)) {
      return <span className={styles.statusWin}>Win</span>;
    } else if (battle.losingMercenaryIds && battle.losingMercenaryIds.includes(`${mercenary?.id}`)) {
      return <span className={styles.statusLoss}>Loss</span>;
    } else {
      return <span className={styles.statusUnknown}>N/A</span>;
    }
  };

  return (
    <div className={styles.battleHistoryContainer}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}
      <h1 className={styles.title}>Battle History</h1>
      {loading ? (
        <p className={styles.loading}>Loading...</p>
      ) : (
        <>
          <table className={styles.battleHistoryTable}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Title</th>
                <th>Result</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {battles.map((battle) => (
                <tr key={battle.id}>
                  <td>{new Date(battle.createdAt).toLocaleDateString()}</td>
                  <td>{battle.title}</td>
                  <td>{renderWinLoss(battle)}</td>
                  <td>
                    <Link to={`/battle-result/${battle.id}`} className={styles.viewButton}>
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.pagination}>
            <button onClick={handlePreviousPage} disabled={page === 1}>
              Previous
            </button>
            <button onClick={handleNextPage} disabled={battles.length < 20}>
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default BattleHistory;
