import React, { useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import axios from 'axios';
import Toast from '../Toast';
import styles from './SendMail.module.css';

interface SendMailProps {
  recipientId: number;
  recipientName: string;
  onClose: () => void;
}

const SendMail: React.FC<SendMailProps> = ({ recipientId, recipientName, onClose }) => {
  const { user } = useAuth();
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
    setTimeout(() => setToastMessage(null), 3000);
  };

  const handleSendMail = async () => {
    try {
      await axios.post('/api/mail/send', {
        senderId: user.id,
        receiverId: recipientId,
        subject,
        message,
      });
      displayToast('Mail sent successfully!', 'success');
      onClose();
    } catch (error) {
      console.error('Error sending mail:', error);
      displayToast('Failed to send mail.', 'error');
    }
  };

  return (
    <div className={styles.modal}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}
      <div className={styles.modalContent}>
        <h2>Send Mail to {recipientName}</h2>
        <input
          type="text"
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <textarea
          placeholder="Write your message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button onClick={handleSendMail}>Send</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default SendMail;
