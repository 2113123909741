import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Tooltip from '../ItemTooltip';
import Toast from '../Toast';
import styles from './CraftingProgress.module.css';

interface CraftingProject {
  id: number;
  blueprintName: string;
  requiredRounds: number;
  remainingRounds: number;
  icon: string;
  description: string;
  createdAt: string;
}

interface Mercenary {
  id: number;
  professionSkillPoints: number;
}

const CraftingProgress: React.FC<{ mercenary: Mercenary }> = ({ mercenary }) => {
  const [projects, setProjects] = useState<CraftingProject[]>([]);
  const [workRounds, setWorkRounds] = useState<{ [projectId: number]: number }>({});
  const [sortConfig, setSortConfig] = useState<{ key: keyof CraftingProject; direction: 'asc' | 'desc' } | null>(null);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  useEffect(() => {
    fetchCraftingProjects();
  }, []);

  const fetchCraftingProjects = async () => {
    try {
      const { data } = await axios.get(`/api/craft/projects/${mercenary.id}`, { withCredentials: true });
      const sortedProjects = [...data.projects].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      setProjects(sortedProjects);
    } catch (error) {
      displayToast('Error fetching crafting projects', 'error');
    }
  };

  const handleWorkOnProject = async (projectId: number) => {
    const rounds = workRounds[projectId] || 1;
    try {
      await axios.post(`/api/craft/projects/${projectId}/work`, { rounds, mercenaryId: mercenary.id }, { withCredentials: true });
      displayToast('Work completed on crafting project!', 'success');
      fetchCraftingProjects();
    } catch (error) {
      displayToast('Error working on crafting project', 'error');
    }
  };

  const handleRoundChange = (projectId: number, rounds: number) => {
    const validRounds = Math.max(1, Math.min(rounds, projects.find(p => p.id === projectId)?.remainingRounds || 1));
    setWorkRounds((prev) => ({ ...prev, [projectId]: validRounds }));
  };

  const handleSort = (key: keyof CraftingProject) => {
    setSortConfig((prevSort) => {
      const direction = prevSort?.key === key && prevSort.direction === 'asc' ? 'desc' : 'asc';
      return { key, direction };
    });
  };

  const sortedProjects = [...projects].sort((a, b) => {
    if (sortConfig) {
      const { key, direction } = sortConfig;
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    }
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  return (
    <div className={styles.craftingProgressContainer}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}
      <h2>Ongoing Crafting Projects</h2>
      {projects.length === 0 ? (
        <p>No ongoing projects. Start a new crafting project in the Crafting District.</p>
      ) : (
        <table className={styles.projectTable}>
          <thead>
            <tr>
              <th onClick={() => handleSort('blueprintName')}>Blueprint</th>
              <th onClick={() => handleSort('requiredRounds')}>Required Rounds</th>
              <th onClick={() => handleSort('remainingRounds')}>Remaining Rounds</th>
              <th>Rounds to Work</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {sortedProjects.map((project) => (
              <tr key={project.id}>
                <td>
                  <Tooltip itemId={project.id}>
                    <div className={styles.itemCell}>
                      <img
                        src={project.icon || `/img/items/${project.blueprintName || 'default_icon'}.png`}
                        alt={`${project.blueprintName} icon`}
                        className={styles.itemIcon}
                      />
                      <span>{project.blueprintName}</span>
                    </div>
                  </Tooltip>
                </td>
                <td>{project.requiredRounds}</td>
                <td>{project.remainingRounds}</td>
                <td>
                  <input
                    type="number"
                    min="1"
                    max={project.remainingRounds}
                    value={workRounds[project.id] || 1}
                    onChange={(e) => handleRoundChange(project.id, parseInt(e.target.value, 10))}
                    className={styles.roundInput}
                  />
                </td>
                <td>
                  <button onClick={() => handleWorkOnProject(project.id)} className={styles.workButton}>
                    Work on Project
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CraftingProgress;
