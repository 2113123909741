import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './CreateTournament.module.css';

const CreateTournament: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    type: 'normal',
    team_size: 1,
    num_teams: 8,
    silver_reward: 0,
    silver_reward_1st: 1000,
    silver_reward_2nd: 500,
    silver_reward_3rd: 250,
    item_reward_1st: '',
    item_reward_2nd: '',
    item_reward_3rd: '',
    start_time: new Date(),
    legend: false,
    min_level: 1,
    max_level: 100,
    race_requirement: 'All',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [filteredItems, setFilteredItems] = useState<{ [key: string]: string[] }>({
    item_reward_1st: [],
    item_reward_2nd: [],
    item_reward_3rd: [],
  });
  const [searchTerms, setSearchTerms] = useState<{ [key: string]: string }>({
    item_reward_1st: '',
    item_reward_2nd: '',
    item_reward_3rd: '',
  });

  useEffect(() => {
    const fetchFilteredItems = async (rewardKey: string) => {
      const term = searchTerms[rewardKey];
      if (term.length === 0) {
        setFilteredItems((prev) => ({
          ...prev,
          [rewardKey]: [],
        }));
        return;
      }

      try {
        const response = await axios.get('/api/admin/items/search', {
          params: { term },
        });
        setFilteredItems((prev) => ({
          ...prev,
          [rewardKey]: response.data.map((item: any) => item.name),
        }));
      } catch (error) {
        console.error(`Error searching items for ${rewardKey}:`, error);
      }
    };

    Object.keys(searchTerms).forEach((rewardKey) => {
      fetchFilteredItems(rewardKey);
    });
  }, [searchTerms]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: e.target instanceof HTMLInputElement && e.target.type === 'checkbox'
        ? e.target.checked
        : value,
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchTerms((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      min_level: formData.legend ? 20 : 1,
      max_level: formData.legend ? 25 : 100,
      type: formData.legend ? 'death' : prevData.type,
    }));
  }, [formData.legend]);

  const handleSelectItem = (rewardKey: string, itemName: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [rewardKey]: itemName,
    }));
    setSearchTerms((prev) => ({
      ...prev,
      [rewardKey]: '',
    }));
    setFilteredItems((prev) => ({
      ...prev,
      [rewardKey]: [],
    }));
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setFormData((prevData) => ({
        ...prevData,
        start_time: date,
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const formattedData = {
        ...formData,
        top_3_rewards: JSON.stringify({
          "1st": formData.silver_reward_1st,
          "2nd": formData.silver_reward_2nd,
          "3rd": formData.silver_reward_3rd,
        }),
        item_rewards: JSON.stringify({
          "1st": formData.item_reward_1st,
          "2nd": formData.item_reward_2nd,
          "3rd": formData.item_reward_3rd,
        }),
      };
      await axios.post('/api/tournament/create', formattedData);
      setSuccessMessage('Tournament created successfully!');
      setFormData({
        name: '',
        type: 'normal',
        team_size: 1,
        num_teams: 8,
        silver_reward: 0,
        silver_reward_1st: 1000,
        silver_reward_2nd: 500,
        silver_reward_3rd: 250,
        item_reward_1st: '',
        item_reward_2nd: '',
        item_reward_3rd: '',
        start_time: new Date(),
        legend: false,
        min_level: 1,
        max_level: 100,
        race_requirement: 'All',
      });
      setTimeout(() => {
        navigate('/admin/tournaments');
      }, 1500);
    } catch (error) {
      console.error('Error creating tournament:', error);
      setSuccessMessage('Failed to create tournament. Please try again.');
    }
  };

  return (
    <div className={styles.createTournamentContainer}>
      <h2 className={styles.title}>Create Tournament</h2>
      <form onSubmit={handleSubmit} className={styles.createTournamentForm}>
        <label>
          Tournament Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={styles.inputField}
            required
          />
        </label>

        <label>
          Type:
          <select
            name="type"
            value={formData.type}
            onChange={handleChange}
            disabled={formData.legend}
            className={styles.selectField}
          >
            <option value="normal">Normal</option>
            <option value="death">Death Match</option>
          </select>
        </label>

        <label>
          Team Size:
          <select
            name="team_size"
            value={formData.team_size}
            onChange={handleChange}
            className={styles.selectField}
          >
            <option value={1}>1 vs 1</option>
            <option value={2}>2 vs 2</option>
            <option value={3}>3 vs 3</option>
            <option value={4}>4 vs 4</option>
          </select>
        </label>

        <label>
          Number of Contestants:
          <select
            name="num_teams"
            value={formData.num_teams}
            onChange={handleChange}
            className={styles.selectField}
          >
            <option value={8}>8</option>
            <option value={16}>16</option>
            <option value={32}>32</option>
            <option value={64}>64 (Recommended max)</option>
            <option value={128}>128</option>
            <option value={256}>256</option>
            <option value={512}>512</option>
            <option value={1024}>1024</option>
          </select>
        </label>

        <label>
          Start Time:
          <DatePicker
            selected={formData.start_time}
            onChange={handleDateChange}
            showTimeSelect
            dateFormat="Pp"
            className={styles.inputField}
          />
        </label>

        <label>
          Silver Reward for All Participants:
          <input
            type="number"
            name="silver_reward_all"
            value={formData.silver_reward}
            onChange={handleChange}
            className={styles.inputField}
          />
        </label>

        <label>
          Silver Reward - 1st Place:
          <input
            type="number"
            name="silver_reward_1st"
            value={formData.silver_reward_1st}
            onChange={handleChange}
            className={styles.inputField}
          />
        </label>

        <label>
          Silver Reward - 2nd Place:
          <input
            type="number"
            name="silver_reward_2nd"
            value={formData.silver_reward_2nd}
            onChange={handleChange}
            className={styles.inputField}
          />
        </label>

        <label>
          Silver Reward - 3rd Place:
          <input
            type="number"
            name="silver_reward_3rd"
            value={formData.silver_reward_3rd}
            onChange={handleChange}
            className={styles.inputField}
          />
        </label>

        <label>
          Item Reward - 1st Place:
          <input
            type="text"
            name="item_reward_1st"
            value={searchTerms.item_reward_1st}
            onChange={handleSearchChange}
            placeholder="Search item reward..."
            className={styles.inputField}
          />
          {filteredItems.item_reward_1st.length > 0 && (
            <div className={styles.dropdown}>
              {filteredItems.item_reward_1st.map((item) => (
                <div
                  key={item}
                  className={styles.dropdownItem}
                  onClick={() => handleSelectItem('item_reward_1st', item)}
                >
                  {item}
                </div>
              ))}
            </div>
          )}
          <div>Selected: {formData.item_reward_1st || 'None'}</div>
        </label>

        <label>
          Item Reward - 2nd Place:
          <input
            type="text"
            name="item_reward_2nd"
            value={searchTerms.item_reward_2nd}
            onChange={handleSearchChange}
            placeholder="Search item reward..."
            className={styles.inputField}
          />
          {filteredItems.item_reward_2nd.length > 0 && (
            <div className={styles.dropdown}>
              {filteredItems.item_reward_2nd.map((item) => (
                <div
                  key={item}
                  className={styles.dropdownItem}
                  onClick={() => handleSelectItem('item_reward_2nd', item)}
                >
                  {item}
                </div>
              ))}
            </div>
          )}
          <div>Selected: {formData.item_reward_2nd || 'None'}</div>
        </label>

        <label>
          Item Reward - 3rd Place:
          <input
            type="text"
            name="item_reward_3rd"
            value={searchTerms.item_reward_3rd}
            onChange={handleSearchChange}
            placeholder="Search item reward..."
            className={styles.inputField}
          />
          {filteredItems.item_reward_3rd.length > 0 && (
            <div className={styles.dropdown}>
              {filteredItems.item_reward_3rd.map((item) => (
                <div
                  key={item}
                  className={styles.dropdownItem}
                  onClick={() => handleSelectItem('item_reward_3rd', item)}
                >
                  {item}
                </div>
              ))}
            </div>
          )}
          <div>Selected: {formData.item_reward_3rd || 'None'}</div>
        </label>

        <label>
          Legendary Tournament:
          <input
            type="checkbox"
            name="legend"
            checked={formData.legend}
            onChange={handleChange}
            className={styles.checkbox}
          />
        </label>

        <label>
          Minimum Level:
          <input
            type="number"
            name="min_level"
            value={formData.min_level}
            onChange={handleChange}
            className={styles.inputField}
          />
        </label>

        <label>
          Maximum Level:
          <input
            type="number"
            name="max_level"
            value={formData.max_level}
            onChange={handleChange}
            className={styles.inputField}
          />
        </label>

        <label>
          Race Requirement:
          <select
            name="race_requirement"
            value={formData.race_requirement}
            onChange={handleChange}
            className={styles.selectField}
          >
            <option value="All">All</option>
            <option value="Red Blood">Red Blood (Elf, Dwarf, Human)</option>
            <option value="Black Blood">Black Blood (Orc, Goblin, Troll)</option>
            <option value="Elf">Elf</option>
            <option value="Dwarf">Dwarf</option>
            <option value="Human">Human</option>
            <option value="Orc">Orc</option>
            <option value="Goblin">Goblin</option>
            <option value="Troll">Troll</option>
          </select>
        </label>

        <button type="submit" className={styles.createTournamentBtn}>
          Create Tournament
        </button>
      </form>

      {successMessage && <p className={styles.successMessage}>{successMessage}</p>}
    </div>
  );
};

export default CreateTournament;
