import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';

declare global {
  interface Window {
    ATL_JQ_PAGE_PROPS: any;
  }
}

interface User {
  role: {
    name: string;
  };
}

interface HeaderProps {
  user: User | null;
}

const Header: React.FC<HeaderProps> = ({ user }) => {
  useEffect(() => {
    // Load the Jira Issue Collector script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://njordheim.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/g2slup/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=6d0fb5a9';
    script.async = true;
    document.body.appendChild(script);

    // Add the trigger function for the Jira collector dialog
    window.ATL_JQ_PAGE_PROPS = {
      triggerFunction: function (showCollectorDialog: () => void) {
        document.getElementById("reportBtn")?.addEventListener("click", function (e) {
          e.preventDefault();
          showCollectorDialog();
        });
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <header className={styles.banner}>
      <Link to="/home">
        <h1 className={styles.logo}>Njordheim Arena</h1>
      </Link>
      <div className={styles.topRightLinks}>
        <button id="reportBtn" className={styles.issueButton}>Report an Issue</button>
        {user?.role.name === 'Admin' || user?.role.name === 'Owner' ? (
          <Link to="/admin">Admin Page</Link>
        ) : null}
        {user?.role.name === 'Moderator' || user?.role.name === 'Admin' || user?.role.name === 'Owner' ? (
          <Link to="/moderator">Moderator Page</Link>
        ) : null}
      </div>
    </header>
  );
};

export default Header;
