import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { SocketProvider } from './contexts/SocketContext';
import { MercenaryProvider, useMercenary } from './contexts/MercenaryContext';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Home from './components/Pages/Home';
import Landing from './components/Pages/Landing';
import CreateMercenary from './components/Pages/CreateMercenary/CreateMercenary';
import MainLayout from './components/Layout/MainLayout';
import MercenaryProfile from './components/Pages/MercenaryLinks/MercenaryProfile';
import Market from './components/Pages/Market/Market';
import Beasts from './components/Pages/Beast/Beasts';
import Inventory from './components/Pages/MercenaryLinks/Inventory';
import LevelUp from './components/Pages/LevelUp/LevelUp';
import AdminPage from './components/Pages/Admin/AdminPage';
import PostPatchNotes from './components/Pages/Admin/PostPatchNotes';
import MarkdownViewer from './components/Pages/Admin/MarkdownViewer';
import CreateItem from './components/Pages/Admin/CreateItem';
import EditItem from './components/Pages/Admin/EditItem';
import Duel from './components/Pages/Duel/Duel';
import CreateBeast from './components/Pages/Admin/BeastEditor';
import BattleResult from './components/Pages/BattleResult';
import AssignItemsToVendor from './components/Pages/Admin/AssignItemsToVendor';
import BeastBattle from './components/Pages/Beast/BeastBattle';
import ModeratorPage from './components/Pages/Moderator/ModeratorPage';
import Bio from './components/Pages/MercenaryLinks/Bio';
import Attributes from './components/Pages/MercenaryLinks/Attributes';
import BattleHistory from './components/Pages/MercenaryLinks/BattleHistory';
import Statistics from './components/Pages/MercenaryLinks/Statistics';
import Profession from './components/Pages/MercenaryLinks/Profession';
import Achievements from './components/Pages/MercenaryLinks/Achievements';
import Activity from './components/Pages/MercenaryLinks/Activity';
import Mercenaries from './components/Pages/AccountLinks/Mercenaries';
import MyMercenaries from './components/Pages/MercenaryLinks/MyMercenaries';
import Notes from './components/Pages/AccountLinks/Notes';
import FriendsList from './components/Pages/AccountLinks/FriendsList';
import Mail from './components/Pages/AccountLinks/Mail';
import Account from './components/Pages/AccountLinks/Account';
import AuthInitializer from './AuthInitializer';
import TeamBattles from './components/Pages/TeamBattle/TeamBattles';
import CreateLobby from './components/Pages/TeamBattle/CreateLobby';
import LobbyView from './components/Pages/TeamBattle/LobbyView';
import Ranking from './components/Pages/Ranking/Ranking';
import DeathScreen from './components/Pages/DeathScreen';
import CreateTournament from './components/Pages/Admin/CreateTournament';
import ViewTournaments from './components/Pages/Tournament/ViewTournaments';
import TournamentDetails from './components/Pages/Tournament/TournamentDetail';
import Adventure from './components/Pages/Adventure/Adventure';
import AdventureList from './components/Pages/Adventure/AdventureList';
import AdventureCompletion from './components/Pages/Adventure/AdventureCompletion';
import ChapterView from './components/Pages/Adventure/ChapterView';
import AuctionHouse from './components/Pages/AuctionHouse/AuctionHouse';
import Browse from './components/Pages/AuctionHouse/Browse';
import BuyOrder from './components/Pages/AuctionHouse/BuyOrder';
import SellOrder from './components/Pages/AuctionHouse/SellOrder';
import History from './components/Pages/AuctionHouse/History';
import Tavern from './components/Pages/Tavern/Tavern';
import ManageUser from './components/Pages/Admin/ManageUser';
import AuditLog from './components/Pages/Admin/AuditLog';
import Profile from './components/Pages/Profile/Profile';
import WorkDistrict from './components/Pages/WorkDistrict/WorkDistrict';
import CraftingDistrict from './components/Pages/Crafting/CraftingDistrict';
import WorldEditor from './components/Pages/Admin/WorldEditor';
import Vault from './components/Pages/Vault/VaultPage';
import ContractBattles from './components/Pages/ContractBattle/ContractBattle';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <SocketProvider>
        <MercenaryProvider>
          <Router>
            <AuthInitializer>
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<Landing />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />

                {/* Protected Routes */}
                <Route path="/home" element={<ProtectedRoute roles={['Member', 'Admin', 'Owner']}><MainLayout><Home /></MainLayout></ProtectedRoute>}/>
                <Route path="/admin" element={<ProtectedRoute roles={['Admin', 'Owner']}><MainLayout><AdminPage /></MainLayout></ProtectedRoute>}/>
                <Route path="/audit" element={<ProtectedRoute roles={['Owner']}><MainLayout><AuditLog /></MainLayout></ProtectedRoute>}/>
                <Route path="/world-editor" element={<ProtectedRoute roles={['Admin', 'Owner']}><MainLayout><WorldEditor /></MainLayout></ProtectedRoute>}/>
                <Route path="/create-item" element={<ProtectedRoute roles={['Admin', 'Owner']}><MainLayout><CreateItem /></MainLayout></ProtectedRoute>}/>
                <Route path="/edit-item" element={<ProtectedRoute roles={['Admin', 'Owner']}><MainLayout><EditItem /></MainLayout></ProtectedRoute>}/>
                <Route path="/post-patch-note" element={<ProtectedRoute roles={['Admin', 'Owner']}><MainLayout><PostPatchNotes /></MainLayout></ProtectedRoute>}/>
                <Route path="/create-beast" element={<ProtectedRoute roles={['Admin', 'Owner']}><MainLayout><CreateBeast mode="create" /></MainLayout></ProtectedRoute>}/>
                <Route path="/assign-items" element={<ProtectedRoute roles={['Admin', 'Owner']}><MainLayout><AssignItemsToVendor /></MainLayout></ProtectedRoute>}/>
                <Route path="/create-tournament" element={<ProtectedRoute roles={['Admin', 'Owner']}><MainLayout><CreateTournament /></MainLayout></ProtectedRoute>}/>
                <Route path="/manage-user" element={<ProtectedRoute roles={['Admin', 'Owner']}><MainLayout><ManageUser /></MainLayout></ProtectedRoute>}/>
                <Route path="/moderator" element={<ProtectedRoute roles={['Moderator', 'Admin', 'Owner']}><MainLayout><ModeratorPage /></MainLayout></ProtectedRoute>}/>
                <Route path="/level-up" element={<ProtectedRoute roles={['Member', 'Admin', 'Owner']}><MainLayout><LevelUp /></MainLayout></ProtectedRoute>}/>
                <Route path="/create-mercenary" element={<ProtectedRoute><MainLayout><CreateMercenary /></MainLayout></ProtectedRoute>}/>
                <Route path="/mercenary" element={<ProtectedRoute><MainLayout><MercenaryProfile /></MainLayout></ProtectedRoute>}/>
                <Route path="/mercenaries" element={<ProtectedRoute><MainLayout><MyMercenaries /></MainLayout></ProtectedRoute>} />
                <Route path="/team-battle" element={<ProtectedRoute><MainLayout><TeamBattles /></MainLayout></ProtectedRoute>}/>
                <Route path="/team-battle/create" element={<ProtectedRoute><MainLayout><CreateLobby /></MainLayout></ProtectedRoute>}/>
                <Route path="/team-battle/lobby/:lobbyId" element={<ProtectedRoute><MainLayout><LobbyView /></MainLayout></ProtectedRoute>}/>
                <Route path="/market" element={<ProtectedRoute><MainLayout><Market /></MainLayout></ProtectedRoute>}/>
                <Route path="/beasts" element={<ProtectedRoute><MainLayout><Beasts /></MainLayout></ProtectedRoute>}/>
                <Route path="/beast-battle" element={<ProtectedRoute><MainLayout><BeastBattle /></MainLayout></ProtectedRoute>}/>
                <Route path="/inventory" element={<ProtectedRoute><MainLayout><Inventory /></MainLayout></ProtectedRoute>}/>
                <Route path="/duel" element={<ProtectedRoute><MainLayout><Duel /></MainLayout></ProtectedRoute>}/>
                <Route path="/battle-result/:battleId" element={<ProtectedRoute><MainLayout><BattleResult /></MainLayout></ProtectedRoute>}/>
                <Route path="/docs/:filename" element={<ProtectedRoute><MainLayout><MarkdownViewer /></MainLayout></ProtectedRoute>}/>
                <Route path="/bio" element={<ProtectedRoute><MainLayout><Bio /></MainLayout></ProtectedRoute>}/>
                <Route path="/attributes" element={<ProtectedRoute><MainLayout><Attributes /></MainLayout></ProtectedRoute>}/>
                <Route path="/battle-history" element={<ProtectedRoute><MainLayout><BattleHistory /></MainLayout></ProtectedRoute>}/>
                <Route path="/statistics" element={<ProtectedRoute><MainLayout><Statistics /></MainLayout></ProtectedRoute>}/>
                <Route path="/profession" element={<ProtectedRoute><MainLayout><Profession /></MainLayout></ProtectedRoute>}/>
                <Route path="/achievements" element={<ProtectedRoute><MainLayout><Achievements /></MainLayout></ProtectedRoute>}/>
                <Route path="/activity" element={<ProtectedRoute><MainLayout><Activity /></MainLayout></ProtectedRoute>}/>
                <Route path="/mercenaries" element={<ProtectedRoute><MainLayout><Mercenaries /></MainLayout></ProtectedRoute>}/>
                <Route path="/notes" element={<ProtectedRoute><MainLayout><Notes /></MainLayout></ProtectedRoute>}/>
                <Route path="/friends" element={<ProtectedRoute><MainLayout><FriendsList /></MainLayout></ProtectedRoute>}/>
                <Route path="/mail" element={<ProtectedRoute><MainLayout><Mail /></MainLayout></ProtectedRoute>}/>
                <Route path="/account" element={<ProtectedRoute><MainLayout><Account /></MainLayout></ProtectedRoute>}/>
                <Route path="/ranking" element={<ProtectedRoute><MainLayout><Ranking /></MainLayout></ProtectedRoute>}/>
                <Route path="/deathscreen/:battleReportId" element={<ProtectedRoute><MainLayout><DeathScreen /></MainLayout></ProtectedRoute>}/>
                <Route path="/tournament" element={<ProtectedRoute><MainLayout><ViewTournaments /></MainLayout></ProtectedRoute>}/>
                <Route path="/tournament/:id" element={<ProtectedRoute><MainLayout><TournamentDetails /></MainLayout></ProtectedRoute>}/>
                <Route path="/adventures" element={<ProtectedRoute><MainLayout><AdventureList /></MainLayout></ProtectedRoute>}/>
                <Route path="/adventure/:adventure_id" element={<ProtectedRoute><MainLayout><Adventure /></MainLayout></ProtectedRoute>}/>
                <Route path="/adventure-completion" element={<ProtectedRoute><MainLayout><AdventureCompletion /></MainLayout></ProtectedRoute>}/>
                <Route path="/adventure/:adventureId/chapter/:chapterId" element={<ProtectedRoute><MainLayout><ChapterView /></MainLayout></ProtectedRoute>}/>
                <Route path="/auction" element={<ProtectedRoute><MainLayout><AuctionHouse /></MainLayout></ProtectedRoute>}/>
                <Route path="/auction/browse" element={<ProtectedRoute><MainLayout><Browse category={null} subcategory={null} searchTerm="" /></MainLayout></ProtectedRoute>}/>
                <Route path="/auction/buy-order" element={<ProtectedRoute><MainLayout><BuyOrder /></MainLayout></ProtectedRoute>}/> 
                <Route path="/auction/sell-order" element={<ProtectedRoute><MainLayout><SellOrder /></MainLayout></ProtectedRoute>}/>
                <Route path="/auction/history/:mercenaryId" element={<ProtectedRoute><MainLayout><History /></MainLayout></ProtectedRoute>}/>
                <Route path="/tavern" element={<ProtectedRoute><MainLayout><Tavern /></MainLayout></ProtectedRoute>}/>
                <Route path="/mercenary/:id" element={<ProtectedRoute><MainLayout><Profile /></MainLayout></ProtectedRoute>}/>
                <Route path="/work" element={<ProtectedRoute><MainLayout><WorkDistrict /></MainLayout></ProtectedRoute>}/>
                <Route path="/craft" element={<ProtectedRoute><MainLayout><CraftingDistrict /></MainLayout></ProtectedRoute>}/>
                <Route path="/vault" element={<ProtectedRoute><MainLayout><Vault /></MainLayout></ProtectedRoute>}/>
                <Route path="/contract-battle" element={<ProtectedRoute><MainLayout><ContractBattles /></MainLayout></ProtectedRoute>}/>

                {/* Fallback Route */}
                <Route path="*" element={<Navigate to="/home" />} />
              </Routes>
            </AuthInitializer>
          </Router>
        </MercenaryProvider>
      </SocketProvider>
    </AuthProvider>
  );
};

interface ProtectedRouteProps {
  children: JSX.Element;
  roles?: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, roles }) => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/" />;
  }

  if (roles && (!user.role || !roles.includes(user.role.name))) {
    return <Navigate to="/" />;
  }

  return children;
};

export default App;
