import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import TournamentBracket from './TournamentBracket';
import Toast from '../Toast';
import styles from './TournamentDetail.module.css';
import { useMercenary } from '../../../contexts/MercenaryContext';

interface Mercenary {
  id: number;
  name: string;
}

interface Participant {
  id: number;
  mercenary: Mercenary;
}

interface Match {
  id: number;
  team1Participants: Mercenary[];
  team2Participants: Mercenary[];
  winner_team?: number;
  round_number: number;
  battle_report?: number;
}

interface Tournament {
  id: number;
  name: string;
  type: string;
  status: string;
  num_teams: number;
  team_size: number;
  start_time: string;
  TournamentParticipants: Participant[];
  TournamentMatches: Match[];
}

const TournamentDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [tournament, setTournament] = useState<Tournament | null>(null);
  const { mercenary } = useMercenary();
  const [isParticipant, setIsParticipant] = useState(false);
  const [surrenderAt, setSurrenderAt] = useState<number>(() => {
    const savedSurrenderValue = localStorage.getItem('tournamentSurrenderAt');
    return savedSurrenderValue ? parseInt(savedSurrenderValue) : 50;
  });
  const [strategy, setStrategy] = useState<string>(() => {
    const savedStrategy = localStorage.getItem('tournamentStrategy');
    return savedStrategy || 'Normal';
  });
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  const fetchTournament = async () => {
    try {
      const response = await axios.get(`/api/tournament/${id}`, { withCredentials: true });
      setTournament(response.data);
      const participantFound = response.data.TournamentParticipants.some(
        (p: Participant) => p.mercenary && p.mercenary.id === mercenary?.id
      );
      
      setIsParticipant(participantFound);
    } catch (error) {
      displayToast('Error fetching tournament.', 'error');
      console.error('Error fetching tournament:', error);
    }
  };

  useEffect(() => {
    fetchTournament();
  }, [id, mercenary?.id]);

  useEffect(() => {
    localStorage.setItem('tournamentSurrenderAt', surrenderAt.toString());
  }, [surrenderAt]);

  useEffect(() => {
    localStorage.setItem('tournamentStrategy', strategy);
  }, [strategy]);

  const handleJoin = async () => {
    try {
      await axios.post(
        `/api/tournament/${id}/join`, 
        { mercenaryId: mercenary?.id, surrenderHP: surrenderAt / 100, strategy },
        { withCredentials: true }
      );
      await fetchTournament();
      displayToast('Successfully joined the tournament!', 'success');
    } catch (error) {
      displayToast('Failed to join the tournament.', 'error');
      console.error('Error joining tournament:', error);
    }
  };

  const handleLeave = async () => {
    try {
      await axios.post(
        `/api/tournament/${id}/leave`,
        { mercenaryId: mercenary?.id },
        { withCredentials: true }
      );
      await fetchTournament();
      displayToast('Successfully left the tournament.', 'success');
    } catch (error) {
      displayToast('Failed to leave the tournament.', 'error');
      console.error('Error leaving tournament:', error);
    }
  };

  if (!tournament) return <div>Loading...</div>;

  return (
    <div className={styles.tournamentDetailContainer}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}
      <h2 className={styles.title}>{tournament.name}</h2>
      <p>Type: {tournament.type === 'death' ? 'Death Match' : 'Normal Battle'}</p>
      <p>Status: {tournament.status}</p>
      <p>Participants: {tournament.TournamentParticipants.length} / {tournament.num_teams * tournament.team_size}</p>
      <p>Starts at: {new Date(tournament.start_time).toLocaleString()}</p>

      {tournament.status === 'scheduled' && (
        <div>
          {isParticipant ? (
            <button onClick={handleLeave} className={styles.leaveButton}>Leave Tournament</button>
          ) : (
            <div>
              <label>
                Surrender HP:
                <select
                  className={styles.surrenderAtSelect}
                  value={surrenderAt}
                  onChange={(e) => setSurrenderAt(parseInt(e.target.value))}
                >
                  {[90, 80, 70, 60, 50, 40, 30, 20, 10, 0].map(value => (
                    <option key={value} value={value}>Surrender at {value}%</option>
                  ))}
                </select>
              </label>
              <label>
                Strategy:
                <select
                  className={styles.strategySelect}
                  value={strategy}
                  onChange={(e) => setStrategy(e.target.value)}
                >
                  <option value="Normal">Normal</option>
                  <option value="Normal: Light Attacks">Normal: Light Attacks</option>
                  <option value="Normal: Heavy Attacks">Normal: Heavy Attacks</option>
                  <option value="Offensive">Offensive</option>
                  <option value="Offensive: Light Attacks">Offensive: Light Attacks</option>
                  <option value="Offensive: Heavy Attacks">Offensive: Heavy Attacks</option>
                  <option value="Defensive">Defensive</option>
                  <option value="Defensive: Light Attacks">Defensive: Light Attacks</option>
                  <option value="Defensive: Heavy Attacks">Defensive: Heavy Attacks</option>
                  <option value="Berserk">Berserk</option>
                  <option value="Berserk: Light Attacks">Berserk: Light Attacks</option>
                  <option value="Berserk: Heavy Attacks">Berserk: Heavy Attacks</option>
                </select>
              </label>
              <button onClick={handleJoin} className={styles.joinButton}>Join Tournament</button>
            </div>
          )}
        </div>
      )}
      {['locked', 'ongoing', 'completed'].includes(tournament.status) ? (
        <TournamentBracket matches={tournament.TournamentMatches} />
      ) : (
        <p>Bracket will be available once the tournament starts.</p>
      )}
    </div>
  );
};

export default TournamentDetail;
