import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from '../../Shared/Modal';
import { useMercenary } from '../../../contexts/MercenaryContext';
import styles from './ContractBattles.module.css';

const IncomingChallenges = ({ displayToast }: { displayToast: (message: string, type: 'success' | 'error') => void }) => {
  const { mercenaryId } = useMercenary();
  const [incomingChallenges, setIncomingChallenges] = useState<any[]>([]);
  const [showReady, setShowReady] = useState<boolean>(() => {
    const storedValue = localStorage.getItem('showReady');
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });
  const [minLevelIncoming, setMinLevelIncoming] = useState<number>(1);
  const [maxLevelIncoming, setMaxLevelIncoming] = useState<number>(50);
  const [showModal, setShowModal] = useState(false);
  const [selectedChallenge, setSelectedChallenge] = useState<number | null>(null);
  const [strategy, setStrategy] = useState<string>('Normal');
  const [surrenderHp, setSurrenderHp] = useState<number>(0.5);

  useEffect(() => {
    const fetchIncomingChallenges = async () => {
      try {
        const response = await axios.get(`/api/contract/incoming/${mercenaryId}`);
        setIncomingChallenges(response.data);
      } catch (error) {
        displayToast('Error fetching incoming challenges.', 'error');
      }
    };
    fetchIncomingChallenges();
  }, [mercenaryId, displayToast]);

  useEffect(() => {
    localStorage.setItem('showReady', JSON.stringify(showReady));
  }, [showReady]);

  const loadPreferences = () => {
    const preferencesKey = `preferences_${mercenaryId}`;
    const savedPreferences = localStorage.getItem(preferencesKey);

    if (savedPreferences) {
      try {
        const { preferences } = JSON.parse(savedPreferences);
        setStrategy(preferences.strategy || 'Normal');
        setSurrenderHp(preferences.surrenderHP || 0.5);
      } catch (error) {
        console.error('Failed to parse saved preferences:', error);
        setStrategy('Normal');
        setSurrenderHp(0.5);
      }
    } else {
      setStrategy('Normal');
      setSurrenderHp(0.5);
    }
  };

  const filteredChallenges = incomingChallenges
    .filter((challenge: any) => {
      const level = challenge.Sender?.level || 0;
      const isReady = challenge.senderMercenary?.ready || false;

      return (
        level >= minLevelIncoming &&
        level <= maxLevelIncoming &&
        (!showReady || isReady)
      );
    })
    .sort((a, b) => (b.Sender?.level || 0) - (a.Sender?.level || 0));

    const acceptChallenge = async () => {
        if (selectedChallenge) {
          try {
            const response = await axios.post('/api/battle/contract/accept', {
              challengeId: selectedChallenge,
              mercenaryId,
              strategy,
              surrenderHp,
            });
            const message = response.data?.message || 'Challenge accepted!';
            displayToast(message, 'success');
            setShowModal(false);
            setIncomingChallenges((prev) =>
              prev.filter((challenge) => challenge.id !== selectedChallenge)
            );
          } catch (error) {
            const errorMessage =
              (axios.isAxiosError(error) && error.response?.data?.message) || 'Failed to accept the challenge.';
            displayToast(errorMessage, 'error');
          }
        }
      };
      
      const rejectChallenge = async (challengeId: number) => {
        try {
          const response = await axios.post('/api/battle/contract/reject', { challengeId });
          const message = response.data?.message || 'Challenge rejected.';
          displayToast(message, 'success');
          setIncomingChallenges((prev) =>
            prev.filter((challenge) => challenge.id !== challengeId)
          );
        } catch (error) {
          const errorMessage =
            (axios.isAxiosError(error) && error.response?.data?.message) || 'Failed to reject the challenge.';
          displayToast(errorMessage, 'error');
        }
      };

  return (
    <div className={styles.incomingChallenges}>
      {showModal && (
        <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
          onConfirm={acceptChallenge}
          title="Accept Challenge"
          message={
            <div>
              <label>
                Strategy:
                <select value={strategy} onChange={(e) => setStrategy(e.target.value)}>
                <option value="Normal">Normal</option>
                <option value="Normal: Light Attacks">Normal: Light Attacks</option>
                <option value="Normal: Heavy Attacks">Normal: Heavy Attacks</option>
                <option value="Offensive">Offensive</option>
                <option value="Offensive: Light Attacks">Offensive: Light Attacks</option>
                <option value="Offensive: Heavy Attacks">Offensive: Heavy Attacks</option>
                <option value="Defensive">Defensive</option>
                <option value="Defensive: Light Attacks">Defensive: Light Attacks</option>
                <option value="Defensive: Heavy Attacks">Defensive: Heavy Attacks</option>
                <option value="Berserk">Berserk</option>
                <option value="Berserk: Light Attacks">Berserk: Light Attacks</option>
                <option value="Berserk: Heavy Attacks">Berserk: Heavy Attacks</option>
                </select>
              </label>
              <label>
                Surrender HP (%):
                <select
                  value={surrenderHp}
                  onChange={(e) => setSurrenderHp(parseFloat(e.target.value))}
                >
                  {[...Array(10).keys()].map((i) => (
                    <option key={i} value={i / 10}>
                      {i * 10}%
                    </option>
                  ))}
                </select>
              </label>
            </div>
          }
        />
      )}

      <h3>Incoming Challenges</h3>
      <div className={styles.filterSection}>
        <label>
          Show Ready:
          <input
            type="checkbox"
            checked={showReady}
            onChange={() => setShowReady(!showReady)}
          />
        </label>
        <label>
          Min Level:
          <input
            type="number"
            value={minLevelIncoming}
            onChange={(e) => setMinLevelIncoming(parseInt(e.target.value, 10))}
          />
        </label>
        <label>
          Max Level:
          <input
            type="number"
            value={maxLevelIncoming}
            onChange={(e) => setMaxLevelIncoming(parseInt(e.target.value, 10))}
          />
        </label>
      </div>
      <ul>
        {filteredChallenges.map((challenge) => (
          <li key={challenge.id}>
            {challenge.senderMercenary?.name} (Level {challenge.senderMercenary?.level})
            <div className={styles.buttonGroup}>
              <button
                onClick={() => {
                  setSelectedChallenge(challenge.id);
                  loadPreferences();
                  setShowModal(true);
                }}
              >
                Accept
              </button>
              <button
                onClick={() => rejectChallenge(challenge.id)}
                className={styles.rejectButton}
              >
                Reject
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default IncomingChallenges;
