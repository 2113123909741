import React from 'react';

const Mercenaries: React.FC = () => {
  return (
    <div>
      <h1>Mercenaries</h1>
      <p>This is the Mercenaries component.</p>
    </div>
  );
};

export default Mercenaries;
