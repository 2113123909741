// AuditLog.tsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './AuditLog.module.css'; // Import CSS module

interface AuditLog {
  id: number;
  action: string;
  details: string;
  createdAt: string;
  admin: { username: string };
  target: { username: string };
}

const AuditLog: React.FC = () => {
  const [logs, setLogs] = useState<AuditLog[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [filterType, setFilterType] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const fetchAuditLogs = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/admin/audit-log`, {
        params: { page, pageSize, filterType },
        withCredentials: true
      });
      setLogs(response.data.logs);
      setTotal(response.data.total);
    } catch (error) {
      console.error('Error fetching audit logs', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAuditLogs();
  }, [page, pageSize, filterType]);

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterType(e.target.value);
    setPage(1);
  };

  return (
    <div className={styles.auditLogContainer}>
      <h1>Audit Logs</h1>

      {/* Filter by Action Type */}
      <div className={styles.filterGroup}>
        <label htmlFor="filterType">Filter by Action:</label>
        <select
          id="filterType"
          value={filterType}
          onChange={handleFilterChange}
          className={styles.filterSelect}
        >
          <option value="">All</option>
          <option value="Update Display Name">Update Display Name</option>
          <option value="Remove Password">Remove Password</option>
          <option value="Update Role">Update Role</option>
          <option value="Clear Death Status">Clear Death Status</option>
        </select>
      </div>

      {/* Display Audit Logs */}
      {loading ? (
        <p>Loading logs...</p>
      ) : (
        <table className={styles.auditLogTable}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Admin</th>
              <th>Action</th>
              <th>Target</th>
              <th>Details</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log) => (
              <tr key={log.id}>
                <td>{log.id}</td>
                <td>{log.admin.username}</td>
                <td>{log.action}</td>
                <td>{log.target?.username || 'N/A'}</td>
                <td>{log.details}</td>
                <td>{new Date(log.createdAt).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Pagination */}
      <div className={styles.pagination}>
        <button onClick={() => setPage(page - 1)} disabled={page === 1}>
          Previous
        </button>
        <span>
          Page {page} of {Math.ceil(total / pageSize)}
        </span>
        <button onClick={() => setPage(page + 1)} disabled={page * pageSize >= total}>
          Next
        </button>
      </div>
    </div>
  );
};

export default AuditLog;
