import React, { useState, useEffect } from 'react';
import { useMercenary } from '../../../contexts/MercenaryContext';
import axios from 'axios';
import styles from './VaultPage.module.css';
import Toast from '../Toast';

interface VaultItem {
  id: number;
  name: string;
  quantity: number;
}

const VaultPage = () => {
  const { mercenaryId } = useMercenary();
  const [vault, setVault] = useState<{ silver: number; items: VaultItem[] } | null>(null);
  const [inventory, setInventory] = useState<VaultItem[]>([]);
  const [silver, setSilver] = useState(0);
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [itemQuantity, setItemQuantity] = useState<number>(1);
  const [actionType, setActionType] = useState<'deposit' | 'withdraw'>('deposit');
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const fetchVault = async () => {
    try {
      const response = await axios.get('/api/vault');
      setVault(response.data);
    } catch (error) {
      console.error(error);
      showToast('Failed to fetch vault details.', 'error');
    }
  };

  const fetchInventory = async () => {
    try {
      const response = await axios.get('/api/vault/inventory');
      setInventory(response.data);
    } catch (error) {
      console.error(error);
      showToast('Failed to fetch inventory items.', 'error');
    }
  };

  const handleAction = async () => {
    try {
      const endpoint = `/api/vault/${actionType}`;
      const data: any = { mercenaryId };
      if (silver > 0) data.silver = silver;
      if (selectedItem && itemQuantity > 0) {
        data.items = [{ itemId: selectedItem, quantity: itemQuantity }];
      }

      if (!data.silver && !data.items) {
        showToast('Please specify silver or items to proceed.', 'error');
        return;
      }

      await axios.post(endpoint, data);
      showToast(`${actionType === 'deposit' ? 'Deposit' : 'Withdrawal'} successful!`, 'success');
      fetchVault();
      fetchInventory();
      resetInputs();
    } catch (error: any) {
      console.error(error);
      const message =
        error.response?.data?.message ||
        `Failed to ${actionType}. Please check your input and try again.`;
      showToast(message, 'error');
    }
  };

  const resetInputs = () => {
    setSilver(0);
    setSelectedItem(null);
    setItemQuantity(1);
  };

  const showToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  useEffect(() => {
    fetchVault();
    fetchInventory();
  }, []);

  const currentItems = actionType === 'deposit' ? inventory : vault?.items || [];

  return (
    <div className={styles.vaultPage}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}
      <h1 className={styles.title}>The Glacier Vault</h1>
      <div className={styles.contentContainer}>
        <div className={styles.section}>
          <h2>Vault Silver</h2>
          <p>{vault?.silver || 0}</p>
        </div>
        <div className={styles.section}>
          <h2>Vault Items</h2>
          <ul>
            {vault?.items.map((item) => (
              <li key={item.id}>
                {item.name} - {item.quantity}
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.section}>
          <h2>Inventory Items</h2>
          <ul>
            {inventory.map((item) => (
              <li key={item.id}>
                {item.name} - {item.quantity}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.actionContainer}>
        <h3>{actionType.charAt(0).toUpperCase() + actionType.slice(1)}</h3>
        <div className={styles.inputGroup}>
          <label>Silver</label>
          <input
            type="number"
            value={silver}
            onChange={(e) => setSilver(parseInt(e.target.value, 10))}
            placeholder="Enter amount"
          />
        </div>
        <div className={styles.inputGroup}>
          <label>Items</label>
          <select
            value={selectedItem || ''}
            onChange={(e) => {
              const itemId = parseInt(e.target.value, 10);
              setSelectedItem(itemId);
              const selected = currentItems.find((item) => item.id === itemId);
              setItemQuantity(selected?.quantity != null && selected.quantity > 1 ? 1 : selected?.quantity || 0);
            }}
          >
            <option value="">Select Item</option>
            {currentItems.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name} ({item.quantity})
              </option>
            ))}
          </select>
          {selectedItem && (() => {
            const selectedItemDetails = currentItems.find((item) => item.id === selectedItem);
            return selectedItemDetails?.quantity !== undefined && selectedItemDetails.quantity > 1;
          })() && (
            <select
              className={styles.itemQuantity}
              value={itemQuantity}
              onChange={(e) => setItemQuantity(parseInt(e.target.value, 10))}
            >
              {Array.from(
                {
                  length:
                    currentItems.find((item) => item.id === selectedItem)?.quantity || 1,
                },
                (_, i) => i + 1
              ).map((qty) => (
                <option key={qty} value={qty}>
                  {qty}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className={styles.buttonGroup}>
          <button onClick={handleAction}>{actionType.charAt(0).toUpperCase() + actionType.slice(1)}</button>
          <button onClick={() => setActionType(actionType === 'deposit' ? 'withdraw' : 'deposit')}>
            Switch to {actionType === 'deposit' ? 'Withdraw' : 'Deposit'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VaultPage;
