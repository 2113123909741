import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSocket } from '../../contexts/SocketContext';
import { useAuth } from '../../contexts/AuthContext';
import { useMercenary } from '../../contexts/MercenaryContext';
import axios from 'axios';
import Book from './Book';
import styles from './Home.module.css';

const Home: React.FC = () => {
  const { socket } = useSocket();
  const { user } = useAuth();
  const { mercenary } = useMercenary();

  useEffect(() => {
    // Existing socket and mercenary data handling
    if (socket && mercenary?.id) {
      socket.emit('join_room', user.id);
      axios.get(`/api/mercenary/${mercenary.id}`, { withCredentials: true })
        .catch(error => console.error('Error fetching mercenary data:', error));
    }

  //   // Dynamically load the Jira Issue Collector script
  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.src = 'https://njordheim.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/g2slup/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=39607082';
  //   script.async = true;
  //   document.body.appendChild(script);

  //   // Cleanup function to remove the script when the component unmounts
  //   return () => {
  //     document.body.removeChild(script);
  //   };
   }, [socket, mercenary, user]);

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <div className={styles.homeContainer}>
      <h2 className={styles.welcomeTitle}>Welcome to Njordheim, {user.displayname ? user.displayname : user.username}!</h2>
      <div className={styles.bookContainer}>
        <Book />
        {!mercenary && (
          <Link to="/create-mercenary" className={styles.createMercenaryButton}>
            Embark on Your Journey
          </Link>
        )}
      </div>
    </div>
  );
};

export default Home;
