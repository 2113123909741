import React, { useState } from 'react';
import axios from 'axios';
import Toast from '../Toast';
import styles from './DiceGame.module.css';

interface DiceGameProps {
  mercenaryId: number;
  initialSilver: number;
}

const DiceGame: React.FC<DiceGameProps> = ({ mercenaryId, initialSilver }) => {
  const [bet, setBet] = useState(0);
  const [silver, setSilver] = useState(initialSilver);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  const handlePlay = async () => {
    if (bet <= 0 || bet > silver) {
      displayToast('Please enter a valid bet within your silver amount.', 'error');
      return;
    }

    try {
      const response = await axios.post('/api/tavern/play-dice', {
        mercenaryId,
        bet,
      });

      displayToast(response.data.message, 'success');
      setSilver(response.data.newSilver);
    } catch (error) {
      const errorMessage = (error as any).response?.data?.message || 'Failed to play dice game';
      displayToast(errorMessage, 'error');
    }
  };

  return (
    <div className={styles.diceGame}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}
      <h3>Dice Game</h3>
      <p>Bet silver and try to roll exactly 7 with two dice to win big!</p>
      <div className={styles.betControls}>
        <input
          type="number"
          min="1"
          max={silver}
          value={bet > 0 ? bet : ''}
          onChange={(e) => setBet(Number(e.target.value))}
          placeholder="Enter your bet"
          className={styles.plainInput}
        />
        <button onClick={handlePlay} className={styles.rollButton}>Roll Dice</button>
      </div>
      <p>Your silver: {silver}</p>
    </div>
  );
};

export default DiceGame;
