import React, { useState, useEffect, useRef } from 'react';
import styles from './ChatBox.module.css';
import EmojiPicker from './EmojiPicker';
import { useAuth } from '../../../contexts/AuthContext';

interface ChatMessage {
  id: number;
  userName: string;
  message: string;
  createdAt: string;
}

interface ChatBoxProps {
  chatMessages: ChatMessage[];
  lobbyId: string;
  socket: any;
}

const ChatBox: React.FC<ChatBoxProps> = ({ chatMessages, lobbyId, socket }) => {
  const { user } = useAuth();
  const [message, setMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [messages, setMessages] = useState<ChatMessage[]>(chatMessages);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const toggleEmojiPicker = () => setShowEmojiPicker((prev) => !prev);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch(`/api/chat/${lobbyId}/messages`);
        const history = await response.json();
        setMessages(history);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();

    socket.on('new_message', (newMessage: ChatMessage) => {
      setMessages((prev) => [...prev, newMessage]);
    });

    return () => {
      socket.off('new_message');
    };
  }, [lobbyId, socket]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = () => {
    if (message.trim() === '') return;

    socket.emit('chat_message_lobby', {
      lobbyId,
      message,
      userId: user.id,
      userName: user.username,
    });

    setMessage('');
  };

  const validEmojis = Array.from({ length: 50 }, (_, i) => `${i + 1}`);

  const parseMessage = (msg: ChatMessage) => {
    const parts = msg.message.split(/(:[a-zA-Z0-9_]+:)/);
    return (
      <>
        <span className={styles.timestamp}>[{msg.createdAt}]</span>{' '}
        <span className={styles.userName}>{msg.userName}</span>:{" "}
        {parts.map((part, index) => {
          if (part.match(/:[a-zA-Z0-9_]+:/)) {
            const emojiName = part.slice(1, -1);
            if (validEmojis.includes(emojiName)) {
              return (
                <img
                  key={index}
                  src={`/api/emojis/${emojiName}`}
                  alt={emojiName}
                  className={`${styles.emoji}`}
                />
              );
            }
          }
          return part;
        })}
      </>
    );
  };

  return (
    <div className={styles.chatBox}>
      <div className={styles.chatHistory}>
        {messages.map((msg) => (
          <div key={msg.id} className={styles.chatMessage}>
            {parseMessage(msg)}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Emoji Picker Overlay */}
      {showEmojiPicker && (
        <div className={styles.emojiPickerOverlay}>
          <EmojiPicker
            onSelect={(emoji) => {
              setMessage((prev) => prev + `:${emoji}:`);
              setShowEmojiPicker(false);
            }}
          />
        </div>
      )}

      <div className={styles.chatInputContainer}>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message..."
          className={styles.chatInput}
        />
        <button onClick={toggleEmojiPicker} className={styles.emojiToggleButton}>
          Emoji
        </button>
        <button onClick={handleSendMessage} className={styles.sendButton}>
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatBox;
