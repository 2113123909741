import React, { useState } from 'react';
import axios from 'axios';
import styles from './CreateItem.module.css';

const CreateItem: React.FC = () => {
  const [itemType, setItemType] = useState<string>('');
  const [itemData, setItemData] = useState<Record<string, any>>({});

  const handleItemTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemType = e.target.value;
    let defaultSlot = '';

    switch (newItemType) {
      case 'Weapon':
      case 'Armor':
      case 'Accessory':
        defaultSlot = '';
        break;
      case 'Shield':
        defaultSlot = 'offHand';
        break;
      case 'Material':
        defaultSlot = 'material';
        break;
      case 'Potion':
        defaultSlot = 'potion';
        break;
      case 'Enchant':
        defaultSlot = 'enchant';
        break;
      case 'Knowledge':
        defaultSlot = 'knowledge';
        break;
      default:
        break;
    }

    setItemType(newItemType);
    setItemData({ itemCategory: newItemType, slot: defaultSlot });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setItemData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setItemData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    // Perform absorption-specific validation only if item type is Armor
    if (itemType === "Armor") {
      if (!itemData.absorption && !itemData.absorptionPercentage) {
        alert("Please provide either a flat absorption value or an absorption percentage for armor items.");
        return;
      }
  
      if (itemData.absorption && itemData.absorptionPercentage) {
        alert("Please provide only one type of absorption: either flat or percentage for armor items.");
        return;
      }
    }
  
    try {
      const formData = { ...itemData };
  
      // Clear irrelevant absorption fields for armor items only
      if (itemType === "Armor") {
        if (formData.absorption) {
          formData.absorptionPercentage = null;
          formData.minAbsorption = null;
          formData.maxAbsorption = null;
        } else if (formData.absorptionPercentage) {
          formData.absorption = null;
          formData.minAbsorption = formData.minAbsorption || null;
          formData.maxAbsorption = formData.maxAbsorption || null;
        }
      }
  
      const endpoint = '/api/admin/create-item';
      await axios.post(endpoint, formData, { withCredentials: true });
      alert(`${itemType} created successfully!`);
      setItemData({});
      setItemType('');
    } catch (error) {
      console.error('Error creating item:', error);
      alert(`Failed to create ${itemType}.`);
    }
  };

  const renderRaceOptions = () => (
    <>
      <option value="All">All</option>
      <option value="Elf">Elf</option>
      <option value="Dwarf">Dwarf</option>
      <option value="Human">Human</option>
      <option value="Goblin">Goblin</option>
      <option value="Orc">Orc</option>
      <option value="Troll">Troll</option>
    </>
  );

  const renderTitleOptions = () => (
    <>
      <option value="Normal">Normal</option>
      <option value="Legend">Legend</option>
      <option value="Dishonorable">Dishonorable</option>
      <option value="Reputable">Reputable</option>
      <option value="Disreputable">Disreputable</option>
    </>
  );

  const renderTextField = (label: string, name: string, required: boolean = true, type: string = 'text') => (
    <div className={styles.formGroup}>
      <label>{label}:</label>
      <input type={type} name={name} value={itemData[name] || ''} onChange={handleInputChange} required={required} />
    </div>
  );

  const renderSelectField = (label: string, name: string, options: string[], required: boolean = true) => (
    <div className={styles.formGroup}>
      <label>{label}:</label>
      <select name={name} value={itemData[name] || ''} onChange={handleInputChange} required={required}>
        <option value="">Select {label}</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );

  const renderWeaponForm = () => (
    <>
      {renderSelectField('Weapon Type', 'weaponType', ['Axe', 'Sword', 'Mace', 'Staff', 'Dagger', 'Chain'])}
      {renderSelectField('Slot', 'slot', ['oneHand', 'twoHand'])}
      {renderTextField('Min Damage', 'minDamage', true, 'number')}
      {renderTextField('Max Damage', 'maxDamage', true, 'number')}
      {renderTextField('Damage Cap', 'dmgCap', true, 'number')}
      {renderTextField('Crit Chance', 'critChance', true, 'number')}
      {renderTextField('Crit Damage', 'critDamage', true, 'number')}
      {renderTextField('Break Value', 'breakValue', true, 'number')}
      {renderTextField('Skill Recommendation', 'skillRecommendation', true, 'number')}
      {renderTextField('Strength Requirement', 'strengthRequirement', true, 'number')}
      {renderTextField('Actions Per Round', 'actionsPerRound', true, 'number')}
      {itemData.weaponType === 'Staff' && (
        <>
          {renderTextField('Momentum Chance', 'momentumChance', true, 'number')}
          {renderTextField('Momentum Multiplier', 'momentumMultiplier', true, 'number')}
        </>
      )}
      <div className={styles.formGroup}>
        <label>Can Equip In Off Hand:</label>
        <input type="checkbox" name="canEquipInOffHand" checked={itemData.canEquipInOffHand || false} onChange={handleCheckboxChange} />
      </div>      
      {renderTextField('Bonuses', 'bonuses', false, 'text')}
    </>
  );

  const renderArmorForm = () => (
    <>
      {renderSelectField('Slot', 'slot', ['Head', 'Torso', 'Shoulders', 'Hands', 'Legs', 'Feet'])}
      {renderTextField('Armor Type', 'armorType', false, 'text')}
      {renderTextField('Absorption (flat value)', 'absorption', false, 'number')}
      {renderTextField('Absorption Percentage (%)', 'absorptionPercentage', false, 'number')}
      {renderTextField('Min Absorption (for % only)', 'minAbsorption', false, 'number')}
      {renderTextField('Max Absorption (for % only)', 'maxAbsorption', false, 'number')}
      {renderTextField('Bonuses', 'bonuses', false, 'text')}
    </>
  );

  const renderShieldForm = () => (
    <>
      {renderTextField('Block Value', 'blockValue', true, 'number')}
      {renderTextField('Break Value', 'breakValue', true, 'number')}
      {renderTextField('Slot', 'slot', false, 'text')}
      {renderTextField('Skill Recommendation', 'skillRecommendation', true, 'number')}
      {renderTextField('Bonuses', 'bonuses', false, 'text')}
    </>
  );

  const renderAccessoryForm = () => (
    <>
      {renderSelectField('Slot', 'slot', ['cloak', 'neck', 'ring', 'amulet', 'armring'])}
      {renderTextField('Bonuses', 'bonuses', false, 'text')}
    </>
  );

  const renderMaterialForm = () => (
    <>
      {renderTextField('Material Type', 'materialType')}
      {renderTextField('Slot', 'slot', false, 'text')}
      {renderTextField('Bonuses', 'bonuses', false, 'text')}
    </>
  );

  const renderPotionForm = () => (
    <>
      {renderTextField('Effect', 'potionEffect')}
      {renderTextField('Duration (minutes)', 'duration', true, 'number')}
      {renderTextField('Slot', 'slot', false, 'text')}
      {renderTextField('Bonuses', 'bonuses', false, 'text')}
    </>
  );

  const renderEnchantForm = () => (
    <>
      {renderTextField('Enchant Effect', 'enchantEffect')}
      {renderTextField('Slot', 'slot', false, 'text')}
      {renderTextField('Bonuses', 'bonuses', false, 'text')}
    </>
  );

  const renderKnowledgeForm = () => (
    <>
      {renderSelectField('Knowledge Type', 'knowledgeType', ['Scroll', 'Tome', 'Book'])}
      {renderTextField('Effect Description', 'effectDescription')}
      {renderTextField('Bonuses', 'bonuses', false, 'text')}
    </>
  );

  const renderForm = () => (
    <>
      {renderTextField('Name', 'name')}
      <div className={styles.formGroup}>
        <label>Description:</label>
        <textarea name="description" value={itemData.description || ''} onChange={handleInputChange} required />
      </div>
      {renderTextField('Image URL', 'img')}
      {renderTextField('Weight', 'weight', true, 'number')}
      {renderTextField('Price', 'value', true, 'number')}
      {renderTextField('Required Level', 'requiredLevel', true, 'number')}
      {renderSelectField('Item Race', 'itemRace', ['All', 'Red Blood', 'Elf', 'Dwarf', 'Human', 'Black Blood', 'Goblin', 'Orc', 'Troll'])}
      {renderSelectField('Item Title', 'itemTitle', ['Normal', 'Legend', 'Dishonorable', 'Reputable', 'Disreputable'])}

      {itemType === 'Weapon' && renderWeaponForm()}
      {itemType === 'Armor' && renderArmorForm()}
      {itemType === 'Shield' && renderShieldForm()}
      {itemType === 'Accessory' && renderAccessoryForm()}
      {itemType === 'Material' && renderMaterialForm()}
      {itemType === 'Potion' && renderPotionForm()}
      {itemType === 'Enchant' && renderEnchantForm()}
      {itemType === 'Knowledge' && renderKnowledgeForm()}
    </>
  );

  return (
    <div className={styles.createItemContainer}>
      <h1>Create Item</h1>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label>Item Type:</label>
          <select value={itemType} onChange={handleItemTypeChange} required>
            <option value="">Select an item type</option>
            <option value="Weapon">Weapon</option>
            <option value="Armor">Armor</option>
            <option value="Shield">Shield</option>
            <option value="Accessory">Accessory</option>
            <option value="Material">Material</option>
            <option value="Potion">Potion</option>
            <option value="Enchant">Enchant</option>
            <option value="Knowledge">Knowledge</option>
          </select>
        </div>
        {renderForm()}
        <button type="submit" className={styles.submitButton}>Create {itemType}</button>
      </form>
    </div>
  );
};

export default CreateItem;
