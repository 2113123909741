import React, { useState, useEffect } from 'react';
import CreateItem from './CreateItem';
import EditItem from './EditItem';
import EditBeast from './BeastEditor';
import BlueprintEditor from './BlueprintEditor';
import styles from './WorldEditor.module.css';
import axios from 'axios';

const WorldEditor: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>('CreateItem');
  const [selectedBlueprintId, setSelectedBlueprintId] = useState<number | null>(null);
  const [blueprints, setBlueprints] = useState<{ id: number; name: string }[]>([]);

  useEffect(() => {
    if (selectedTab === 'EditBlueprint') {
      axios.get('/api/admin/blueprints')
        .then((response) => setBlueprints(response.data))
        .catch((error) => console.error('Error fetching blueprints:', error));
    }
  }, [selectedTab]);

  const renderTabContent = () => {
    switch (selectedTab) {
      case 'CreateItem':
        return <CreateItem />;
      case 'EditItem':
        return <EditItem />;
      case 'CreateBeast':
        return <EditBeast mode="create" />;
      case 'EditBeast':
        return <EditBeast mode="edit" />;
      case 'CreateBlueprint':
        return <BlueprintEditor mode="create" />;
      case 'EditBlueprint':
        return selectedBlueprintId ? (
          <BlueprintEditor mode="edit" blueprintId={selectedBlueprintId} />
        ) : (
          <div>
            <p>Please select a blueprint to edit:</p>
            <select onChange={(e) => setSelectedBlueprintId(Number(e.target.value))} value={selectedBlueprintId || ''}>
              <option value="" disabled>Select a blueprint</option>
              {blueprints.map((blueprint) => (
                <option key={blueprint.id} value={blueprint.id}>
                  {blueprint.name}
                </option>
              ))}
            </select>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.worldEditorContainer}>
      <h1 className={styles.title}>World Editor</h1>
      <div className={styles.tabs}>
        {[
          { label: 'Create Item', key: 'CreateItem' },
          { label: 'Edit Item', key: 'EditItem' },
          { label: 'Create Beast', key: 'CreateBeast' },
          { label: 'Edit Beast', key: 'EditBeast' },
          { label: 'Create Blueprint', key: 'CreateBlueprint' },
          { label: 'Edit Blueprint', key: 'EditBlueprint' },
        ].map(({ label, key }) => (
          <button
            key={key}
            onClick={() => setSelectedTab(key)}
            className={`${styles.tabButton} ${selectedTab === key ? styles.activeTab : ''}`}
          >
            {label}
          </button>
        ))}
      </div>
      <div className={styles.tabContent}>{renderTabContent()}</div>
    </div>
  );
};

export default WorldEditor;
