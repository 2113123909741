import React, { createContext, useState, useEffect, useContext, ReactNode, useCallback } from 'react';
import axios from 'axios';

interface Mercenary {
  id: number;
  name: string;
  level: number;
  race: string;
  sex: string;
  avatar: number;
}

interface MercenaryContextProps {
  mercenaries: any[];
  mercenary: any | null;
  mercenaryId: number | null;
  fetchUserMercenaries: () => Promise<{ mercenaries: Mercenary[]; canCreate: boolean, isPremiumOrHigher: boolean } | undefined>;
  switchMercenary: (mercenary: any) => void;
  createMercenary: (mercenaryData: any) => Promise<void>;
  deleteMercenary: (mercenaryId: number) => Promise<void>;
  setMercenary: React.Dispatch<React.SetStateAction<any | null>>;
  setMercenaryId: (id: number | null) => void;
}

const MercenaryContext = createContext<MercenaryContextProps | undefined>(undefined);

interface MercenaryProviderProps {
  children: ReactNode;
}

export const MercenaryProvider: React.FC<MercenaryProviderProps> = ({ children }) => {
  const [mercenaries, setMercenaries] = useState<any[]>([]);
  const [mercenary, setMercenary] = useState<any | null>(null);
  const [mercenaryId, setMercenaryId] = useState<number | null>(() => {
    const storedId = localStorage.getItem('mercenaryId');
    return storedId ? parseInt(storedId, 10) : null;
  });

  useEffect(() => {
    if (mercenaryId !== null) {
      localStorage.setItem('mercenaryId', mercenaryId.toString());
    } else {
      localStorage.removeItem('mercenaryId');
    }
  }, [mercenaryId]);

  const fetchUserMercenaries = useCallback(async (): Promise<{ mercenaries: Mercenary[]; canCreate: boolean; isPremiumOrHigher: boolean } | undefined> => {
    try {
      const response = await axios.get('/api/mercenary/all', { withCredentials: true });
      const data = response.data;
      setMercenaries(data.mercenaries);
      return {
        mercenaries: data.mercenaries,
        canCreate: data.canCreate,
        isPremiumOrHigher: data.isPremiumOrHigher,
      };
    } catch (error) {
      console.error('Failed to fetch mercenaries:', error);
      return undefined;
    }
  }, []);

  const switchMercenary = async (selectedMercenary: Mercenary) => {
    try {
      setMercenaryId(selectedMercenary.id);
      const response = await axios.get(`/api/mercenary/set-active/${selectedMercenary.id}`, { withCredentials: true });
      const fullMercenaryData = response.data;
      setMercenary(fullMercenaryData);
    } catch (error) {
      console.error('Failed to fetch mercenary data:', error);
    }
  };

  const createMercenary = async (mercenaryData: any) => {
    try {
      const response = await axios.post('/api/mercenary', mercenaryData, { withCredentials: true });
      setMercenaries([...mercenaries, response.data]);
      switchMercenary(response.data);
    } catch (error) {
      console.error("Failed to create mercenary:", error);
    }
  };

  const deleteMercenary = async (mercenaryId: number) => {
    try {
      await axios.delete(`/api/mercenary/${mercenaryId}`, { withCredentials: true });
      setMercenaries(mercenaries.filter(g => g.id !== mercenaryId));
      if (mercenaryId === mercenaryId) {
        switchMercenary(mercenaries[0]);
      }
    } catch (error) {
      console.error("Failed to delete mercenary:", error);
    }
  };

  return (
    <MercenaryContext.Provider value={{ mercenaries, mercenary, mercenaryId, fetchUserMercenaries, switchMercenary, createMercenary, deleteMercenary, setMercenary, setMercenaryId }}>
      {children}
    </MercenaryContext.Provider>
  );
};

export const useMercenary = () => {
  const context = useContext(MercenaryContext);
  if (!context) {
    throw new Error('useMercenary must be used within a MercenaryProvider');
  }
  return context;
};
