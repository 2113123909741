import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './BlueprintEditor.module.css';

interface ComponentItem {
  itemId: number;
  name: string;
  quantity: number;
}

interface BlueprintData {
  name: string;
  description: string;
  requiredProfessionId: number | null;
  requiredLevel: number;
  roundsToCraft: number;
  isRare: boolean;
  outputItemId: number | null;
  requiredRace: string[];
  components: ComponentItem[];
}

const allRaces = ['Dwarf', 'Elf', 'Human', 'Goblin', 'Orc', 'Troll'];

const initialBlueprintData: BlueprintData = {
  name: '',
  description: '',
  requiredProfessionId: null,
  requiredLevel: 1,
  roundsToCraft: 10,
  isRare: false,
  outputItemId: null,
  requiredRace: allRaces,
  components: [],
};

const BlueprintEditor: React.FC<{ mode: 'create' | 'edit'; blueprintId?: number }> = ({ mode, blueprintId }) => {
  const [blueprintData, setBlueprintData] = useState<BlueprintData>(initialBlueprintData);
  const [selectedRaceOption, setSelectedRaceOption] = useState<string>('All');
  const [outputSearchTerm, setOutputSearchTerm] = useState('');
  const [outputSearchResults, setOutputSearchResults] = useState<Item[]>([]);
  const [componentString, setComponentString] = useState(''); // New input for the comma-separated component string

  interface Profession {
    id: number;
    name: string;
  }

  const [professions, setProfessions] = useState<Profession[]>([]);
  interface Item {
    id: number;
    name: string;
  }

  const [items, setItems] = useState<Item[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<Item[]>([]);

  const raceOptions = ['All', 'Red Blood', 'Dwarf', 'Elf', 'Human', 'Black Blood', 'Goblin', 'Orc', 'Troll'];

  useEffect(() => {
    axios.get('/api/admin/professions').then((response) => setProfessions(response.data));
    axios.get('/api/admin/items').then((response) => setItems(response.data));

    if (mode === 'edit' && blueprintId) {
      axios.get(`/api/admin/blueprints/${blueprintId}`).then((response) => {
        const data = response.data;
        setBlueprintData({
          ...data,
          requiredProfessionId: data.requiredProfession,
          outputItemId: data.itemId,
          requiredRace: Array.isArray(data.requiredRace) ? data.requiredRace : allRaces,
          components: typeof data.components === 'string' ? JSON.parse(data.components) : data.components || [],
        });
        updateSelectedRaceOption(data.requiredRace);
      });
    } else if (mode === 'create') {
      setBlueprintData(initialBlueprintData);
      setSelectedRaceOption('All');
    }
  }, [mode, blueprintId]);

  const updateSelectedRaceOption = (requiredRace: string[]) => {
    if (requiredRace.length === allRaces.length && requiredRace.every(r => allRaces.includes(r))) {
      setSelectedRaceOption('All');
    } else if (requiredRace.length === 3 && requiredRace.every(r => ['Dwarf', 'Elf', 'Human'].includes(r))) {
      setSelectedRaceOption('Red Blood');
    } else if (requiredRace.length === 3 && requiredRace.every(r => ['Goblin', 'Orc', 'Troll'].includes(r))) {
      setSelectedRaceOption('Black Blood');
    } else if (requiredRace.length === 1) {
      setSelectedRaceOption(requiredRace[0]);
    } else {
      setSelectedRaceOption('');
    }
  };

  const handleRaceRequirementChange = (selectedRace: string) => {
    let allowedRaces: string[];

    if (selectedRace === 'All') {
      allowedRaces = allRaces;
    } else if (selectedRace === 'Red Blood') {
      allowedRaces = ['Dwarf', 'Elf', 'Human'];
    } else if (selectedRace === 'Black Blood') {
      allowedRaces = ['Goblin', 'Orc', 'Troll'];
    } else {
      allowedRaces = [selectedRace];
    }

    setBlueprintData({ ...blueprintData, requiredRace: allowedRaces });
    setSelectedRaceOption(selectedRace);
  };

  const handleSearch = (query: string) => {
    setSearchTerm(query);
    if (query) {
      axios.get(`/api/admin/items/search?term=${query}`).then((response) => setSearchResults(response.data));
    } else {
      setSearchResults([]);
    }
  };

  const handleOutputSearch = (query: string) => {
    setOutputSearchTerm(query);
    if (query) {
      const filteredItems = items.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      setOutputSearchResults(filteredItems);
    } else {
      setOutputSearchResults([]);
    }
  };

  const handleAddComponent = (itemId: number, name: string, quantity: number) => {
    setBlueprintData({
      ...blueprintData,
      components: [...blueprintData.components, { itemId, name, quantity }],
    });
    setSearchTerm('');
    setSearchResults([]);
  };

  const handleComponentStringAdd = () => {
    const parsedComponents = componentString.split(',').map((comp) => {
      const [quantity, ...nameParts] = comp.trim().split(' ');
      const name = nameParts.join(' ');
      return { quantity: parseInt(quantity), name };
    });
  
    const componentsToAdd = parsedComponents.map(({ quantity, name }) => {
      const item = items.find((item) => item.name.toLowerCase() === name.toLowerCase());
      if (item && !isNaN(quantity)) {
        return { itemId: item.id, name: item.name, quantity };
      } else {
        console.warn(`Component not found or quantity invalid for: ${name}`);
        return null;
      }
    }).filter((component) => component !== null);
  
    setBlueprintData((prevData) => ({
      ...prevData,
      components: [...prevData.components, ...componentsToAdd as ComponentItem[]],
    }));
  
    setComponentString('');
  };

  const handleSaveBlueprint = () => {
    const endpoint = mode === 'create' ? '/api/admin/blueprints/create' : `/api/admin/blueprints/${blueprintId}/edit`;
    const method = mode === 'create' ? 'post' : 'put';
  
    axios({
      method,
      url: endpoint,
      data: blueprintData,
    })
      .then(() => {
        alert(`${mode === 'create' ? 'Created' : 'Updated'} blueprint successfully.`);
        if (mode === 'create') {
          setBlueprintData((prevData) => ({
            ...prevData,
            name: '',
            description: '',
            outputItemId: null,
            requiredRace: allRaces,
            components: [],
          }));
          setSelectedRaceOption('All');
        }
      })
      .catch((error) => console.error('Error saving blueprint:', error));
  };

  return (
    <div className={styles.blueprintEditor}>
      <h2>{mode === 'create' ? 'Create Blueprint' : 'Edit Blueprint'}</h2>

      {/* Basic Information */}
      <label>Name:</label>
      <input
        type="text"
        value={blueprintData.name}
        onChange={(e) => setBlueprintData({ ...blueprintData, name: e.target.value })}
      />

      <label>Description:</label>
      <textarea
        value={blueprintData.description}
        onChange={(e) => setBlueprintData({ ...blueprintData, description: e.target.value })}
      />

      <label>Profession:</label>
      <select
        value={blueprintData.requiredProfessionId || ''}
        onChange={(e) => setBlueprintData({ ...blueprintData, requiredProfessionId: parseInt(e.target.value) })}
      >
        <option value="" disabled>Select Profession</option>
        {professions.map((profession) => (
          <option key={profession.id} value={profession.id}>
            {profession.name}
          </option>
        ))}
      </select>

      <label>Required Level:</label>
      <input
        type="number"
        value={blueprintData.requiredLevel}
        onChange={(e) => setBlueprintData({ ...blueprintData, requiredLevel: parseInt(e.target.value) })}
      />

      <label>Rounds to Craft:</label>
      <input
        type="number"
        value={blueprintData.roundsToCraft}
        onChange={(e) => setBlueprintData({ ...blueprintData, roundsToCraft: parseInt(e.target.value) })}
      />

      <label>Is Rare:</label>
      <input
        type="checkbox"
        checked={blueprintData.isRare}
        onChange={(e) => setBlueprintData({ ...blueprintData, isRare: e.target.checked })}
      />

      {/* Output Item with Search Filter */}
      <label>Output Item:</label>
      <input
        type="text"
        placeholder="Search output item..."
        value={outputSearchTerm}
        onChange={(e) => handleOutputSearch(e.target.value)}
      />
      {outputSearchResults.length > 0 && (
        <div className={styles.searchResults}>
          {outputSearchResults.map((item) => (
            <div
              key={item.id}
              onClick={() => {
                setBlueprintData({ ...blueprintData, outputItemId: item.id });
                setOutputSearchTerm('');
                setOutputSearchResults([]);
              }}
            >
              {item.name}
            </div>
          ))}
        </div>
      )}
      {blueprintData.outputItemId && (
        <div>Selected Item: {items.find((item) => item.id === blueprintData.outputItemId)?.name}</div>
      )}

      {/* Race Requirement */}
      <label>Race Requirement:</label>
      <select
        value={selectedRaceOption}
        onChange={(e) => handleRaceRequirementChange(e.target.value)}
      >
        {raceOptions.map((race) => (
          <option key={race} value={race}>
            {race}
          </option>
        ))}
      </select>

      {/* Automatic Component String Input */}
      <label>Add Components (Comma-separated):</label>
      <input
        type="text"
        placeholder="e.g., 1 Adamantium, 2 Black Walnut, 3 Iron"
        value={componentString}
        onChange={(e) => setComponentString(e.target.value)}
      />
      <button onClick={handleComponentStringAdd}>Add Components</button>

      {/* Components Section */}
      <div className={styles.componentsSection}>
        <h3>Components</h3>
        <input
          type="text"
          placeholder="Search components..."
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
        />
        {searchResults.length > 0 && (
          <div className={styles.searchResults}>
            {searchResults.map((item) => (
              <div
                key={item.id}
                onClick={() => handleAddComponent(item.id, item.name, 1)}
              >
                {item.name}
              </div>
            ))}
          </div>
        )}

        <ul>
          {blueprintData.components.map((component, index) => (
            <li key={index}>
              <span>{component.name} - Quantity: </span>
              <input
                type="number"
                value={component.quantity}
                onChange={(e) =>
                  setBlueprintData({
                    ...blueprintData,
                    components: blueprintData.components.map((comp, idx) =>
                      idx === index ? { ...comp, quantity: parseInt(e.target.value) } : comp
                    ),
                  })
                }
              />
              <button
                onClick={() =>
                  setBlueprintData({
                    ...blueprintData,
                    components: blueprintData.components.filter((_, idx) => idx !== index),
                  })
                }
              >
                Remove
              </button>
            </li>
          ))}
        </ul>
      </div>

      <button onClick={handleSaveBlueprint}>Save Blueprint</button>
    </div>
  );
};

export default BlueprintEditor;
