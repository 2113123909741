import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import io from 'socket.io-client';
import Toast from '../Toast';
import LobbyDetails from './LobbyDetails';
import TeamList from './TeamList';
import ChatBox from './ChatBox';
import ActionButtons from './ActionButtons';
import Preferences from './Preferences';
import { useMercenary } from '../../../contexts/MercenaryContext';
import { useAuth } from '../../../contexts/AuthContext';
import styles from './LobbyView.module.css';

const socket = io('https://njordheim.com');

interface TeamMember {
  id: number;
  name: string;
  level: number;
}

interface Lobby {
  id: number;
  creatorId: number;
  name: string;
  minLevel: number;
  maxLevel: number;
  maxPlayers: number;
  randomTeams: boolean;
  pve: boolean;
  matchType: string;
  teamOne: TeamMember[];
  teamTwo: TeamMember[];
}

const LobbyView: React.FC = () => {
  const { lobbyId } = useParams<{ lobbyId: string }>();
  const navigate = useNavigate();
  const { mercenary } = useMercenary();
  const { user } = useAuth();

  const [lobby, setLobby] = useState<Lobby | null>(null);
  const [chatMessages, setChatMessages] = useState<any[]>([]);
  const [alreadyInLobby, setAlreadyInLobby] = useState(false);
  const [isInAnyLobby, setIsInAnyLobby] = useState(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchParticipation = async () => {
      try {
        const response = await axios.get('/api/team-battle/participation', { withCredentials: true });
        setIsInAnyLobby(response.data.inLobby);
      } catch (error) {
        console.error('Error fetching participation:', error);
      }
    };

    fetchParticipation();
  }
  , []);

  useEffect(() => {
    const fetchLobbyData = async () => {
      setIsLoading(true);
      try {
        const lobbyResponse = await axios.get(`/api/team-battle/lobbies/${lobbyId}`, { withCredentials: true });
        const fetchedLobby = lobbyResponse.data;

        setLobby(fetchedLobby);

        const isUserInLobby =
          fetchedLobby.teamOne.some((member: TeamMember) => member.id === mercenary.id) ||
          fetchedLobby.teamTwo.some((member: TeamMember) => member.id === mercenary.id);
        setAlreadyInLobby(isUserInLobby);

        const chatResponse = await axios.get(`/api/team-battle/lobbies/${lobbyId}/chat`, { withCredentials: true });
        setChatMessages(chatResponse.data);
      } catch (error) {
        console.error('Error fetching lobby data:', error);
        setToastMessage('Failed to fetch lobby data.');
        setToastType('error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchLobbyData();

    socket.emit('join_lobby', { lobbyId });

    socket.on('new_message', (message) => {
      setChatMessages((prev) => [...prev, message]);
    });

    socket.on('lobby_update', (updatedLobby) => {
      setLobby(updatedLobby);
      const isUserInLobby =
        updatedLobby.teamOne.some((member: TeamMember) => member.id === mercenary.id) ||
        updatedLobby.teamTwo.some((member: TeamMember) => member.id === mercenary.id);
      setAlreadyInLobby(isUserInLobby);
    });

    socket.on('redirect_to_battle', ({ battleId }) => {
      navigate(`/battle-result/${battleId}`);
    });

    return () => {
      socket.emit('leave_lobby', { lobbyId });
      socket.off('new_message');
      socket.off('lobby_update');
      socket.off('redirect_to_battle');
    };
  }, [lobbyId, navigate, mercenary.id]);

  const handleStartBattle = async () => {
    if (!lobby) return;

    const totalPlayers = lobby.teamOne.length + lobby.teamTwo.length;
    if (totalPlayers < lobby.maxPlayers) {
      setToastMessage('Lobby is not full. Cannot start battle.');
      setToastType('error');
      return;
    }

    try {
      const response = await axios.post('/api/battle/team-battle', { lobbyId }, { withCredentials: true });
      socket.emit('redirect_to_battle', { battleId: response.data.battleId });
      setToastMessage('Battle started successfully!');
      setToastType('success');
    } catch (error) {
      console.error('Error starting battle:', error);
      setToastMessage('Failed to start the battle.');
      setToastType('error');
    }
  };

  const handleKickPlayer = async (targetMercenaryId: number) => {
    try {
      if (!mercenary?.id) {
        setToastMessage('No active mercenary found.');
        setToastType('error');
        return;
      }
  
      await axios.delete(`/api/team-battle/lobbies/${lobbyId}/participants/${targetMercenaryId}`, {
        data: { creatorMercenaryId: mercenary.id },
        withCredentials: true,
      });
  
      setLobby((prevLobby) => {
        if (!prevLobby) return prevLobby;
  
        const updatedTeamOne = prevLobby.teamOne.filter((player) => player.id !== targetMercenaryId);
        const updatedTeamTwo = prevLobby.teamTwo.filter((player) => player.id !== targetMercenaryId);
  
        return {
          ...prevLobby,
          teamOne: updatedTeamOne,
          teamTwo: updatedTeamTwo,
        };
      });
  
      setToastMessage('Player kicked successfully.');
      setToastType('success');
    } catch (error) {
      console.error('Error kicking player:', error);
      setToastMessage('Failed to kick player.');
      setToastType('error');
    }
  };

  if (isLoading) {
    return <p>Loading lobby details...</p>;
  }

  return (
    <div className={styles.lobbyViewContainer}>
      {toastMessage && <Toast message={toastMessage} type={toastType} onClose={() => setToastMessage(null)} />}
      {lobby ? (
        <>
          <LobbyDetails lobby={lobby} />
          <TeamList
            teamOne={lobby.teamOne}
            teamTwo={lobby.teamTwo}
            userId={user.id}
            isOwner={lobby.creatorId === mercenary.id}
            handleKickPlayer={handleKickPlayer}
            randomTeams={lobby.randomTeams}
            pve={lobby.pve}
          />
          {alreadyInLobby && (
            <Preferences
              lobbyId={Number(lobbyId)}
              mercenaryId={mercenary.id}
              preferences={{
                surrenderHP: 0.5,
                readyHP: 0.5,
                strategy: 'Normal',
              }}
              onUpdate={(updatedPreferences) => {}}
            />
          )}
          <ChatBox chatMessages={chatMessages} lobbyId={lobbyId!} socket={socket} />
          <ActionButtons
            isOwner={lobby.creatorId === mercenary.id}
            userInCurrentLobby={alreadyInLobby}
            navigate={navigate}
            lobbyId={lobbyId!}
            matchType={lobby.matchType}
            onBattleStart={handleStartBattle}
            teamsFull={lobby.teamOne.length + lobby.teamTwo.length >= lobby.maxPlayers}
            randomTeams={lobby.randomTeams}
            setSelectedTeam={() => {}}
            handleKickPlayer={handleKickPlayer}
            isInAnyLobby={isInAnyLobby}
          />
        </>
      ) : (
        <p>Loading lobby details...</p>
      )}
    </div>
  );
};

export default LobbyView;
