import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useMercenary } from '../../../contexts/MercenaryContext';
import Toast from '../Toast';
import './BuyOrder.css';

interface Item {
  id: number;
  name: string;
  description: string;
}

interface BuyOrder {
  Item: Item;
  id: number;
  price_offer: number;
  quantity: number;
  expiresAt: string;
}

interface AvailableItem {
  Item: Item;
  price: number;
  quantity: number;
}

const BuyOrderForm: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [items, setItems] = useState<Item[]>([]);
  const [availableItems, setAvailableItems] = useState<AvailableItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const [priceOffer, setPriceOffer] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [duration, setDuration] = useState(24);
  const [activeBuyOrders, setActiveBuyOrders] = useState<BuyOrder[]>([]);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const { user } = useAuth();
  const { mercenary } = useMercenary();

  useEffect(() => {
    axios
      .get('/api/auction/items/available')
      .then((response) => setAvailableItems(response.data))
      .catch((error) => displayToast('Error fetching available items', 'error'));
  }, []);

  useEffect(() => {
    if (searchQuery.trim() !== '') {
      axios
        .get(`/api/auction/items/search?query=${searchQuery}`)
        .then((response) => setItems(response.data))
        .catch((error) => displayToast('Error fetching items', 'error'));
    } else {
      setItems([]);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (selectedItem) {
      setAvailableItems([]);
      setActiveBuyOrders([]);
      
      axios
        .get(`/api/auction/items/available?itemId=${selectedItem.id}`)
        .then((response) => setAvailableItems(response.data))
        .catch((error) => displayToast('Error fetching available items', 'error'));

      axios
        .get(`/api/auction/orders/active-bo/${selectedItem.id}`)
        .then((response) => setActiveBuyOrders(response.data))
        .catch((error) => displayToast('Error fetching active buy orders', 'error'));
    }
  }, [selectedItem]);

  const handleCreateBuyOrder = async () => {
    try {
      await axios.post('/api/auction/buyorder/create', {
        buyer_id: mercenary?.id,
        buyer_user_id: user?.id,
        item_id: selectedItem?.id,
        price_offer: parseInt(priceOffer),
        quantity,
        duration,
      });
      displayToast('Buy order created successfully!', 'success');
      setPriceOffer('');
      setQuantity(1);
      setDuration(24);
      setSelectedItem(null);
    } catch (error: any) {
      const errorMessage = error.response?.data?.error || 'Failed to create buy order';
      displayToast(errorMessage, 'error');
    }
  };

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  return (
    <div className="buy-order-form-container">
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}
      <div className="create-buy-order-form">
        <h2>Create Buy Order</h2>

        <div className="filtered-items-box">
          {items.length > 0 ? (
            <ul className="filtered-items-list">
              {items.map((item) => (
                <li
                  key={item.id}
                  onClick={() => {
                    setSelectedItem(item);
                    setSearchQuery('');
                    setItems([]);
                  }}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          ) : (
            <p className="no-items">Filtered items will populate here</p>
          )}
        </div>

        <label>Search for Items</label>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Enter item name..."
          className="search-bar"
        />

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateBuyOrder();
          }}
        >
          <div className="inline-input-container">
            <div className="input-group">
              <label>Item to Buy</label>
              <input
                type="text"
                value={selectedItem ? selectedItem.name : ''}
                placeholder="Select an item"
                readOnly
                className="selected-item-name"
              />
            </div>

            <div className="input-group">
              <label>Price Offer (per item)</label>
              <input
                type="number"
                value={priceOffer}
                onChange={(e) => setPriceOffer(e.target.value)}
                required
              />
            </div>

            <div className="input-group">
              <label>Quantity</label>
              <input
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(parseInt(e.target.value))}
                required
              />
            </div>
          </div>

          <label>Duration & Fee</label>
          <div className="duration-buttons">
            <button
              type="button"
              className={duration === 24 ? 'active' : ''}
              onClick={() => setDuration(24)}
            >
              24h (5 silver)
            </button>
            <button
              type="button"
              className={duration === 72 ? 'active' : ''}
              onClick={() => setDuration(72)}
            >
              72h (10 silver)
            </button>
            <button
              type="button"
              className={duration === 168 ? 'active' : ''}
              onClick={() => setDuration(168)}
            >
              168h (20 silver)
            </button>
          </div>

          <button type="submit" className="create-button">Create Buy Order</button>
        </form>
      </div>

      <div className="right-box">
        <div className="top-box">
          <h2>Available Items</h2>
          {availableItems.length > 0 ? (
            <ul className="available-items-list styled-list">
              {availableItems.map((item) => (
                <li key={item.Item.id} className="list-item">
                  <span className="item-name">{item.Item.name}</span>
                  <span className="item-quantity">Qty: {item.quantity}</span>
                  <span className="item-price">{item.price} silver</span>
                </li>
              ))}
            </ul>
          ) : (
            <p>No available items.</p>
          )}
        </div>
        <div className="bottom-box">
          <h2>Active Buy Orders</h2>
          {activeBuyOrders.length > 0 ? (
            <ul className="available-buyorders-list styled-list">
              {activeBuyOrders.map((order) => (
                <li key={order.id} className="list-item">
                  <span className="item-name">{order.Item.name}</span>
                  <span className="order-quantity">Qty: {order.quantity}</span>
                  <span className="order-price">{order.price_offer} silver</span>
                </li>
              ))}
            </ul>
          ) : (
            <p>No active buy orders for this item.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BuyOrderForm;
