import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMercenary } from '../../../contexts/MercenaryContext';
import styles from './MyMercenaries.module.css';

interface Mercenary {
  id: number;
  name: string;
  level: number;
  race: string;
  sex: string;
  avatar: number;
}

const MyMercenaries: React.FC = () => {
  const navigate = useNavigate();
  const { mercenaries, fetchUserMercenaries, switchMercenary, deleteMercenary } = useMercenary();
  const [canCreate, setCanCreate] = useState(false);
  const [creationMessage, setCreationMessage] = useState('');
  const [dataFetched, setDataFetched] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [mercenaryToDelete, setMercenaryToDelete] = useState<Mercenary | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!dataFetched) {
        const response = await fetchUserMercenaries();
        if (response) {
          setCanCreate(response.canCreate);
          setCreationMessage(getCreationMessage(response.canCreate, response.mercenaries, response.isPremiumOrHigher));
          setDataFetched(true);
        }
      }
    };
  
    fetchData();
  }, [fetchUserMercenaries, dataFetched]);

  const getCreationMessage = (canCreate: boolean, mercenaries: Mercenary[], isPremiumOrHigher: boolean) => {
    if (canCreate) return 'You can create a new mercenary!';
  
    if (mercenaries.length === 1) {
      return mercenaries[0].level < 15 && !isPremiumOrHigher
        ? 'Your first mercenary needs to be level 15 or higher to create a second one.'
        : '';
    }
  
    if (mercenaries.length === 2) {
      const [firstMercenary, secondMercenary] = mercenaries;
      const hasRequiredLevels = (firstMercenary.level >= 26 && secondMercenary.level >= 15) || (secondMercenary.level >= 26 && firstMercenary.level >= 15);
      const premiumRequirementMet = isPremiumOrHigher && (firstMercenary.level >= 15 || secondMercenary.level >= 15);
  
      return !hasRequiredLevels && !premiumRequirementMet
        ? 'To create a third mercenary, you need one mercenary at level 26 and another at level 15, or Premium status with one mercenary at level 15.'
        : '';
    }
  
    if (mercenaries.length === 3 && !isPremiumOrHigher) {
      return 'Only Premium users can create a fourth mercenary.';
    }
  
    return 'You have reached the maximum number of mercenaries allowed.';
  };

  const confirmDeleteMercenary = (mercenary: Mercenary) => {
    setMercenaryToDelete(mercenary);
    setShowDeleteConfirm(true);
  };

  const handleDeleteConfirm = () => {
    if (mercenaryToDelete) {
      deleteMercenary(mercenaryToDelete.id);
      setShowDeleteConfirm(false);
      setMercenaryToDelete(null);
    }
  };

  return (
    <div className={styles.mercenariesContainer}>
      <h2 className={styles.title}>My Mercenaries</h2>
      {mercenaries.length > 0 ? (
        <table className={styles.mercenaryTable}>
          <thead>
            <tr>
              <th>Avatar</th>
              <th>Name</th>
              <th>Level</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {mercenaries.map((mercenary: Mercenary) => (
              <tr key={mercenary.id}>
                <td>
                  <img
                    src={`/img/avatars/${mercenary.race?.toLowerCase() ?? 'default'}.${mercenary.sex?.toLowerCase() ?? 'default'}.${mercenary.avatar ?? 1}.png`}
                    alt={mercenary.name ?? 'Unknown Mercenary'}
                    className={styles.avatar}
                  />
                </td>
                <td>{mercenary.name}</td>
                <td>{mercenary.level}</td>
                <td>
                  <button className={styles.actionButton} onClick={() => switchMercenary(mercenary)}>
                    Select
                  </button>
                  <button className={styles.deleteButton} onClick={() => confirmDeleteMercenary(mercenary)}>
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className={styles.noMercenariesMessage}>No mercenaries found. Create one to start your journey!</p>
      )}
      <div className={styles.creationSection}>
        <button
          className={styles.createButton}
          onClick={() => navigate('/create-mercenary')}
          disabled={!canCreate}
        >
          Create New Mercenary
        </button>
        {!canCreate && <p className={styles.creationMessage}>{creationMessage}</p>}
      </div>

      {/* Confirmation Modal */}
      {showDeleteConfirm && (
        <div className={styles.confirmOverlay}>
          <div className={styles.confirmModal}>
            <h3>Confirm Delete</h3>
            <p>Are you sure you want to delete {mercenaryToDelete?.name}?</p>
            <div className={styles.modalButtons}>
              <button className={styles.confirmButton} onClick={handleDeleteConfirm}>Yes</button>
              <button className={styles.cancelButton} onClick={() => setShowDeleteConfirm(false)}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyMercenaries;
