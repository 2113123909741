import React, { useState } from 'react';
import axios from 'axios';
import Modal from '../../Shared/Modal';
import { useMercenary } from '../../../contexts/MercenaryContext';
import styles from './ContractBattles.module.css';

const SendChallenges = ({ displayToast }: { displayToast: (message: string, type: 'success' | 'error') => void }) => {
  const { mercenaryId } = useMercenary();
  interface Mercenary {
    id: number;
    name: string;
    level: number;
  }

  const [foundMercenaries, setFoundMercenaries] = useState<Mercenary[]>([]);
  const [minLevelSearch, setMinLevelSearch] = useState<number>(1);
  const [maxLevelSearch, setMaxLevelSearch] = useState<number>(50);
  const [showModal, setShowModal] = useState(false);
  const [selectedMercenaries, setSelectedMercenaries] = useState<number[]>([]); // For "Challenge All"
  const [strategy, setStrategy] = useState<string>('Normal');
  const [surrenderHp, setSurrenderHp] = useState<number>(0.5);

  // Load preferences from localStorage when opening the modal
  const loadPreferences = () => {
    const preferencesKey = `preferences_${mercenaryId}`;
    const savedPreferences = localStorage.getItem(preferencesKey);

    if (savedPreferences) {
      try {
        const { preferences } = JSON.parse(savedPreferences);
        setStrategy(preferences.strategy || 'Normal');
        setSurrenderHp(preferences.surrenderHP || 0.5);
      } catch (error) {
        console.error('Failed to parse saved preferences:', error);
        setStrategy('Normal');
        setSurrenderHp(0.5);
      }
    } else {
      setStrategy('Normal');
      setSurrenderHp(0.5);
    }
  };

  const searchMercenaries = async () => {
    try {
      const response = await axios.get('/api/contract/search', {
        params: { levelMin: minLevelSearch, levelMax: maxLevelSearch, mercenaryId },
      });
      setFoundMercenaries(response.data);
    } catch (error) {
      displayToast('Error searching for mercenaries.', 'error');
    }
  };

  const sendChallenge = async () => {
    try {
      await axios.post('/api/contract/challenge', {
        senderId: mercenaryId,
        receiverIds: selectedMercenaries,
        strategy,
        surrenderHp,
      });
      displayToast('Challenges sent successfully!', 'success');
      setShowModal(false);
      setFoundMercenaries((prev) =>
        prev.filter((merc) => !selectedMercenaries.includes(merc.id))
      );
    } catch (error) {
      displayToast('Failed to send challenges.', 'error');
    }
  };

  return (
    <div className={styles.sendChallenges}>
      {showModal && (
        <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
          onConfirm={sendChallenge}
          title="Send Challenges"
          message={
            <div>
              <label>
                Strategy:
                <select value={strategy} onChange={(e) => setStrategy(e.target.value)}>
                <option value="Normal">Normal</option>
                <option value="Normal: Light Attacks">Normal: Light Attacks</option>
                <option value="Normal: Heavy Attacks">Normal: Heavy Attacks</option>
                <option value="Offensive">Offensive</option>
                <option value="Offensive: Light Attacks">Offensive: Light Attacks</option>
                <option value="Offensive: Heavy Attacks">Offensive: Heavy Attacks</option>
                <option value="Defensive">Defensive</option>
                <option value="Defensive: Light Attacks">Defensive: Light Attacks</option>
                <option value="Defensive: Heavy Attacks">Defensive: Heavy Attacks</option>
                <option value="Berserk">Berserk</option>
                <option value="Berserk: Light Attacks">Berserk: Light Attacks</option>
                <option value="Berserk: Heavy Attacks">Berserk: Heavy Attacks</option>
                </select>
              </label>
              <label>
                Surrender HP (%):
                <select
                  value={surrenderHp}
                  onChange={(e) => setSurrenderHp(parseFloat(e.target.value))}
                >
                  {[...Array(10).keys()].map((i) => (
                    <option key={i} value={i / 10}>
                      {i * 10}%
                    </option>
                  ))}
                </select>
              </label>
            </div>
          }
        />
      )}

      <h3>Search Mercenaries</h3>
      <div className={styles.filterSection}>
        <label>
          Min Level:
          <input
            type="number"
            value={minLevelSearch}
            onChange={(e) => setMinLevelSearch(parseInt(e.target.value, 10))}
          />
        </label>
        <label>
          Max Level:
          <input
            type="number"
            value={maxLevelSearch}
            onChange={(e) => setMaxLevelSearch(parseInt(e.target.value, 10))}
          />
        </label>
        <button onClick={searchMercenaries}>Search</button>
      </div>
      <ul>
        {foundMercenaries.map((merc) => (
          <li key={merc.id}>
            {merc.name} (Level {merc.level})
            <button
              onClick={() => {
                setSelectedMercenaries([merc.id]);
                loadPreferences();
                setShowModal(true);
              }}
            >
              Challenge
            </button>
          </li>
        ))}
      </ul>
      {foundMercenaries.length > 0 && (
        <button
          onClick={() => {
            setSelectedMercenaries(foundMercenaries.map((merc) => merc.id));
            loadPreferences();
            setShowModal(true);
          }}
          className={styles.challengeAllButton}
        >
          Challenge All
        </button>
      )}
    </div>
  );
};

export default SendChallenges;
