import React from 'react';
import styles from './EmojiPicker.module.css';

interface EmojiPickerProps {
  onSelect: (emoji: string) => void;
}

const emojis = Array.from({ length: 50 }, (_, i) => `${i + 1}`);

const EmojiPicker: React.FC<EmojiPickerProps> = ({ onSelect }) => {
  return (
    <div className={styles.emojiPicker}>
      {emojis.map((emoji) => (
        <img
          key={emoji}
          src={`/api/emojis/${emoji}`}
          alt={`:${emoji}:`}
          className={styles.emoji}
          onClick={() => onSelect(emoji)}
        />
      ))}
    </div>
  );
};

export default EmojiPicker;
