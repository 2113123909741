import React, { useState } from 'react';
import Toast from '../Toast';
import IncomingChallenges from './IncomingChallenges';
import SendChallenges from './SendChallenges';
import styles from './ContractBattles.module.css';

const ContractBattles = () => {
  const [activeTab, setActiveTab] = useState<'incoming' | 'send'>('incoming');
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  return (
    <div className={styles.contractBattles}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}

      <h2 className={styles.title}>Contract Battles</h2>

      <div className={styles.tabs}>
        <button
          className={activeTab === 'incoming' ? styles.activeTab : ''}
          onClick={() => setActiveTab('incoming')}
        >
          Incoming Challenges
        </button>
        <button
          className={activeTab === 'send' ? styles.activeTab : ''}
          onClick={() => setActiveTab('send')}
        >
          Send Challenge
        </button>
      </div>

      {activeTab === 'incoming' && (
        <IncomingChallenges displayToast={displayToast} />
      )}
      {activeTab === 'send' && (
        <SendChallenges displayToast={displayToast} />
      )}
    </div>
  );
};

export default ContractBattles;
