// MainLayout.tsx
import React, { useEffect } from 'react';
import LeftNavBar from './LeftNavBar';
import MercenaryBar from './MercenaryBar';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import './MainLayout.css';
import { useMercenary } from '../../contexts/MercenaryContext';
import { useAuth } from '../../contexts/AuthContext';

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { mercenary, setMercenaryId } = useMercenary();
  const { user, setUser } = useAuth();

  useEffect(() => {
    if (mercenary) {
      setMercenaryId(mercenary.id);
    } else {
      setMercenaryId(null);
    }
  }, [mercenary, setMercenaryId]);

  return (
    <div className="layout-container">
      <Header user={user} />
      <div className="main-content">
        {user && <LeftNavBar />}
        <div className="content-area">
          {children}
        </div>
        {user && mercenary && (
          <MercenaryBar />
        )}
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default MainLayout;
