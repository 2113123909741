// components/Adventure.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './Adventure.css';

interface Choice {
  id: number;
  text: string;
}

const Adventure: React.FC = () => {
  const { adventure_id } = useParams<{ adventure_id: string }>();
  const [chapterText, setChapterText] = useState<string>('');
  const [choices, setChoices] = useState<Choice[]>([]);

  useEffect(() => {
    axios.get(`/api/adventures/${adventure_id}`)
      .then(response => {
        setChapterText(response.data.chapterText);
        setChoices(response.data.choices);
      })
      .catch(error => console.error('Error fetching adventure:', error));
  }, [adventure_id]);

  const handleChoice = (choiceId: number) => {
    axios.post(`/api/adventures/${adventure_id}/choice`, { choiceId })
      .then(response => {
        setChapterText(response.data.chapterText);
        setChoices(response.data.choices);
      })
      .catch(error => console.error('Error making choice:', error));
  };

  return (
    <div className="adventure">
      <h2>Adventure</h2>
      <p>{chapterText}</p>
      <ul>
        {choices.map((choice) => (
          <li key={choice.id}>
            <button onClick={() => handleChoice(choice.id)}>{choice.text}</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Adventure;
